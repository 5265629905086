import styles from "../../constants";
import {Text, View} from "@react-pdf/renderer";

const KpPdfTableHeader = () => {
    return (
        <View style={styles.tableHeader}>
            <View style={[styles.column, {flex: 0.5}]}>
                <Text style={styles.tableHeaderText}>№</Text>
            </View>
            <View style={[styles.column, {flex: 2}]}>
                <Text style={styles.tableHeaderText}>Наименование</Text>
            </View>
            <View style={[styles.column, {flex: 0.5}]}>
                <Text style={styles.tableHeaderText}>Кол-во</Text>
            </View>
            <View style={[styles.column, {flex: 0.5}]}>
                <Text style={styles.tableHeaderText}>Ед. изм.</Text>
            </View>
            <View style={[styles.column, {flex: 1}]}>
                <Text style={styles.tableHeaderText}>Срок поставки</Text>
            </View>
            <View style={[styles.column, {flex: 1}]}>
                <Text style={styles.tableHeaderText}>Цена за единицу</Text>
            </View>
            <View style={[styles.column, {flex: 1}]}>
                <Text style={styles.tableHeaderText}>Сумма</Text>
            </View>
        </View>
    );
};

export default KpPdfTableHeader;