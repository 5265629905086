import './KanbanRenderer.css'
import {useEffect, useRef, useState} from "react";
import {ReactComponent as AddIcon} from "../../components/UI/icons/x.svg";

const KanbanRenderer = ({
                            lanes,
                            data,
                            stageKey,
                            sortableKey,
                            renderDataItemComponent,
                            changeLaneItem,
                            onClickItem,
                            addItem
                        }) => {
    const refLanes = useRef(null);
    const mouseScrollIntervalId = useRef(null);
    const [dragElement, setDragElement] = useState(null);
    const [isVisibleScrollElementsLeft, setIsVisibleScrollElementsLeft] = useState(false)
    const [isVisibleScrollElementsRight, setIsVisibleScrollElementsRight] = useState(false)

    useEffect(() => {
        checkVisibleScrollElements()
        window.addEventListener('resize', checkVisibleScrollElements)
        return () => {
            window.removeEventListener('resize', checkVisibleScrollElements)
        }
    }, []);
    const checkVisibleScrollElements = () => {
        if (refLanes?.current?.scrollLeft === 0 && (refLanes?.current?.scrollWidth === refLanes?.current?.clientWidth)) {
            setIsVisibleScrollElementsLeft(false)
            setIsVisibleScrollElementsRight(false)
            return {
                left: false,
                right: false
            }
        } else if (refLanes?.current?.scrollLeft === 0) {
            setIsVisibleScrollElementsLeft(false)
            setIsVisibleScrollElementsRight(true)
            return {
                left: false,
                right: true
            }
        } else if ((refLanes?.current?.scrollWidth - refLanes?.current?.clientWidth) === refLanes?.current?.scrollLeft) {
            setIsVisibleScrollElementsLeft(true)
            setIsVisibleScrollElementsRight(false)
            return {
                left: true,
                right: false
            }
        } else {
            setIsVisibleScrollElementsLeft(true)
            setIsVisibleScrollElementsRight(true)
            return {
                left: true,
                right: true
            }
        }

    }
    const sortableItems = (lane) => {
        return data
            ?.filter(item => stageKey?.(item) === lane?.statusValue)
            ?.sort((a, b) => {
                if (sortableKey?.(a) < sortableKey?.(b))
                    return 1
                else if (sortableKey?.(a) === sortableKey?.(b))
                    return 0
                else
                    return -1
            }) || [];
    }
    const handleEnterLeftScroll = () => {
        mouseScrollIntervalId.current = setInterval(() => {
            refLanes.current.scrollLeft -= 3;

            let resultObj = checkVisibleScrollElements()
            if (!resultObj?.left) {
                clearInterval(mouseScrollIntervalId.current)
                mouseScrollIntervalId.current = null
            }

        }, 6)
    }
    const handleEnterRightScroll = () => {
        mouseScrollIntervalId.current = setInterval(() => {
            refLanes.current.scrollLeft += 3;

            let resultObj = checkVisibleScrollElements()
            if (!resultObj?.right) {
                clearInterval(mouseScrollIntervalId.current)
                mouseScrollIntervalId.current = null
            }

        }, 6)
    }
    const handleLeaveLeftScroll = () => {
        clearInterval(mouseScrollIntervalId.current)
        mouseScrollIntervalId.current = null
    }
    const handleLeaveRightScroll = () => {
        clearInterval(mouseScrollIntervalId.current)
        mouseScrollIntervalId.current = null
    }
    return (
        <div className={'kanban__wrapper'}>
            <div ref={refLanes} className={'kanban__lanes'}>
                {isVisibleScrollElementsLeft &&
                    <img
                        className={'horizontal-scroll horizontal-scroll-left'}
                        src="https://i.pinimg.com/originals/bc/81/64/bc8164b3f1b95eaf4bbc24ed5d6a2500.png"
                        alt="a"
                        onDragEnter={handleEnterLeftScroll}
                        onDragLeave={handleLeaveLeftScroll}
                        onMouseEnter={handleEnterLeftScroll}
                        onMouseLeave={handleLeaveLeftScroll}
                    />
                }
                {isVisibleScrollElementsRight &&
                    <img
                        className={'horizontal-scroll horizontal-scroll-right'}
                        src="https://i.pinimg.com/originals/bc/81/64/bc8164b3f1b95eaf4bbc24ed5d6a2500.png"
                        alt="a"
                        onDragEnter={handleEnterRightScroll}
                        onDragLeave={handleLeaveRightScroll}
                        onMouseEnter={handleEnterRightScroll}
                        onMouseLeave={handleLeaveRightScroll}
                    />
                }
                {lanes?.map((lane, idxLane) => (
                    <div
                        key={idxLane}
                        className={'kanban__lane'}
                        onDragOver={e => {
                            e.preventDefault()
                        }}
                        onDrop={e => {
                            if (dragElement?.current?.from?.statusValue !== lane?.statusValue)
                                changeLaneItem({...dragElement, to: lane})
                        }}
                    >
                        <div className={'kanban__lane_label label-lane'}>
                            <div className="label-lane__group">
                                <div className={'label-lane__color'}
                                     style={{backgroundColor: lane.color ? lane.color : 'var(--color-dark-gray)'}}></div>
                                <span className={'label-lane__text'}>{lane?.statusName}</span>
                                <span
                                    className={'label-lane__text label-lane__text_gray'}>{sortableItems(lane).length}</span>
                            </div>
                            {idxLane === 0 && addItem &&
                                <div className="label-lane__group">
                                    <AddIcon
                                        onClick={() => addItem?.()}
                                        className={'label-lane__add-icon'}
                                    />
                                </div>
                            }
                        </div>
                        <div className={'kanban__items'}>
                            {sortableItems(lane)
                                ?.map((item, idxItem) => (
                                    <div
                                        key={idxItem}
                                        className={'kanban__item'}
                                        draggable
                                        onDragStart={e => setDragElement({
                                            from: lane,
                                            item: item
                                        })}
                                        onDragEnd={e => setDragElement(null)}
                                        onClick={() => onClickItem?.(item)}
                                    >
                                        {renderDataItemComponent?.(item)}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default KanbanRenderer;