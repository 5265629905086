import {useState} from "react";
import useLoader from "./useLoader";
import useMessageHub from "./useMessageHub";

export const useInteractionBackend = ({cb, thenCb, checkConnection, errorCb, finallyCb, type = 'signalR'}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const {show, hide} = useLoader();
    const sendMessage = useMessageHub();

    const interaction = (...args) => {
        if (!checkConnection?.() && type === 'signalR') {
            let count = 0;
            let intervalId = setInterval(() => {
                if (checkConnection?.()) {
                    interaction(...args);
                    clearInterval(intervalId);
                } else if (count === 5) clearInterval(intervalId);
                else count++;
            }, 1500);
        } else {
            show();
            setIsLoading(true);
            cb(...args)
                .then((res) => {
                    if (thenCb) thenCb(res, ...args)
                })
                .catch(e => {
                    setError(e.message);
                    sendMessage(e.message);
                    if (errorCb) errorCb(e);
                })
                .finally(() => {
                    setIsLoading(false);
                    hide();
                    if (finallyCb) {
                        finallyCb();
                    }
                })
        }
    }

    const clearError = () => {
        setError('');
    }


    return [interaction, isLoading, error, clearError]
}