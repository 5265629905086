import {Text, View} from "@react-pdf/renderer";
import styles from "../constants";
import {getCurrencyString} from "../../../../../features/formatters/forrmaterrub";

const KpPdfComponentItem = ({material, index, kp}) => {
    return (
        <View style={styles.item} break>
            <View style={[styles.column, {flex: 0.5}]}>
                <Text style={styles.tableHeaderText}>{index + 1}</Text>
            </View>
            <View style={[styles.column, {flex: 2}]}>
                <Text style={styles.tableHeaderText}>{material?.component.printName}</Text>
            </View>
            <View style={[styles.column, {flex: 0.5}]}>
                <Text style={styles.tableHeaderText}>{material?.quantityIncludingPackaging || '0'}</Text>
            </View>
            <View style={[styles.column, {flex: 0.5}]}>
                <Text style={styles.tableHeaderText}>{material?.unitMeasurement?.unitName || 'Не определено'}</Text>
            </View>
            <View style={[styles.column, {flex: 1}]}>
                <Text style={styles.tableHeaderText}>{material?.supplyDate ? material?.supplyDate : 'Не определено'}</Text>
            </View>
            <View style={[styles.column, {flex: 1}]}>
                <Text style={styles.tableHeaderText}>{getCurrencyString(material?.price || 0, kp?.currencyForHandle?.currencyCode)}</Text>
            </View>
            <View style={[styles.column, {flex: 1}]}>
                <Text style={styles.tableHeaderText}>{getCurrencyString(material?.totalPriceIncludingPackaging || 0, kp?.currencyForHandle?.currencyCode)}</Text>
            </View>
        </View>
    );
};

export default KpPdfComponentItem;