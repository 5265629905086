
import {Button, Form, Stack} from "react-bootstrap";
import ProvidersItem from "./ProvidersItem";
import {useQuery} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../hooks/useAxiosHelper";
import useTkp from "../../hooks/useTkp";

const ProvidersTkp = ({slice, type}) => {

    const {data: warehouses} = useQuery({
        queryKey: ['options', 'warehouses'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/warehouses',
        }),
    })

    const {
        removeProvisionItemTkp,
        changeProvisionItemTkp,
        addProvisionItemTkp
    } = useTkp()

    return (
        <Form>

            <Form.Group>
                <Form.Label>Материал: <b>{slice?.equipment?.name || 'Нет данных'}</b></Form.Label>
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Label>Требуется обеспечить: {type === 'equipment' ? slice?.quantityIncludingPackaging : slice?.quantityDiluentIncludingPackaging} {slice?.equipment?.equipmentInformation?.unitMeasurement?.unitName || ''}</Form.Label>
            </Form.Group>
            {slice?.providers?.map(provideItem => (
                <ProvidersItem
                    warehouses={warehouses}
                    key={provideItem?.guid}
                    provideItem={provideItem}
                    remains={slice?.equipment?.remains || []}
                    removeProvideItem={guid => removeProvisionItemTkp(type, guid, slice?.id)}
                    changeProvideItem={newItem => changeProvisionItemTkp(newItem)}
                />
            ))}
            <Stack className={'flex align-items-end justify-content-center'}>
                <Button onClick={() => {
                    addProvisionItemTkp(type, slice?.id)
                }}>Добавить</Button>
            </Stack>

        </Form>
    );
};

export default ProvidersTkp;