import './RemainsButtonTab.css';
import {useEffect, useState} from "react";
import constants from "./constants";

const RemainsButtonTab = ({text, classes, isActive, icon, ...props}) => {
    const [mainClasses, setMainClasses] = useState('');
    const [iconClasses, setIconClasses] = useState('');
    const [textClasses, setTextClasses] = useState('');

    useEffect(() => {
        setMainClasses(`${constants.mainClass}${classes?.mainClass ? ' ' + classes.mainClass : ''}${
            isActive ? ' ' + constants.activeMainClass : ''}`
        );
        setIconClasses(`${constants.iconClass}${classes?.iconClass ? ' ' + classes.iconClass : ''}`);
        setTextClasses(`${constants.textClass}${classes?.textClass ? ' ' + classes.textClass : ''}`);
    });

    return (
        <button className={mainClasses} {...props}>
            {icon &&
                <div className={iconClasses}>
                    {icon}
                </div>}
            <div className={textClasses}>
                {text}
            </div>
        </button>
    );
};

export default RemainsButtonTab;