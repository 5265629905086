import RemainsLine from "../remainsline/RemainsLine";
import {Accordion} from "react-bootstrap";
import './RemainsComponentsModal.css';

const RemainsComponentsModal = ({components}) => {
    const type = 'component';

    return (
        <div className='remains-components-modal'>
            <Accordion alwaysOpen className='remains-components-modal__accordion'>
                {components.map(component => <RemainsLine data={component.component} type={type} key={component.guid}/>)}
            </Accordion>
        </div>
    );
};

export default RemainsComponentsModal;