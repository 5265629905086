import './AssociatedEntities.css'
import {useMultiStepComponent} from "../../hooks/usemultistepcomponent/UseMultiStepComponents";
import ContractsList from "../../pages/sales/contracts/list/ContractsList";
import BillingsList from "../../pages/sales/billings/list/BillingsList";
import SpecificationList from "../../pages/sales/specifications/list/SpecificationList";
import ButtonTab from "../UI/buttons/buttontab/ButtonTab";
import ListIcon from "../UI/icons/ListIcon";
import ClientList from "../../pages/clients/modules/list/ClientList";
import TkpListN from "../../pages/newTkp/list/TkpListN";
import KpList from "../../pages/kp/components/list/KpList";
import DealsList from "../../pages/sales/deals/list/DealsList";
import RentAppealList from "../../pages/management/appeals/list/rentappeallist/RentAppealList";
import BuyAppealList from "../../pages/management/appeals/list/buyappeallist/BuyAppealList";
import RentOfferList from "../../pages/management/offers/list/rentofferlist/RentOfferList";
import BuyOfferList from "../../pages/management/offers/list/buyofferlist/BuyOfferList";
import BuyDealList from "../../pages/management/deals/list/buydeallist/BuyDealList";
import RentDealList from "../../pages/management/deals/list/rentdeallist/RentDealList";
import ProjectList from "../../pages/projects/main/list/ProjectList";
import SellVariationList from "../../pages/projects/sellvariation/list/SellVariationList";

const AssociatedEntities = ({
                                tkpId,
                                dealId,
                                clientId,
                                assignedId,
                                projectRentVariationId,
                                projectSellVariationId,
                                kpId,
                                kp , tkp, client, deal, contract, specification, billing, offerRent, offerBuy, dealRent, dealBuy, appealRent, appealBuy, project
}) => {

    const components = [
        {
            titleStep: 'Клиенты',
            component: <ClientList
                assignedId={assignedId}
            />
        },
        {
            titleStep: 'ТКП',
            component: <TkpListN
                dealId={dealId}
                clientId={clientId}
                assignedId={assignedId}
            />
        },
        {
            titleStep: 'КП',
            component: <KpList
                tkpId={tkpId}
                dealId={dealId}
                clientId={clientId}
                assignedId={assignedId}
            />
        },
        {
            titleStep: 'Сделки',
            component: <DealsList
                clientId={clientId}
                assignedId={assignedId}
            />
        },
        {
            titleStep: 'Договора',
            component: <ContractsList
                tkpId={tkpId}
                dealId={dealId}
                clientId={clientId}
                assignedId={assignedId}
                kpId={kpId}
            />
        },
        {
            titleStep: 'Счета',
            component: <BillingsList
                tkpId={tkpId}
                dealId={dealId}
                clientId={clientId}
                assignedId={assignedId}
                kpId={kpId}
            />
        },
        {
            titleStep: 'Спецификации',
            component: <SpecificationList
                tkpId={tkpId}
                dealId={dealId}
                clientId={clientId}
                assignedId={assignedId}
                kpId={kpId}
            />
        },
        {
            titleStep: 'Обращения аренды',
            component: <RentAppealList
                projectRentVariationId={projectRentVariationId}
                assignedId={assignedId}
                clientId={clientId}
            />
        },
        {
            titleStep: 'Обращения покупки',
            component: <BuyAppealList
                projectSellVariationId={projectSellVariationId}
                assignedId={assignedId}
                clientId={clientId}
            />
        },
        {
            titleStep: 'Фиксации аренды',
            component: <RentOfferList
                projectRentVariationId={projectRentVariationId}
                assignedId={assignedId}
                clientId={clientId}
            />
        },
        {
            titleStep: 'Фиксации покупки',
            component: <BuyOfferList
                projectSellVariationId={projectSellVariationId}
                assignedId={assignedId}
                clientId={clientId}
            />
        },
        {
            titleStep: 'Сделки аренды',
            component: <RentDealList
                projectRentVariationId={projectRentVariationId}
                assignedId={assignedId}
                clientId={clientId}
            />
        },
        {
            titleStep: 'Сделки покупки',
            component: <BuyDealList
                projectSellVariationId={projectSellVariationId}
                assignedId={assignedId}
                clientId={clientId}
            />
        },
        {
            titleStep: 'Проекты',
            component: <ProjectList
                assignedId={assignedId}
                clientId={clientId}
            />
        }
    ]
    const endpointComponents = []

    components?.forEach(component => {
        if(client && component?.titleStep === 'Клиенты')
            endpointComponents.push(component)
        if(tkp && component?.titleStep === 'ТКП')
            endpointComponents.push(component)
        if(kp && component?.titleStep === 'КП')
            endpointComponents.push(component)
        if(deal && component?.titleStep === 'Сделки')
            endpointComponents.push(component)
        if(contract && component?.titleStep === 'Договора')
            endpointComponents.push(component)
        if(billing && component?.titleStep === 'Счета')
            endpointComponents.push(component)
        if(specification && component?.titleStep === 'Спецификации')
            endpointComponents.push(component)
        if(appealRent && component?.titleStep === 'Обращения аренды')
            endpointComponents.push(component)
        if(appealBuy && component?.titleStep === 'Обращения покупки')
            endpointComponents.push(component)
        if(offerRent && component?.titleStep === 'Фиксации аренды')
            endpointComponents.push(component)
        if(offerBuy && component?.titleStep === 'Фиксации покупки')
            endpointComponents.push(component)
        if(dealRent && component?.titleStep === 'Сделки аренды')
            endpointComponents.push(component)
        if(dealBuy && component?.titleStep === 'Сделки покупки')
            endpointComponents.push(component)
        if(project && component?.titleStep === 'Проекты')
            endpointComponents.push(component)
    })

    const {
        titleStep,
        goTO,
        step
    } = useMultiStepComponent(endpointComponents)

    return (
        <div className={'associated-entities__wrapper'}>
            <div className={'associated-entities__header'}>
                {client &&
                    <ButtonTab
                        title={'Клиенты'}
                        isActive={titleStep === 'Клиенты'}
                        onClick={() => goTO(endpointComponents?.findIndex(x => x?.titleStep === 'Клиенты'))}
                        icon={<ListIcon/>}
                    />
                }
                {tkp &&
                    <ButtonTab
                        title={'ТКП'}
                        isActive={titleStep === 'ТКП'}
                        onClick={() => goTO(endpointComponents?.findIndex(x => x?.titleStep === 'ТКП'))}
                        icon={<ListIcon/>}
                    />
                }
                {kp &&
                    <ButtonTab
                        title={'КП'}
                        isActive={titleStep === 'КП'}
                        onClick={() => goTO(endpointComponents?.findIndex(x => x?.titleStep === 'КП'))}
                        icon={<ListIcon/>}
                    />
                }
                {deal &&
                    <ButtonTab
                        title={'Сделки'}
                        isActive={titleStep === 'Сделки'}
                        onClick={() => goTO(endpointComponents?.findIndex(x => x?.titleStep === 'Сделки'))}
                        icon={<ListIcon/>}
                    />
                }
                {contract &&
                    <ButtonTab
                        title={'Договора'}
                        isActive={titleStep === 'Договора'}
                        onClick={() => goTO(endpointComponents?.findIndex(x => x?.titleStep === 'Договора'))}
                        icon={<ListIcon/>}
                    />
                }
                {billing &&
                    <ButtonTab
                        title={'Счета'}
                        isActive={titleStep === 'Счета'}
                        onClick={() => goTO(endpointComponents?.findIndex(x => x?.titleStep === 'Счета'))}
                        icon={<ListIcon/>}
                    />
                }
                {specification &&
                    <ButtonTab
                        title={'Спецификации'}
                        isActive={titleStep === 'Спецификации'}
                        onClick={() => goTO(endpointComponents?.findIndex(x => x?.titleStep === 'Спецификации'))}
                        icon={<ListIcon/>}
                    />
                }
                {appealRent &&
                    <ButtonTab
                        title={'Обращения аренды'}
                        isActive={titleStep === 'Обращения аренды'}
                        onClick={() => goTO(endpointComponents?.findIndex(x => x?.titleStep === 'Обращения аренды'))}
                        icon={<ListIcon/>}
                    />
                }
                {appealBuy &&
                    <ButtonTab
                        title={'Обращения покупки'}
                        isActive={titleStep === 'Обращения покупки'}
                        onClick={() => goTO(endpointComponents?.findIndex(x => x?.titleStep === 'Обращения покупки'))}
                        icon={<ListIcon/>}
                    />
                }
                {offerRent &&
                    <ButtonTab
                        title={'Фиксации аренды'}
                        isActive={titleStep === 'Фиксации аренды'}
                        onClick={() => goTO(endpointComponents?.findIndex(x => x?.titleStep === 'Фиксации аренды'))}
                        icon={<ListIcon/>}
                    />
                }
                {offerBuy &&
                    <ButtonTab
                        title={'Фиксации покупки'}
                        isActive={titleStep === 'Фиксации покупки'}
                        onClick={() => goTO(endpointComponents?.findIndex(x => x?.titleStep === 'Фиксации покупки'))}
                        icon={<ListIcon/>}
                    />
                }
                {dealRent &&
                    <ButtonTab
                        title={'Сделки аренды'}
                        isActive={titleStep === 'Сделки аренды'}
                        onClick={() => goTO(endpointComponents?.findIndex(x => x?.titleStep === 'Сделки аренды'))}
                        icon={<ListIcon/>}
                    />
                }
                {dealBuy &&
                    <ButtonTab
                        title={'Сделки покупки'}
                        isActive={titleStep === 'Сделки покупки'}
                        onClick={() => goTO(endpointComponents?.findIndex(x => x?.titleStep === 'Сделки покупки'))}
                        icon={<ListIcon/>}
                    />
                }
                {project &&
                    <ButtonTab
                        title={'Проекты'}
                        isActive={titleStep === 'Проекты'}
                        onClick={() => goTO(endpointComponents?.findIndex(x => x?.titleStep === 'Проекты'))}
                        icon={<ListIcon/>}
                    />
                }
            </div>
            <div className={'associated-entities__body'}>
                {step}
            </div>
        </div>
    );
};

export default AssociatedEntities;