import './TkpListN.css'
import {useEffect, useMemo, useState} from "react";
import useLoader from "../../../hooks/useLoader";
import useMessageHub from "../../../hooks/useMessageHub";
import {useNavigate} from "react-router";
import BX24 from "bx24-api";
import axios from "axios";
import {BaseUrl} from "../../../features/databaseinfo/BaseUrl";
import {updateBxInfo} from "../features/TkpHandlers";
import GuidsWrapper from "../../../components/guids/wrapper/GuidsWrapper";
import GuidsHeader from "../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../components/guids/search/GuidsSearchInput";
import GuidsBody from "../../../components/guids/body/GuidsBody";
import DefaultModal from "../../../components/UI/modals/DefaultModal";
import {PDFViewer} from "@react-pdf/renderer";
import BitrixIcon from "../../../components/UI/icons/bitrixicon/BitrixIcon";
import TelegramIcon from "../../../components/UI/icons/telegramicon/TelegramIcon";
import TableBootstrap from "../../../components/tableb/TableBootstrap";
import useCheckAccess from "../../../hooks/useCheckAccess";
import AccessDenied from "../../../components/access/AccessDenied";
import EditIcon from "../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../components/UI/icons/BackCrossIcon";
import {getRubString} from "../../../features/formatters/forrmaterrub";
import DropdownDefault from "../../../components/UI/selects/dropdowndefault/DropdownDefault";
import ListIcon from "../../../components/UI/icons/ListIcon";
import {generateGuid} from "../../../features/guidgenerator/GuidGenerator";
import {Button, Form, Image, Modal} from "react-bootstrap";
import useAxiosHelper from "../../../hooks/useAxiosHelper";
import TkpClientPdfV2 from "../components/client/pdf/TkpClientPdfV2";
import GuidsPagination from "../../../components/guids/pagination/GuidsPagination";
import {usePaginationController} from "../../../components/pagination/components";
import KanbanRenderer from "../../../modules/kanban/KanbanRenderer";
import {ReactComponent as AddIcon} from "../../../components/UI/icons/add-icon.svg";
import humanizer from "humanize-duration";
import {ReactComponent as RubleIcon} from "../../../components/UI/icons/badge-russian-ruble.svg";
import AssociatedEntities from "../../../components/associatedentities/AssociatedEntities";
import TemplateModal from "../components/templateModal/TemplateModal";

const TkpListN = ({assignedId, clientId, dealId}) => {
    const [bxInfo, setBxInfo] = useState(null)
    const [modalPreviewVisible, setModalPreviewVisible] = useState(false)
    const [USD, setUSD] = useState(false)
    const [tkpIdForTemplate, setTkpIdForTemplate] = useState(null)
    const [visibleNameTemplateTKP, setVisibleNameTemplateTKP] = useState(false);
    const [isKanban, setIsKanban] = useState(false)
    const [lanes, setLanes] = useState([])
    const [bxInfoIsUpdate, setBxInfoIsUpdate] = useState(false)
    const [showDocumentList, setShowDocumentList] = useState(false)
    const [changeStageModalVisibleItem, setChangeStageModalVisibleItem] = useState(null)
    const [changeStageModalVisible, setChangeStageModalVisible] = useState(false)
    const [tkpPdf, setTkpPdf] = useState({})
    const [modalClipboardVisible, setModalClipboardVisible] = useState(false)
    const [clipboardUri, setClipboardUri] = useState('')
    const [selectedStage, setSelectedStage] = useState(null)
    const [selectedAssigned, setSelectedAssigned] = useState(null)
    const [search, setSearch] = useState('')
    const {hideAll} = useLoader()
    const addMessage = useMessageHub()
    const navigate = useNavigate()

    const [items, setItems] = useState({})
    const [filters, setFilters] = useState({
        BXIsAdmin: false,
        IsList: false,
        SearchTitle: '',
        BXDealId: 0,
        BXUserId: 0,
        assignedIds: assignedId ? [assignedId] : [],
        createdIds: [],
        modifyIds: [],
        clientIds: clientId ? [clientId] : [],
        stagesIds: [],
        dealIds: dealId ? [dealId] : []
    })
    const [page, setPage] = useState(1)
    const {getMethod, deleteMethod, postMethod} = useAxiosHelper({})

    useEffect(() => {
        if (window.name.toLowerCase().includes('bitrix')) {
            BX24.resizeWindow(document.body.clientWidth, 1000).then(() => {

            })
            BX24.isAdmin()
                .then(res => {
                    setFilters({...filters, BXIsAdmin: res})
                })
        }
        axios.get(`${BaseUrl}/ancorr/api/getUsdValue`).then(({data}) => {
            setUSD(data)
        }).catch(res => {
            addMessage("Не удалось загрузить курс валюты с сервера, обратитесь к администратору")
            addMessage(res.message || "Нет данных ошибки")
        }).finally(res => {

        })
        getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/lanes',
            queryParams: {
                destinationEntity: 'tkp'
            },
            successCallback: ({data}) => setLanes(data)
        })
    }, [])

    useEffect(() => {
        updateBxInfo()
            .then(res => {
                if (res) {
                    setBxInfo(res)
                    setFilters({...filters, BXDealId: res?.dealId || 0, BXUserId: res?.userId || 0})
                }
            })
            .catch(res => {
                addMessage(res.message || "Нет данных ошибки")
            })
            .finally(res => {
                setBxInfoIsUpdate(true)
            })
    }, []);

    const addTkp = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/tkp/v2/add',
        data: {
            assignedId,
            dealId,
            clientId
        },
        successCallback: ({data}) => updateItems()
    })

    const deleteTkp = (id) => deleteMethod({
        urlWithoutBaseUrl: `ancorr/api/tkp/v2/delete/tkp/${id}`,
        successCallback: () => updateItems()
    })

    const changeStageTkp = (actionTo, id) => getMethod({
        urlWithoutBaseUrl: 'ancorr/api/tkp/v2/edit/tkp/stage',
        queryParams: {
            actionTo: actionTo,
            tkpId: Number(id)
        },
        successCallback: () => updateItems()
    })

    const changeStageTkpNew = (newItem) => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/tkp/v2/edit/tkp/stage/v2',
        data: newItem,
        successCallback: () => updateItems()
    })

    const memoTkps = useMemo(() => {
        let filteredArray = []
        filteredArray = items?.data?.filter(x => x?.dealName?.toLowerCase()?.includes(search?.toLowerCase()) || x?.title?.toLowerCase()?.includes(search?.toLowerCase()) || x?.ship?.toLowerCase()?.includes(search?.toLowerCase()) || x?.companyName?.toLowerCase()?.includes(search?.toLowerCase()))
        if (selectedStage?.value)
            filteredArray = filteredArray.filter(x => x?.stage === selectedStage.value)
        if (selectedAssigned?.value)
            filteredArray = filteredArray.filter(x => x?.assigned === selectedAssigned.value)

        return filteredArray;
    }, [items, selectedStage, search, selectedAssigned])

    const assignedMemoOptions = useMemo(() => {
        let assignedArray = []

        memoTkps?.forEach(item => {
            if (item?.assigned && item?.assigned !== 'Нет данных' && !assignedArray?.some(x => x.value === item?.assigned))
                assignedArray = [...assignedArray, {
                    id: generateGuid(),
                    accessor: 'assigned',
                    title: item?.assigned,
                    value: item?.assigned
                }]
        })

        return assignedArray;

    }, [memoTkps])

    const openPreview = (tkp) => {
        setTkpPdf(tkp)
        setModalPreviewVisible(true)
    }
    const closePreview = () => {
        setTkpPdf({})
        setModalPreviewVisible(false)
    }
    const openClipBoardModal = (uri) => {
        setClipboardUri(uri)
        setModalClipboardVisible(true)
    }
    const closeClipboardModal = () => {
        setClipboardUri('')
        setModalClipboardVisible(false)
    }
    const formatDate = (dateToFormat) => {
        const date = new Date(dateToFormat);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;
        return `${formattedDay}.${formattedMonth}.${year}`;
    }
    const oldDate = (date) => {
        const currentDate = new Date();
        return humanizer(currentDate - new Date(date), {
            language: ['ru'],
            largest: 1,
            maxDecimalPoints: 1
        }) + ' назад';
    }

    const updateItems = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/tkp/v2/list/v2',
        queryParams: {
            offset: 15,
            page: page,
            isKanban: isKanban
        },
        data: filters,
        successCallback: ({data}) => setItems(data)
    })

    const createKPFromTkp = (tkpId) => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/tkp/v2/convert',
        queryParams: {
            tkpId
        },
        successCallback: ({data}) => navigate(`/sales/kp/detail/${data?.id}`)
    })
    const copyTkp = (tkpId) => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/tkp/v2/copy',
        queryParams: {
            id: tkpId
        },
        successCallback: () => updateItems()
    })

    const {changePage, paginationModule} = usePaginationController({
        pagesCount: items?.pages || 1,
        changePageCallback: (data) => data === 0 ? setPage(1) : setPage(data)
    })

    useEffect(() => {
        if (items?.currentPage > items?.pages)
            setPage(items?.pages)
    }, [items]);

    useEffect(() => {
        if (page === 1) changePage(page);
        if (bxInfoIsUpdate)
            updateItems()
    }, [page, bxInfoIsUpdate, isKanban]);

    const popoverActions = [
        {
            icon: <ListIcon/>,
            title: 'Смена стадии',
            onClick: item => {
                setChangeStageModalVisibleItem(item)
                setChangeStageModalVisible(true)
            }
        },
        {
            icon: <ListIcon/>,
            title: 'Предпросмотр',
            onClick: item => openPreview(item)
        },
        {
            icon: <ListIcon/>,
            title: 'Документы',
            onClick: item => setShowDocumentList(true) || setChangeStageModalVisibleItem(item)
        },
        {
            icon: <ListIcon/>,
            title: 'Создать КП',
            onClick: item => createKPFromTkp(item?.id)
        },
        {
            icon: <ListIcon/>,
            title: 'Создать копию',
            onClick: item => copyTkp(item?.id)
        },
        {
            icon: <ListIcon/>,
            title: 'Создать шаблон',
            onClick: item => {
                setTkpIdForTemplate(item?.id);
                setVisibleNameTemplateTKP(true);
            }
        },
        {
            icon: <ListIcon/>,
            title: 'Получить ссылки',
            onClick: item => openClipBoardModal(`https://it.ancorr.ru/crm/preview/v2/${item.guid}`)
        },
        {
            icon: <EditIcon/>,
            title: 'Редактировать',
            onClick: item => navigate(`/sales/tkp/detail/${item?.id}`)
        },
        {
            icon: <BackCrossIcon/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => deleteTkp(item?.id)
        },
    ]

    const access = useCheckAccess("sales/tkp")

    if (!access) {
        hideAll()
        return <AccessDenied/>
    }

    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'ТКП'}/>
                <div className={'flex center gap'}>
                    <GuidsSearchInput searchValue={search} setSearchValue={setSearch}/>
                    <button
                        className={'tkp__button'}
                        onClick={addTkp}
                    >
                        <AddIcon
                            className={'tkp__add-icon'}
                        />
                        Добавить ТКП
                    </button>
                </div>
            </GuidsHeader>
            <Form className={'w-100 flex left'}>
                <Form.Check // prettier-ignore
                    checked={isKanban}
                    type="switch"
                    label="Вид канбана"
                    onChange={e => setIsKanban(!isKanban)}
                />
            </Form>
            <GuidsBody>
                {isKanban
                    ?
                    <KanbanRenderer
                        lanes={lanes}
                        data={items?.data || []}
                        stageKey={item => item?.stageTkp?.statusValue}
                        sortableKey={item => new Date(item?.modifyTimeDb)}
                        addItem={addTkp}
                        renderDataItemComponent={item => {
                            return (
                                <div className={'tkp-card__wrapper'}>
                                    <div className="tkp-card__container">
                                        <div className={'tkp-card__created-time'}>
                                            {formatDate(item?.createdTimeDb)}
                                        </div>
                                    </div>
                                    <p className="m-0 p-0 tkp-card__name">{item?.title}</p>
                                    <div className="m-0 p-0 text-muted tkp-card__creds">
                                        <Image
                                            className={'tkp-card__avatar'}
                                            src={item?.createdBy?.avatar?.urlPath || 'https://static.tildacdn.com/tild3238-3834-4566-b939-643034303766/no-profile.png'}
                                            alt=""
                                        />
                                        {item?.createdBy?.lastName && item?.createdBy?.firstName ?
                                            `${item?.createdBy?.lastName || ''} ${item?.createdBy?.firstName || ''}`
                                            :
                                            `${item?.createdBy?.email || 'Неизвестно'}`
                                        }
                                    </div>
                                    <div className={'tkp-card__footer'}>
                                        <div className={'tkp-card__footer-price'}>
                                            <RubleIcon/>
                                            {getRubString(item?.totalPriceIncludingPackaging)}
                                        </div>
                                        <div className="tkp-card__old-date">
                                            {oldDate(item?.modifyTimeDb)}
                                        </div>
                                    </div>
                                </div>
                            )
                        }}
                        changeLaneItem={e => changeStageTkpNew({...e?.item, stageTkp: e?.to})}
                        onClickItem={item => navigate(`detail/${item?.id}`)}
                    />
                    :
                    <TableBootstrap
                        popoverCaption={'Действия'}
                        items={memoTkps}
                        schema={schema}
                        popoverActions={popoverActions}
                    />
                }

            </GuidsBody>
            {!isKanban &&
                <GuidsPagination>
                    {
                        paginationModule
                    }
                </GuidsPagination>
            }
            {modalPreviewVisible &&
                <DefaultModal visible={modalPreviewVisible} setVisible={closePreview}>
                    <PDFViewer style={{width: '80vw', height: '80vh'}}>
                        <TkpClientPdfV2 tkp={tkpPdf} USD={USD}/>
                    </PDFViewer>
                </DefaultModal>
            }

            <Modal show={modalClipboardVisible} onHide={() => closeClipboardModal()}>
                <Modal.Header><Modal.Title>URL для клиента</Modal.Title></Modal.Header>
                <Modal.Body>
                    <div className={'tkp-clipboard-modal'}>
                        <div onClick={() => {
                            addMessage('Ссылка скопирована в буфер обмена')
                            navigator.clipboard.writeText(clipboardUri)

                        }}
                        >
                            {clipboardUri}
                        </div>
                        <div className="tkp-clipboard-modal__links">
                            <div className="tkp-clipboard-modal__link"
                                 onClick={() => {
                                     addMessage('Ссылка скопирована в буфер обмена')
                                     navigator.clipboard.writeText(`[url=${clipboardUri}]ТКП ANCORR[/url]`)
                                 }}
                            >
                                <BitrixIcon size={'60px'}/>
                            </div>
                            <div className="tkp-clipboard-modal__link"
                                 onClick={() => {
                                     addMessage('Ссылка скопирована в буфер обмена')
                                     navigator.clipboard.writeText(`@Sean_Bot md [ТКП ANCORR](${clipboardUri})`);
                                 }}
                            >
                                <TelegramIcon size={'70px'}/>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} onClick={() => closeClipboardModal()}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={changeStageModalVisible}
                onHide={() => {
                    setChangeStageModalVisibleItem(null)
                    setChangeStageModalVisible(false)
                }}
            >
                <Modal.Header><Modal.Title>Смена стадии</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Выберите стадию:</Form.Label>
                            <DropdownDefault
                                options={stageOptions}
                                currentValue={stageOptions.find(x => x?.value === changeStageModalVisibleItem?.stage)}
                                placeholder={'Стадия'}
                                onChange={e => setChangeStageModalVisibleItem({
                                    ...changeStageModalVisibleItem,
                                    stage: e?.value ?? null
                                })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => {
                        setChangeStageModalVisibleItem(null)
                        setChangeStageModalVisible(false)
                    }}>Закрыть</Button>
                    <Button variant={"primary"} onClick={() => {
                        changeStageTkp(changeStageModalVisibleItem?.stage, changeStageModalVisibleItem?.id)
                        setChangeStageModalVisibleItem(null)
                        setChangeStageModalVisible(false)
                    }}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showDocumentList}
                onHide={() => setShowDocumentList(false) || setChangeStageModalVisibleItem(null)}
                fullscreen
            >
                <Modal.Header><Modal.Title>Документы</Modal.Title></Modal.Header>
                <Modal.Body>
                    <AssociatedEntities
                        tkpId={changeStageModalVisibleItem?.id}
                        assignedId={assignedId}
                        dealId={changeStageModalVisibleItem?.dealMyClientId}
                        clientId={changeStageModalVisibleItem?.clientId}
                        contract
                        specification
                        billing
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => setShowDocumentList(false) || setChangeStageModalVisibleItem(null)}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
            <TemplateModal
                show={visibleNameTemplateTKP}
                onHide={() => {
                    setVisibleNameTemplateTKP(false);
                    setTkpIdForTemplate(null);
                }}
                tkpId={tkpIdForTemplate}
            />
        </GuidsWrapper>
    );
};

const schema = [
    {
        title: 'Дата создания',
        callbackItem: item => new Date(item?.createdTimeDb)?.toLocaleDateString('ru-RU') || "Нет данных",
    },
    {
        title: 'Название',
        callbackItem: item => item?.title || "Не заполнено",
    },
    {
        title: 'Компания',
        callbackItem: item => item?.companyName || "Нет данных",
    },
    {
        title: 'Сделка',
        callbackItem: item => item?.dealName || "Нет данных",
    },
    {
        title: 'Судно',
        callbackItem: item => item?.ship || "Нет данных",
    },
    {
        title: 'Стоимость',
        callbackItem: item => getRubString(item?.totalPriceIncludingPackaging || 0),
    },
    {
        title: 'Ответственный',
        callbackItem: item => item?.assigned || "Нет данных",
    },
    {
        title: 'Стадия',
        callbackItem: item => stageOptions.find(x => x?.value === item?.stage)?.title || "",
    },
]
const stageOptions = [
    {id: 3, accessor: 'stageId', value: 'logisticsFallback', title: 'Возвращено логистом'},
    {id: 2, accessor: 'stageId', value: 'check', title: 'На проверке'},
    {id: 4, accessor: 'stageId', value: 'wait', title: 'Ожидает подтверждения клиента'},
    {id: 5, accessor: 'stageId', value: 'supply', title: 'К обеспечению'},
    {id: 6, accessor: 'stageId', value: 'success', title: 'Успешно'},
    {id: 7, accessor: 'stageId', value: 'fail', title: 'Провал'}
]

export default TkpListN;