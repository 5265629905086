import axios from "axios";
import {BaseUrl} from "../../features/databaseinfo/BaseUrl";
import {useInteractionBackend} from "../../hooks/useInteractionBackend";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";

const UseCurrenciesService = (isManualMode) => {
    const [currencies, setCurrencies] = useState([]);

    const [getAllCurrencies] = useInteractionBackend({
        type: 'axios',
        cb: Service.getAllCurrencies,
        thenCb: ({data}) => setCurrencies(data)
    });

    useEffect(() => {
        if (!isManualMode) {
            getAllCurrencies();
        }
        return () => {
            setCurrencies([]);
        };
    }, [])

    return ({currencies});
};

class Service {
    static getAllCurrencies() {
        return axios.get(`${BaseUrl}/ancorr/api/helper/get/currencies`);
    }
};

UseCurrenciesService.defaultProps = {
    isManualMode: false,
};

UseCurrenciesService.propTypes = {
    isManualMode: PropTypes.bool,
};

export default UseCurrenciesService;