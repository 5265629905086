import {useEffect, useState} from 'react';
import {useInteractionBackend} from "../../../../../hooks/useInteractionBackend";
import {getCurrencies} from "../../../API/remainsAPI";
import RemainsCheckbox from "../../remainscheckbox/RemainsCheckbox";
import newArrayIds from "../../../helpers/newArrayIds";
import ClearBtn from "../../../UI/clearbtn/ClearBtn";
import {useAuth} from "../../../../../features/authprovider/AuthProvider";

const CurrenciesFilters = ({filters, setFilters, updatePosition}) => {
    const [initialFilters, setInitialFilters] = useState(filters.currencyIds);
    const [currencies, setCurrencies] = useState([]);
    const {userInfo} = useAuth();

    const [fetchCurrencies] = useInteractionBackend({
        type: 'axios',
        cb: getCurrencies,
        thenCb: ({data}) => setCurrencies(data)
    })

    useEffect(() => {
        const token = userInfo?.accessToken || '';
        fetchCurrencies({token});
    }, []);

    return (
        <div>
            {
                currencies?.map(currency => {
                    return (
                        <RemainsCheckbox
                            key={currency.guid}
                            checked={filters.currencyIds.includes(currency.id)}
                            onClick={(e) => {
                                let newArr = newArrayIds(currency.id, filters.currencyIds);
                                setFilters({...filters, currencyIds: newArr});
                                updatePosition(e);
                            }}

                        >
                            {currency?.name}
                        </RemainsCheckbox>
                    )
                })
            }
            <ClearBtn
                isActive={filters.currencyIds.length > 0}
                onClick={(e) => {
                    setFilters({...filters, currencyIds: initialFilters});
                    updatePosition(e);
                }}
            />
        </div>
    );
};

export default CurrenciesFilters;