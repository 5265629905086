import {useEffect, useState} from 'react';
import './StatusInfoList.css'
import useLoader from "../../../../../hooks/useLoader";
import useAxiosHelper from "../../../../../hooks/useAxiosHelper";
import EditIcon from "../../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import useCheckAccess from "../../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../../components/access/AccessDenied";
import GuidsWrapper from "../../../../../components/guids/wrapper/GuidsWrapper";
import GuidsHeader from "../../../../../components/guids/header/GuidsHeader";
import GuidsSearchInput from "../../../../../components/guids/search/GuidsSearchInput";
import GuidsBody from "../../../../../components/guids/body/GuidsBody";
import TableBootstrap from "../../../../../components/tableb/TableBootstrap";
import {Button, Container, Form, Modal} from "react-bootstrap";

const StatusInfoList = ({statusForEdit, updateAllStatuses}) => {

    const [searchValue, setSearchValue] = useState('')
    const [statusItemForEdit, setStatusItemForEdit] = useState(null)
    const [visibleStatusItemForEdit, setVisibleStatusItemForEdit] = useState(false)
    const {hideAll} = useLoader()
    const [item, setItem] = useState({})
    const {deleteMethod, postMethod} = useAxiosHelper({})

    useEffect(() => {
        if(statusForEdit)
            setItem(statusForEdit)
    }, [statusForEdit]);


    const addNewStatusItem = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/statuses/add/status/item',
        queryParams: {
            groupId: item?.id
        },
        successCallback: ({data}) => setItem({...item, statuses: [...item?.statuses, data]}) || updateAllStatuses()
    })
    const changeStatusItem = (newItem) => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/statuses/update/status/item',
        queryParams: {
            groupId: item?.id
        },
        data: newItem,
        successCallback: () => {
            updateAllStatuses()
            setItem({...item, statuses: item?.statuses?.map(el => {
                if(el?.guid === newItem?.guid) return newItem;
                else return el;
            })})
        }
    })
    const removeStatusItem = (guid) => deleteMethod({
        urlWithoutBaseUrl: 'ancorr/api/statuses/delete/status/item',
        successCallback: () => setItem({...item, statuses: item?.statuses?.filter(el => el?.guid !== guid)}) || updateAllStatuses(),
        queryParams: {
            guid,
            groupId: item?.id
        }
    })

    const popoverActions = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item => setStatusItemForEdit(item) || setVisibleStatusItemForEdit(true)
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => removeStatusItem(item?.guid)
        },
    ]



    const schema = [
        {
            title: 'Дата создания',
            callbackItem: item => new Date(item?.createdTimeDb)?.toLocaleDateString('ru-RU') || "Нет данных",
        },
        {
            title: 'Титул',
            callbackItem: item => item?.statusName || "Не заполнено",
        },
        {
            title: 'Значение',
            callbackItem: item => item?.statusValue || "Не заполнено",
        },
        {
            title: 'Цвет',
            callbackItem: item => item?.color || "Не заполнено",
        },
    ]

    const access = useCheckAccess("admin/status")

    if(!access){
        hideAll()
        return <AccessDenied />
    }
    return (
        <GuidsWrapper>
            <GuidsHeader>
                <Container fluid className={'d-flex justify-content-end gap-2'}>
                    <GuidsSearchInput onChange={newValue => setSearchValue(newValue)}/>
                    <Button onClick={() => addNewStatusItem()}>Добавить</Button>
                </Container>
            </GuidsHeader>
            <GuidsBody>
                <TableBootstrap
                    schema={schema}
                    items={item?.statuses || []}
                    popoverActions={popoverActions}
                    popoverCaption={"Действия"}
                />
            </GuidsBody>
            <Modal show={visibleStatusItemForEdit} onHide={() => setVisibleStatusItemForEdit(false) || setStatusItemForEdit(null)}>
                <Modal.Header><Modal.Title>Редактирование элемента</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Титул</Form.Label>
                            <Form.Control
                                type={'text'}
                                value={statusItemForEdit?.statusName || ''}
                                onChange={e => setStatusItemForEdit({...statusItemForEdit, statusName: e.target.value})}
                            />
                            <Form.Label>Значение</Form.Label>
                            <Form.Control
                                type={'text'}
                                value={statusItemForEdit?.statusValue || ''}
                                onChange={e => setStatusItemForEdit({...statusItemForEdit, statusValue: e.target.value})}
                            />
                            <Form.Label>Цвет</Form.Label>
                            <Form.Control
                                type={'text'}
                                value={statusItemForEdit?.color || ''}
                                onChange={e => setStatusItemForEdit({...statusItemForEdit, color: e.target.value})}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => setVisibleStatusItemForEdit(false) || setStatusItemForEdit(null)}>Закрыть</Button>
                    <Button variant={"primary"} onClick={() => {
                        changeStatusItem(statusItemForEdit);
                        setVisibleStatusItemForEdit(false)
                        setStatusItemForEdit(null)
                    }}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
        </GuidsWrapper>
    );
};

export default StatusInfoList;