import './RemainsLine.css';
import useRemainsSettingsStore from "../../store/remainsSettingsStore";
import {
    calcMinMaxPrices
} from "../../../../features/pricecalculators/PriceCalculator";
import {Accordion, Button, Modal} from "react-bootstrap";
import RemainsWarehouse from "./RemainsWarehouse";
import {useEffect, useMemo, useState} from "react";
import RemainsComponentsModal from "../remainscomponentsmodal/RemainsComponentsModal";
import ReportMaterial from "../reportmaterial/ReportMaterial";
import {checkBitrix} from "../../helpers/bitrixFunctions";
import BX24 from "bx24-api";
import {useInteractionBackend} from "../../../../hooks/useInteractionBackend";
import {updateDiscount} from "../../API/remainsAPI";
import useDebounce from "../../../../hooks/useDebounce";
import {useShallow} from "zustand/react/shallow";
import useAxiosHelper from "../../../../hooks/useAxiosHelper";

const RemainsLine = ({data, type, isSelect, isSelector, clickElement, USD = 1}) => {
    const settings = useRemainsSettingsStore(useShallow(state => state.settings));
    const [visibleComponents, setVisibleComponents] = useState(false);
    const [showModalPriceFaq, setShowModalPriceFaq] = useState(false);
    const [visibleDetailedRemain, setVisibleDetailedRemain] = useState(false);
    const [isEmptyRemains, setIsEmptyRemains] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [maxDiscount, setMaxDiscount] = useState(0);
    const [faq, setFaq] = useState('')
    const {getMethod} = useAxiosHelper({})
    const debouncedMaxDiscount = useDebounce({
        value: maxDiscount,
        delay: 1200,
    });
    const information = type === 'equipment' ? 'equipmentInformation' : 'componentInformation';
    const [isNotPostDiscount, setIsNotPostDiscount] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const prices = useMemo(() => {
        const {strMinPrice, strMaxPrice} = calcMinMaxPrices(data.priceForUnit, maxDiscount);
        return {strMinPrice, strMaxPrice};
    }, [information, data, data.discount, maxDiscount])

    useEffect(() => {
        if (data.maxDiscount > 0) {
            setMaxDiscount(data.maxDiscount);
            setIsNotPostDiscount(true);
        }
        if (!data.remains || data.remains.length === 0) {
            setIsEmptyRemains(true);
        }
        checkBitrix(() => {
            BX24.isAdmin().then(res => setIsAdmin(res));
        })
        // setIsAdmin(true);
    }, []);
    useEffect(() => {
        if (isFirstLoad || isNotPostDiscount) {
            if (isFirstLoad) {
                setIsFirstLoad(false);
            } else {
                setIsNotPostDiscount(false);
            }
        } else {
            postDiscount({type: type, id: data.id, value: maxDiscount});
        }
    }, [debouncedMaxDiscount]);

    const [postDiscount] = useInteractionBackend({
        type: 'axios',
        cb: updateDiscount,
    })

    return (
        <Accordion.Item className='remains-line remains-equipment__accordion-item' eventKey={data.guid}>
            <Accordion.Header className='remains-equipment__accordion-header'>
                <div className={`remains-line__selector ${(isSelect && isSelector) && 'remains-line__select'}`}>
                    <span>{data.name}</span>
                    {(!isSelect && isSelector) && <Button onClick={() => clickElement({type: type, element: data})} className={'h-25'} variant={"primary"}>Выбрать</Button>}
                </div>
            </Accordion.Header>
            <Accordion.Body className='remains-equipment__accordion-body'>
                <div className="remains-line__price remains-line__param">
                    <div className="remains-line__header">Цены</div>
                    <div className="remains-line__data">
                        <div
                            className='remains-line__price-count'>Мин: {prices.strMinPrice}
                        </div>
                        <div
                            className='remains-line__price-count'>Ст: {prices.strMaxPrice}
                        </div>
                        {isAdmin && <input
                            className='remains-line__input'
                            placeholder={'Максимальная скидка'}
                            defaultValue={maxDiscount}
                            onChange={e => setMaxDiscount(Number(e.target.value))}
                            type={'number'}
                        />}
                    </div>
                </div>
                <div className="remains-line__current-remains remains-line__param">
                    <div className="remains-line__header">Текущие остатки</div>
                    {!isEmptyRemains ? <div className="remains-line__data">
                            {data?.remains?.map((remain) => settings.allowedWarehouses.length === 0
                                    ?
                                    remain?.warehouse?.isVisibleShortReport && (
                                        <RemainsWarehouse remain={remain} settings={settings} data={data}
                                                          type={type} key={remain.guid} />
                                    )
                                    :
                                    remain?.warehouse?.isVisibleShortReport && settings.allowedWarehouses.includes(remain?.warehouse?.id) && (
                                        <RemainsWarehouse remain={remain} settings={settings} data={data}
                                                      type={type} key={remain.guid}/>
                                    )
                            )}
                        </div>
                        : <div className='remains-line__data'>Отсутствуют</div>
                    }
                </div>
                <div className="remains-line__actions remains-line__footer">
                    {type === 'equipment' &&
                        <Button className='remains-line__btn' onClick={() => setVisibleComponents(true)}>
                            Компоненты
                        </Button>
                    }
                    {type === 'equipment' &&
                        <Button className='remains-line__btn' onClick={() => getMethod({
                            urlWithoutBaseUrl: 'ancorr/api/materials/get/information',
                            queryParams: {
                                type,
                                entityId: data?.id
                            },
                            successCallback: ({data}) => setFaq(data)
                        })|| setShowModalPriceFaq(true)}>
                            Почему такая цена
                        </Button>
                    }
                    {!isEmptyRemains && <Button className='remains-line__btn' onClick={() => setVisibleDetailedRemain(true)}>
                        Остатки подробно
                    </Button>
                    }
                </div>
            </Accordion.Body>
            <Modal
                size={'lg'}
                show={visibleComponents}
                onHide={() => setVisibleComponents(false)}
            >
                <Modal.Header><Modal.Title>Компоненты</Modal.Title></Modal.Header>
                <Modal.Body><RemainsComponentsModal components={data?.components}/></Modal.Body>
                <Modal.Footer>
                    <Button
                        className='remains-line__btn'
                        variant={"primary"}
                        onClick={() => {
                            setVisibleComponents(false)
                        }}
                    >
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                size={'lg'}
                show={showModalPriceFaq}
                onHide={() => setShowModalPriceFaq(false)}
            >
                <Modal.Header><Modal.Title>Пояснение</Modal.Title></Modal.Header>
                <Modal.Body>
                    <pre>{faq}</pre>
                </Modal.Body>
                <Modal.Footer>
                <Button
                        className='remains-line__btn'
                        variant={"primary"}
                        onClick={() => {
                            setShowModalPriceFaq(false)
                        }}
                    >
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                size={'lg'}
                show={visibleDetailedRemain}
                onHide={() => setVisibleDetailedRemain(false)}
            >
                <Modal.Header><Modal.Title>Подробный отчет</Modal.Title></Modal.Header>
                <Modal.Body><ReportMaterial type={type} material={data}/></Modal.Body>
                <Modal.Footer>
                    <Button
                        className='remains-line__btn'
                        variant={"primary"}
                        onClick={() => {
                            setVisibleDetailedRemain(false)
                        }}
                    >
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
        </Accordion.Item>
    );
};

export default RemainsLine;