
import Fields from "./Fields";

const Block = ({
                   block,
                   dataModel,
                   isEditing,
                   dependencyEntity,
                   handleChangeValue,
                   setShowModalSelector,
                   setElementToModalSelector,
                   entities,
                   onNeedEditCallback,
                   editingFieldGuids
               }) => {
    return (
        <div className={'field-block__wrapper'}>
            <div className={'field__block_header'}>
                <p className={'field-block__title border-bottom border-2 fw-bold pb-2 text-muted'}>{block?.blockTitle || 'Не заполнен'}</p>
            </div>
            <div className={'p-0'}>
                <Fields
                    fields={block?.fields || []}
                    keys={[]}
                    dependencyEntity={dependencyEntity}
                    dataModel={dataModel}
                    isEditing={isEditing}
                    handleChangeValue={handleChangeValue}
                    setShowModalSelector={setShowModalSelector}
                    setElementToModalSelector={setElementToModalSelector}
                    entities={entities}
                    onNeedEditCallback={onNeedEditCallback}
                    editingFieldGuids={editingFieldGuids}
                />
            </div>
        </div>
    );
};

export default Block;