import {create} from "zustand";
import {devtools} from "zustand/middleware"


const useBreadcrumbsStore = create()(devtools((set, get) => ({
    breadcrumbs: [],
    addBreadcrumb: (name, onClick) => {
        set({breadcrumbs: [...get().breadcrumbs, {name, onClick}]})
    },
    goToBreadcrumb: (onClick, name) => {
        const breadcrumbs = get().breadcrumbs;
        const breadcrumbId = breadcrumbs.findIndex(b => b.onClick.toString() === onClick.toString() && b.name === name);
        if (breadcrumbId === 0) {
            set({breadcrumbs: [breadcrumbs[0]]})
        }
        set({breadcrumbs: breadcrumbs.slice(0, breadcrumbId + 1)});
        onClick();
    },
    clearBreadcrumbs: () => {
        set({breadcrumbs: []})
    }
}), {name: 'breadcrumbsStore'}));

export default useBreadcrumbsStore;