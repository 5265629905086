import './Remains.css'
import {useMultiStepComponent} from "../../hooks/usemultistepcomponent/UseMultiStepComponents";
import RemainsNomenclaturesModule from "./modules/nomenclatures/RemainsNomenclaturesModule";
import RemainsComponentsModule from "./modules/components/RemainsComponentsModule";
import RequisitesIcon from "../../components/UI/icons/RequisitesIcon";
import RemainsButtonTab from "./components/remainsbuttontab/RemainsButtonTab";
import {useEffect, useState} from "react";
import {checkBitrix, resizeBitrixWindow} from "./helpers/bitrixFunctions";
import useCheckAccess from "../../hooks/useCheckAccess";
import AccessDenied from "../../components/access/AccessDenied";
import useLoader from "../../hooks/useLoader";
import RemainsToolbar from "./components/remainstoolbar/RemainsToolbar";
import {useInteractionBackend} from "../../hooks/useInteractionBackend";
import {getUSD, updateUSD} from "./API/remainsAPI";
import BX24 from 'bx24-api';
import useDebounce from "../../hooks/useDebounce";
import RemainsFilters from "./components/remainsfilters/RemainsFilters";
import RemainsPagination from "./components/remainspagination/RemainsPagination";
import useRemainsStore from "./store/remainsStore";
import useRemainsController from "./hooks/useRemainsController";

const RemainsNew = ({clickElement, isSelector, selectedIdsComponents, selectedIdsEquipments}) => {
    useRemainsController();
    const {hideAll} = useLoader();
    const setCurrentTab = useRemainsStore.getState().setCurrentTab;
    const access = useCheckAccess('remains');
    const [USD, setUSD] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);
    const debouncedUSD = useDebounce({
        value: USD,
        delay: 1200
    })

    const [loadUSD] = useInteractionBackend({
        type: 'axios',
        cb: getUSD,
        thenCb: ({data}, {setUSD}) => setUSD(data)
    })
    const [postUSD] = useInteractionBackend({
        type: 'axios',
        cb: () => updateUSD({USD}),
    })

    //INIT PAGE
    useEffect(() => {
        checkBitrix(() => {
            resizeBitrixWindow();
        });
        loadUSD({setUSD});
        checkBitrix(() => {
            BX24.isAdmin().then(res => setIsAdmin(res));
        })
        // setIsAdmin(true)
    }, []);
    useEffect(() => {
        if (debouncedUSD.Value) {
            postUSD({debouncedUSD});
        }
    }, [debouncedUSD]);

    const {
        titleStep,
        step,
        goTO
    } = useMultiStepComponent([
        {
            component:
                <RemainsNomenclaturesModule
                    clickElement={clickElement}
                    isSelector={isSelector}
                    selectedIdsEquipments={selectedIdsEquipments}
                    USD={USD}
                />,
            titleStep: 'Готовая продукция'
        },
        {
            component:
                <RemainsComponentsModule
                    clickElement={clickElement}
                    isSelector={isSelector}
                    selectedIdsComponents={selectedIdsComponents}
                />,
            titleStep: 'Компоненты'
        }
    ])

    if (!access) {
        hideAll()
        return <AccessDenied/>
    }

    const classesRemainsButtonTab = {
        mainClass: 'remains__button-tab',
    }

    return (
        <div className='remains'>
            <div className="remains__props">
                <RemainsFilters key={'remains-filters'}/>
            </div>
            <div className="remains__content">
                <div className='remains__tabs-row'>
                    <RemainsButtonTab
                        classes={classesRemainsButtonTab}
                        text={'Готовая продукция'}
                        icon={<RequisitesIcon classNames={'icon-extension'}/>}
                        onClick={() => {
                            setCurrentTab('equipments')
                            goTO(0)
                        }}
                        isActive={titleStep === 'Готовая продукция'}
                    />
                    <RemainsButtonTab
                        classes={classesRemainsButtonTab}
                        icon={<RequisitesIcon classNames={'icon-extension'}/>}
                        text={'Компоненты'}
                        onClick={() => {
                            setCurrentTab('components')
                            goTO(1)
                        }}
                        isActive={titleStep === 'Компоненты'}
                    />
                    <RemainsToolbar/>
                    <div className="remains__usd">
                        {isAdmin
                            ? <input
                                defaultValue={USD.Value}
                                onChange={(e) => setUSD({...USD, Value: e.target.value})}
                                placeholder={'КУРС'}
                                className='remains__usd-input'
                                type='number'
                            />
                            : <label>Курс: {USD.Value}</label>
                        }
                    </div>
                </div>
                <div className="remains__body">
                    {step}
                    <RemainsPagination/>
                </div>
            </div>
        </div>
    );
};

export default RemainsNew;