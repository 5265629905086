import './DefaultSidebar.css'
import {NavLink, useLocation, useMatch} from "react-router-dom";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {Button, Image} from "react-bootstrap";
import {AxiosHelper} from "../../hooks/useAxiosHelper";
import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../features/authprovider/AuthProvider";
const DefaultSidebar = ({setIsOpenMenu}) => {
    const [isOpen, setIsOpen] = useState(false)

    const {isAdmin, userInfo} = useAuth()

    const {data: menuItems} = useQuery({
        queryKey: 'menu',
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/menu',
            queryParams: {
                partnerId: userInfo?.partnerId,
                token: userInfo?.accessToken
            },
        }),
        refetchOnWindowFocus: false
    })

    useEffect(() => {
        setIsOpenMenu(isOpen)
    }, [isOpen])
    const navigate = useNavigate()
    const location = useLocation()
    const match = useMatch(location?.pathname)
    const checkPermission = (itemIsAdmin) => {
        if(itemIsAdmin && isAdmin)
            return true
        else if(itemIsAdmin && !isAdmin)
            return false
        else return true
    }
    return (
        <nav className={`default-sidebar__wrapper ${isOpen ? '' : 'hide__menu'}`}>
            <div className={`gradient  ${isOpen ? '' : 'hide__gradient'}`}></div>
            <div className={`default-sidebar__body ${isOpen ? '' : 'hide__body'}`}>
                <div className={`default-sidebar__header ${isOpen ? '' : 'hide__header'}`}>
                    {isOpen && <img className={'default-sidebar__logo'} src="https://it.ancorr.ru/photo/img/logo3.png" alt=""/>}
                    <div className={`logout__container ${isOpen ? '' : 'hide__header'}`} onClick={() => setIsOpen(!isOpen)}>
                        <i className={`fa-solid fa-arrow-right-from-bracket fa-sm logout-icon ${isOpen ? '' : 'logout-icon-rotate'}`}></i>
                    </div>
                </div>
                <div className={`default-sidebar__menu ${isOpen ? '' : 'hide-menu'}`}>
                    {menuItems?.map(item => (
                        checkPermission(item?.isAdmin) &&
                            <div key={item?.guid} className={`default-sidebar__item_container ${isOpen ? '' : 'hide__item'}`}>
                                <NavLink
                                    to={item?.path}
                                    className={({ isActive, isPending, isTransitioning}) => [
                                        isPending ? 'default-sidebar__item_pending' : '',
                                        isActive ? 'default-sidebar__item_active' : '',
                                        isTransitioning ? 'default-sidebar__transitioning' : ''
                                    ].join(" ") + `default-sidebar__item ${isOpen ? '' : 'hide__item'}`}
                                >
                                    <div className={'default-sidebar__color'} style={match?.pathname?.toLowerCase()?.includes(item?.path?.toLowerCase()) ? {background: item?.color, '--color-neon': item?.color} : {background: item?.color, '--color-neon': ''}}></div>
                                    {isOpen && <div className={'default-sidebar__text'}>
                                        {item?.title}
                                    </div>}
                                </NavLink>
                                {isOpen && match?.pathname?.toLowerCase()?.includes(item?.path?.toLowerCase()) && item?.subitems?.map(subitem => (
                                    <NavLink
                                        key={subitem?.guid}
                                        to={subitem?.path}
                                        className={({ isActive, isPending, isTransitioning}) => [
                                            isPending ? 'default-sidebar__item_pending' : '',
                                            isActive ? 'default-sidebar__item_active' : '',
                                            isTransitioning ? 'default-sidebar__transitioning' : ''
                                        ].join(" ") + `default-sidebar__subitem`}
                                    >
                                        <div className={'default-sidebar__color'} style={match?.pathname?.toLowerCase()?.includes(subitem?.path?.toLowerCase()) ? {background: '#3E90F0', '--color-neon': '#3E90F0'} : {background: subitem?.color, '--color-neon': subitem?.color}}></div>
                                        <div className={'default-sidebar__text'}>
                                            {subitem?.title}
                                        </div>
                                        <div className={'default-sidebar__crumbs'}>
                                            0
                                        </div>
                                    </NavLink>
                                ))}
                            </div>

                    ))}
                </div>
            </div>
            <div className={`default-sidebar__footer ${isOpen ? '' : 'hide__footer'}`}>
                {isOpen && <div className={`user__profile ${isOpen ? '' : 'hide__profile'}`}>
                    <div className={'user__block'}>
                        <div className={'user__avatar'}>
                            <Image
                                className={'user-img__avatar_sidebar'}
                                src={userInfo?.avatarPath || 'https://static.tildacdn.com/tild3238-3834-4566-b939-643034303766/no-profile.png'}
                                onError={e => {
                                    e.target.src = 'https://static.tildacdn.com/tild3238-3834-4566-b939-643034303766/no-profile.png'
                                }}
                                alt=""
                            />
                        </div>
                        <div className={'user__info'}>
                            <div className={'user__name'}>
                                {`${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`}
                            </div>
                            <div className={'user_email'}>
                                {userInfo?.email || ''}
                            </div>
                        </div>
                        <div className={'user__other'}>
                            <div className={'user__role'}>
                                {userInfo?.role || ''}
                            </div>
                        </div>
                    </div>
                    <Button
                        onClick={() => navigate(`users/detail/${userInfo?.id}`)}
                        variant={'outline-light'}
                    >
                        Редактировать профиль
                    </Button>
                </div>}
            </div>
        </nav>
    );
};

export default DefaultSidebar;