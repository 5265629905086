import {useEffect, useMemo, useState} from 'react';
import './KpList.css'
import KanbanRenderer from "../../../../modules/kanban/KanbanRenderer";
import GuidsBody from "../../../../components/guids/body/GuidsBody";
import TitleDetailCard from "../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../components/guids/search/GuidsSearchInput";
import GuidsHeader from "../../../../components/guids/header/GuidsHeader";
import {useNavigate} from "react-router";
import {ReactComponent as RubleIcon} from "../../../../components/UI/icons/badge-russian-ruble.svg";
import humanizer from 'humanize-duration'
import {ReactComponent as AddIcon} from "../../../../components/UI/icons/add-icon.svg";
import {Button, Form, Image, Modal} from "react-bootstrap";
import BX24 from "bx24-api";
import {getRubString} from "../../../../features/formatters/forrmaterrub";
import TableBootstrap from "../../../../components/tableb/TableBootstrap";
import {updateBxInfo} from "../../../newTkp/features/TkpHandlers";
import useMessageHub from "../../../../hooks/useMessageHub";
import useLoader from "../../../../hooks/useLoader";
import ListIcon from "../../../../components/UI/icons/ListIcon";
import EditIcon from "../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../components/UI/icons/BackCrossIcon";
import useCheckAccess from "../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../components/access/AccessDenied";
import {usePaginationController} from "../../../../components/pagination/components";
import useAxiosHelper from "../../../../hooks/useAxiosHelper";
import GuidsPagination from "../../../../components/guids/pagination/GuidsPagination";
import DropdownDefault from "../../../../components/UI/selects/dropdowndefault/DropdownDefault";
import GuidsWrapper from "../../../../components/guids/wrapper/GuidsWrapper";
import AssociatedEntities from "../../../../components/associatedentities/AssociatedEntities";
import {useAuth} from "../../../../features/authprovider/AuthProvider";

const priority = {
    0: {color: '#00fb3b', label: 'low'},
    1: {color: '#00fb3b', label: 'low'},
    2: {color: 'yellow', label: 'middle'},
    3: {color: '#e15e5a', label: 'hight'}
}

const KpList = ({assignedId, clientId, dealId, tkpId}) => {
    const [lanes, setLanes] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const {userInfo} = useAuth()
    const navigate = useNavigate()
    const [bxInfo, setBxInfo] = useState(null)
    const [isKanban, setIsKanban] = useState(false)
    const [showDocumentList, setShowDocumentList] = useState(false)
    const [bxInfoIsUpdate, setBxInfoIsUpdate] = useState(false)
    const [changeStageModalVisibleItem, setChangeStageModalVisibleItem] = useState(null)
    const [changeStageModalVisible, setChangeStageModalVisible] = useState(false)
    const addMessage = useMessageHub()
    const {hideAll} = useLoader()



    const [items, setItems] = useState({})
    const [filters, setFilters] = useState({
        BXIsAdmin: false,
        IsList: false,
        SearchTitle: '',
        BXDealId: 0,
        BXUserId: 0,
        assignedIds: assignedId ? [assignedId] : [],
        createdIds: [],
        modifyIds: [],
        clientIds: clientId ? [clientId] : [],
        stagesIds: [],
        dealIds: dealId ? [dealId] : [],
        tkpIds: tkpId ? [tkpId] : []
    })
    const [page, setPage] = useState(1)
    const {getMethod, deleteMethod, postMethod} = useAxiosHelper({})


    useEffect(() => {
        if(window.name.toLowerCase().includes('bitrix')){
            BX24.resizeWindow(document.body.clientWidth, 1000).then(() => {

            })
            BX24.isAdmin()
                .then(isAdminRes => {
                    updateBxInfo()
                        .then(res => {
                            if(res){
                                setBxInfo(res)
                                setFilters({...filters, BXDealId: res?.dealId || 0, BXUserId: res?.userId || 0, BXIsAdmin:  isAdminRes})
                            }
                        })
                        .catch(res => {
                            addMessage(res.message || "Нет данных ошибки")
                        })
                        .finally(res => {
                            setBxInfoIsUpdate(true)
                        })
                })
        }
        else
            setBxInfoIsUpdate(true)
        getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/lanes',
            queryParams: {
                destinationEntity: 'kp'
            },
            successCallback: ({data}) => setLanes(data)
        })


    }, []);

    const memoData = useMemo(() => items?.data?.filter(x => x?.title?.toLowerCase()?.includes(searchValue?.toLowerCase())), [items, searchValue])

    const addKp = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/kp/add',
        data: {
            assignedId,
            dealId,
            clientId,
            tkpId
        },
        successCallback: ({data}) => updateItems()
    })

    const deleteKp = (id) => deleteMethod({
        urlWithoutBaseUrl: `ancorr/api/kp/delete/${id}`,
        successCallback: () => updateItems()
    })

    const changeStageKp = (newItem) => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/kp/edit/stage',
        data: newItem,
        successCallback: () => updateItems()
    })

    const updateItems = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/kp/list/v2',
        queryParams: {
            offset: 15,
            page: page,
            isKanban: isKanban
        },
        data: filters,
        successCallback: ({data}) => setItems(data)

    })

    const copyKp = (kpId) => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/kp/copy',
        queryParams: {
            id: kpId
        },
        successCallback: () => updateItems()
    })

    const {changePage, paginationModule} = usePaginationController({
        pagesCount: items?.pages || 1,
        changePageCallback: (data) => data === 0 ? setPage(1) : setPage(data)
    })

    useEffect(() => {
        if(items?.currentPage > items?.pages){
            if(items?.pages === 0)
                setPage(1)
            else
                setPage(items?.pages)

        }
    }, [items]);

    useEffect(() => {
        if (page === 1) changePage(page);
        if(bxInfoIsUpdate)
            updateItems()
    }, [page, bxInfoIsUpdate, isKanban]);

    const popoverActions = [
        {
            icon: <ListIcon/>,
            title: 'Смена стадии',
            onClick: item => {
                setChangeStageModalVisibleItem(item)
                setChangeStageModalVisible(true)
            }
        },
        {
            icon: <ListIcon/>,
            title: 'Документы',
            onClick: item => setShowDocumentList(true) || setChangeStageModalVisibleItem(item)
        },
        {
            icon: <ListIcon/>,
            title: 'Создать копию',
            onClick: item => copyKp(item?.id)
        },
        {
            icon: <EditIcon/>,
            title: 'Редактировать',
            onClick: item => navigate(`/sales/kp/detail/${item?.id}`)
        },
        {
            icon: <BackCrossIcon/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => deleteKp(item?.id)
        },
    ]

    const access = useCheckAccess("sales/kp")

    if(!access){
        hideAll()
        return <AccessDenied />
    }

    const schema = [
        {
            title: 'Дата создания',
            callbackItem: item => new Date(item?.createdTimeDb)?.toLocaleDateString('ru-RU') || "Нет данных",
        },
        {
            title: 'Название',
            callbackItem: item => item?.title || "Не заполнено",
        },
        {
            title: 'Компания',
            callbackItem: item => item?.companyNameB24 || "Нет данных",
        },
        {
            title: 'Сделка',
            callbackItem: item => item?.dealNameB24 || "Нет данных",
        },
        {
            title: 'Судно',
            callbackItem: item => item?.shipB24 || "Нет данных",
        },
        {
            title: 'Стоимость',
            callbackItem: item => getRubString(item?.totalPriceIncludingPackaging || 0),
        },
        {
            title: 'Ответственный',
            callbackItem: item => item?.assignedB24 || "Нет данных",
        },
        {
            title: 'Стадия',
            callbackItem: item => lanes?.find(x => x?.statusValue === item?.stage?.statusValue)?.statusName || "",
        },
    ]

    const convertFromStatusInfoToFieldItem = () => {
        let lane = lanes?.find(x => x?.statusValue === changeStageModalVisibleItem?.stage?.statusValue)
        return {
            id: lane?.id,
            title: lane?.statusName,
            accessor: lane?.statusValue,
            value: lane?.statusValue,
        }
    }

        const formatDate = (dateToFormat) => {
        const date = new Date(dateToFormat);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;
        return `${formattedDay}.${formattedMonth}.${year}`;
    }
    const oldDate = (date) => {
        const currentDate = new Date();
        return humanizer(currentDate - new Date(date), {
            language: ['ru'],
            largest: 1,
            maxDecimalPoints: 1
        }) + ' назад';
    }



    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'КОММЕРЧЕСКОЕ ПРЕДЛОЖЕНИЕ'}/>
                <div className={' flex center gap'}>
                    <GuidsSearchInput searchValue={searchValue} setSearchValue={setSearchValue}/>
                    <button
                        className={'kp__button'}

                        onClick={addKp}
                    >
                        <AddIcon
                            className={'kp__add-icon'}
                        />
                        Добавить КП
                    </button>
                </div>
            </GuidsHeader>
            <Form className={'w-100 flex left'}>
                <Form.Check // prettier-ignore
                    checked={isKanban}
                    type="switch"
                    label="Вид канбана"
                    onChange={e => setIsKanban(!isKanban)}
                />
            </Form>
            <GuidsBody>
                {isKanban
                    ?
                    <KanbanRenderer
                        lanes={lanes}
                        data={memoData}
                        stageKey={item => item?.stage?.statusValue}
                        sortableKey={item => new Date(item?.modifyTimeDbDb)}
                        addItem={addKp}
                        renderDataItemComponent={item => {
                            return (
                                <div className={'kp-card__wrapper'}>
                                    <div className="kp-card__container">
                                        <div className={'kp-card__created-time'}>
                                            {formatDate(item?.createdTimeDb)}
                                        </div>
                                        <div className={'kp-card__priority'}
                                             style={{background: priority[item?.priority]?.color || ''}}>{priority[item?.priority]?.label || ''}</div>
                                    </div>
                                    <p className="m-0 p-0 kp-card__name">{item?.title}</p>
                                    <div className="m-0 p-0 text-muted kp-card__creds">
                                        <Image
                                            className={'kp-card__avatar'}
                                            src={item?.createdBy?.avatar?.urlPath || 'https://static.tildacdn.com/tild3238-3834-4566-b939-643034303766/no-profile.png'}
                                            alt=""
                                        />

                                        {item?.createdBy?.lastName && item?.createdBy?.firstName ?
                                            `${item?.createdBy?.lastName} ${item?.createdBy?.firstName}`
                                            :
                                            `${item?.createdBy?.email}`
                                        }
                                    </div>
                                    <div className={'kp-card__footer'}>
                                        <div className={'kp-card__footer-price'}>
                                            <RubleIcon/>
                                            {getRubString(item?.totalPriceIncludingPackaging)}
                                        </div>
                                        <div className="kp-card__old-date">
                                            {oldDate(item?.modifyTimeDb)}
                                        </div>
                                    </div>
                                </div>
                            )
                        }}
                        changeLaneItem={e => changeStageKp({...e?.item, stage: e?.to})}
                        onClickItem={item => navigate(`detail/${item?.id}`)}
                    />
                    :
                    <TableBootstrap
                        popoverCaption={'Действия'}
                        items={memoData}
                        schema={schema}
                        popoverActions={popoverActions}
                    />
                }
            </GuidsBody>
            {!isKanban &&
                <GuidsPagination>
                    {
                        paginationModule
                    }
                </GuidsPagination>
            }
            <Modal
                show={changeStageModalVisible}
                onHide={() => {
                    setChangeStageModalVisibleItem(null)
                    setChangeStageModalVisible(false)
                }}
            >
                <Modal.Header><Modal.Title>Смена стадии</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Выберите стадию:</Form.Label>
                            <DropdownDefault
                                options={lanes?.map(x => {
                                    return {
                                        id: x?.id,
                                        title: x?.statusName,
                                        accessor: x?.statusValue,
                                        value: x?.statusValue,
                                    }
                                })}
                                currentValue={convertFromStatusInfoToFieldItem()}
                                placeholder={'Стадия'}
                                onChange={e => setChangeStageModalVisibleItem({
                                    ...changeStageModalVisibleItem,
                                    stage: lanes?.find(x => x?.statusValue === e?.value)
                                })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => {
                        setChangeStageModalVisibleItem(null)
                        setChangeStageModalVisible(false)
                    }}>Закрыть</Button>
                    <Button variant={"primary"} onClick={() => {
                        changeStageKp(changeStageModalVisibleItem)
                        setChangeStageModalVisibleItem(null)
                        setChangeStageModalVisible(false)
                    }}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showDocumentList}
                onHide={() => setShowDocumentList(false) || setChangeStageModalVisibleItem(null)}
                fullscreen
            >
                <Modal.Header><Modal.Title>Документы</Modal.Title></Modal.Header>
                <Modal.Body>
                    <AssociatedEntities
                        kpId={changeStageModalVisibleItem?.id}
                        dealId={changeStageModalVisibleItem?.dealId}
                        clientId={changeStageModalVisibleItem?.clientId}
                        assignedId={assignedId}
                        tkpId={tkpId}
                        specification
                        contract
                        billing
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => setShowDocumentList(false) || setChangeStageModalVisibleItem(null)}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
        </GuidsWrapper>
    );
};


export default KpList;