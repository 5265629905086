import './RemainsPagination.css'
import {usePaginationController} from '../../../../components/pagination/components';
import useRemainsStore from "../../store/remainsStore";
import {useEffect, useMemo, useState} from "react";

const RemainsPagination = () => {
    const remains = useRemainsStore(state => state.remains);
    const currentTab = useRemainsStore(state => state.currentTab);
    const page = useRemainsStore(state => state.page);
    const setPage = useRemainsStore.getState().setPage;
    const [pagesCount, setPagesCount] = useState(0);

    const memoAccessor = useMemo(() => {
        return (currentTab === 'equipments' ? 'pagesEquipments' : 'pagesComponents');
    }, [currentTab, remains])

    useEffect(() => {
        setPagesCount(remains[memoAccessor]);
    }, [remains, memoAccessor])

    const {paginationModule, changePage} = usePaginationController({
        pagesCount,
        defaultAccessor: memoAccessor,
        changePageCallback: setPage
    });

    useEffect(() => {
        if (page === 1) changePage(page);
    }, [page]);

    return (
        <div className={'remains-pagination'}>
            {
                paginationModule
            }
        </div>
    );
};

export default RemainsPagination;