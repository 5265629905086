import {useRef, useState} from "react";
import {generateGuid} from "../../features/guidgenerator/GuidGenerator";
import ModalWrapper from "./ModalWrapper";

const UseModal = () => {

    const [modal, setModal] = useState(null)
    const modalRef = useRef(null)
    const renderModal = ({children, buttons = [], fullscreen = false, size = 'lg', guid = generateGuid(), caption = 'Модальное окно', onHide}) => {

        let newGuid = generateGuid()

        if(modal)
            modalRef?.current?.onHide()

        setModal(
            <ModalWrapper
                ref={modalRef}
                key={newGuid}
                guid={newGuid}
                fullscreen={fullscreen}
                size={size}
                caption={caption}
                onHide={onHide}
                buttons={buttons}
            >
                {children}
            </ModalWrapper>
        )
    }
    const open = () => modalRef.current?.open()
    const close = () => modalRef.current?.close()

    return [
        modal,
        renderModal,
        close,
        open
    ];
};



export default UseModal;