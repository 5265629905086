import './LineKpPreview.css'
import {getRubString} from "../../../../../../features/formatters/forrmaterrub";

const LineKpPreview = ({kp}) => {
    return (
        <div className='line-kp-preview'>
            Всего наименований: {kp?.materials?.length || 0} | На сумму: {getRubString(kp.totalPriceIncludingPackaging)}
        </div>
    );
};

export default LineKpPreview;