import {forwardRef, useImperativeHandle, useState} from "react";
import useLoader from "../../../../../hooks/useLoader";
import {useMutation} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../../hooks/useAxiosHelper";
import {Button, Form, Modal, Stack} from "react-bootstrap";
import TextInput from "../../../../../components/UI/inputs/text/TextInput";
import PhoneInput from "../../../../../components/UI/inputs/phone/PhoneInput";

const CreatingContactModule = forwardRef(({onChange}, ref) => {

    const [visibleModal, setVisibleModal] = useState(false)
    const {show, hide} = useLoader()
    const [value, setValue] = useState({
        firstName: '',
        secondName: '',
        lastName: '',
        phone: '',
    })

    const {mutate: addContact} = useMutation({
        mutationKey: ['contact', 'modal', 'add'],
        mutationFn: ({contactDTO}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: `ancorr/api/contact/add`,
            data: {
                contactDTO
            }
        }),
        onMutate: () => show(),
        onSettled: () => hide(),
        onSuccess: (data) => {
            setValue(data)
            onChange?.(data)
        }
    })


    useImperativeHandle(ref, () => ({
        open: () => setVisibleModal(true),
        close: () => setVisibleModal(false),
    }), [])
    return (
        <Modal onHide={() => setVisibleModal(false)} show={visibleModal} size={"lg"}>
            <Modal.Header><Modal.Title>Создание контакта</Modal.Title></Modal.Header>
            <Modal.Body>
                <Form>
                    <Stack className={'mb-3'}>
                        <TextInput
                            label={'Фамилия'}
                            controlled
                            defaultValue={value?.lastName}
                            onChange={newValue => setValue({...value, lastName: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <TextInput
                            label={'Имя'}
                            controlled
                            defaultValue={value?.firstName}
                            onChange={newValue => setValue({...value, firstName: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <TextInput
                            label={'Отчество'}
                            controlled
                            defaultValue={value?.secondName}
                            onChange={newValue => setValue({...value, secondName: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <TextInput
                            label={'Email'}
                            controlled
                            defaultValue={value?.email}
                            onChange={newValue => setValue({...value, email: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <PhoneInput
                            label={'Телефон'}
                            variant={'outlined'}
                            controlled
                            defaultValue={value?.phone}
                            returnNumber
                            onChange={newValue => setValue({...value, phone: newValue})}
                        />
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"danger"} onClick={() => setVisibleModal(false)}>Закрыть</Button>
                <Button
                    onClick={() => {
                        addContact({contactDTO: value})
                    }}
                >
                    Привязать
                </Button>
            </Modal.Footer>
        </Modal>
    )
});

export default CreatingContactModule;