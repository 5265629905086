import React from 'react';
import './TableRow.css'
import { animated } from "@react-spring/web";

const TableRow = ({children, classNames = '', sizesColumns, style, refMapSetter, ...props}) => {
    return (
        <animated.div
            className={`table__row ${classNames && classNames}`}
            style={{'--sizes-columns': sizesColumns, ...style}}
            ref={ref => ref && refMapSetter && refMapSetter(ref)}
            {...props}
        >
            {children}
        </animated.div>
    );
};

export default TableRow;