import {useEffect, useRef, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import EntitiesSelector from "../../../../modules/entitiesselector/EntitiesSelector";
import MultiField from "../../../../components/multifield/MultiField";
import useDebounce from "../../../../hooks/useDebounce";


const TkpField = ({title, text, large, setValue, templatesValue}) => {
    const textareaRef = useRef(null);
    const [showModalSelectTemplate, setShowModalSelectTemplate] = useState(false)
    const [localValue, setLocalValue] = useState(null);

    useEffect(() => {
        setLocalValue(text);
    }, [])

    const debouncedValue = useDebounce({
        value: localValue,
        delay: 1000,
        callback: (value) => {
            setValue(debouncedValue);
        }
    })

    return (
        <div>
            <MultiField
                title={title || ''}
                onClick={() => {
                    if (templatesValue && !text)
                        setShowModalSelectTemplate(true);
                    textareaRef.current?.focus?.();
                }}
            >
                <MultiField.Textarea
                    text={localValue || ''}
                    onChange={e => setLocalValue(e.target.value)}
                    ref={textareaRef}
                />
            </MultiField>
            <Modal show={showModalSelectTemplate} onHide={() => setShowModalSelectTemplate(false)}>
                <Modal.Header><Modal.Title>Выбор</Modal.Title></Modal.Header>
                <Modal.Body>
                    <EntitiesSelector
                        entities={templatesValue}
                        nameSelector={item => item?.title}
                        keySelector={item => item?.id}
                        onClickItem={item => {
                            setShowModalSelectTemplate(false);
                            setLocalValue(item?.item?.value || '')
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowModalSelectTemplate(false)} variant={"danger"}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default TkpField;