import {useNavigate, useParams} from "react-router";
import {useInteractionBackend} from "../../../../hooks/useInteractionBackend";
import {MaterialsService} from "../API/MaterialsService";
import useMaterialsStore from "../store/materialsStore";
import {useEffect} from "react";
import {useBreadcrumbsStore} from "../../../../components/breadcrumbs";
import equipment from "../../equipment/axios/equipment/Equipment";


const UseMaterialsController = () => {
    const setMaterials = useMaterialsStore.getState().setMaterials;
    const breadcrumbs = useBreadcrumbsStore(state => state.breadcrumbs);
    const addBreadcrumb = useBreadcrumbsStore.getState().addBreadcrumb;
    const {type, id} = useParams();
    const navigate = useNavigate();

    const [getMaterials] = useInteractionBackend({
        cb: MaterialsService.getMaterials,
        thenCb: ({data}, folderId, nomenclatureId) => {
            if (folderId && !nomenclatureId) {
                checkBreadcrumbs(data.currentFolder.name);
            }
            if (!folderId && nomenclatureId) {
                checkBreadcrumbs(data.currentNomenclature.name);
            }
            setMaterials(data)
        },
        type: 'axios'
    });

    const [getComponent] = useInteractionBackend({
        cb: MaterialsService.getComponent,
        thenCb: ({data}) => {
            checkBreadcrumbs(data.name)
            setMaterials({component: data})
        },
        type: 'axios'
    })

    const [getEquipment] = useInteractionBackend({
        cb: MaterialsService.getEquipment,
        thenCb: ({data}) => {
            checkBreadcrumbs(data.name);
            setMaterials({equipment: data})
        },
        type: 'axios'
    })

    const handleChangeBreadcrumb = (type, id) => {
        switch (type) {
            case 'folder':
                getMaterials(id, null);
                break;
            case 'nomenclature':
                getMaterials(null, id);
                break;
            case 'equipment':
                getEquipment(id);
                break;
            case 'component':
                getComponent(id);
                break;
            default:
                getMaterials();
                break;
        }
    }

    const checkBreadcrumbs = (name) => {
        if (breadcrumbs.length === 0) addBreadcrumb(
            name,
            () => navigate(`/profile/materials/${type}/${id}`),
        )
    }

    useEffect(() => {
            handleChangeBreadcrumb(type, id);
    }, [breadcrumbs])
};

export default UseMaterialsController;