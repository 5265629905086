import {useRef} from "react";
import {Autocomplete, TextField} from "@mui/material";
import useContactsSearcher from "../../../hooks/clients/useContactsSearcher";
import CreatingContactModule from "./modals/add/CreatingContactModule";
import EditContactModule from "./modals/edit/EditContactModule";
import EditIcon from "../../../components/UI/icons/EditIcon";
import {Stack} from "react-bootstrap";
import {useMutation} from "@tanstack/react-query";
import {AxiosHelper} from "../../../hooks/useAxiosHelper";
import useControlledValue from "../../../hooks/controlled/useControlledValue";

const ContactEditInputModule = ({defaultValue = null, onChange, label= '', sx, controlled = false}) => {
    const createModuleControlsRef = useRef(null)
    const editModuleControlsRef = useRef(null)
    const {optionsContacts, inputContacts, updateInputContacts, onBlur} = useContactsSearcher()
    const [value, setValue] = useControlledValue({defaultValue, controlled})

    const {mutate: updateContact} = useMutation({
        mutationKey: ['contact', 'input', 'update'],
        mutationFn: (id) => AxiosHelper.getMethod({
            urlWithoutBaseUrl: `ancorr/api/contact/get/${id}`
        }),
        onSuccess: (data) => {
            setValue(data)
            onChange?.(data)
        }
    })

    return (
        <Stack direction={"horizontal"} className={'gap-2'}>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    if(newValue?.fio === 'Добавить новый контакт')
                        createModuleControlsRef?.current?.open()
                    else{
                        setValue(newValue)
                        onChange?.(newValue)
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    if(newInputValue !== 'Добавить новый контакт' && newInputValue)
                        updateInputContacts(newInputValue)
                }}
                onBlur={() => onBlur()}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                inputValue={inputContacts}
                suppressContentEditableWarning={true}
                suppressHydrationWarning={true}
                options={optionsContacts}
                getOptionLabel={option => option?.fio || 'Не заполнено'}
                getOptionKey={option => option?.id}
                sx={sx ? sx : { width: 400 }}
                size="small"
                filterOptions={(options, params) => {

                    return [...options, {fio: 'Добавить новый контакт', id: 999999}]
                }}
                renderInput={(params) => <TextField {...params} variant={'standard'} label={label} placeholder={'Телефон, фио'} />}
            />
            {value &&
                <Stack onClick={() => editModuleControlsRef.current?.open()} className={'justify-content-center align-content-center pointer'}>
                    <EditIcon/>
                </Stack>
            }
            <CreatingContactModule
                ref={createModuleControlsRef}
                onChange={newValue => {
                    setValue(newValue)
                    onChange?.(newValue)
                    createModuleControlsRef?.current?.close()
                }}
            />
            <EditContactModule
                ref={editModuleControlsRef}
                controlled
                defaultValue={value}
                onChange={newValue => {
                    setValue(newValue)
                    if(newValue?.id)
                        updateContact(newValue?.id)
                    else
                        onChange?.(newValue)
                    editModuleControlsRef?.current?.close()
                }}
            />
        </Stack>

    );
};

export default ContactEditInputModule;