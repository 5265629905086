import {useEffect, useRef, useState} from 'react';
import useLoader from "./useLoader";
import useMessageHub from "./useMessageHub";

const UseCheckConnectionToHub = ({SignalRContext, onErrorCallback, onConnectCallback, onReconnectCallback, onCloseCallback, onBeforeCloseCallback, initialFunk}) => {
    const [connectionRef, setConnectionRef] = useState(false)
    const connectionRefer = useRef(false)
    const addMessage = useMessageHub()
    const {show, hide} = useLoader()

    useEffect(() => {
        const intervalID = setInterval(() => {
            if(connectionRefer.current){
                initialFunk?.();
                clearInterval(intervalID)
            }
        }, 1500)
    }, [])
    useEffect(() => {
        if (connectionRef) {
            addMessage('Соединение установлено');
        }
        if (!connectionRef) {
            show();
        } else {
            hide();
        }
    }, [connectionRef])
    const checkConnection = () => connectionRefer.current

    const onError = (e) => {
        if(e)
            onErrorCallback?.(e);
        else
            onErrorCallback?.();
        setConnectionRef(false)
        connectionRefer.current = false

    }
    const onConnect = (e) => {
        onConnectCallback?.(e);
        setConnectionRef(true)
        connectionRefer.current = true
    }
    const onReconnect = (e) => {
        onReconnectCallback?.(e);
        setConnectionRef(true)
        connectionRefer.current = true
    }
    const onClose = (e) => {
        onCloseCallback?.(e);
        setConnectionRef(false)
        connectionRefer.current = false
    }
    const onBeforeClose = (e) => {
        onBeforeCloseCallback?.(e);
        setConnectionRef(false)
        connectionRefer.current = false
    }

    return {
        connectionRef: connectionRefer.current,
        checkConnection,
        onError,
        onConnect,
        onReconnect,
        onClose,
        onBeforeClose
    };
};

export default UseCheckConnectionToHub;