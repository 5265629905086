import './KpButton.css'

const KpButton = ({onClick , id, className, children, ...props}) => {
    return (
        <button
            onClick={onClick}
            id={id ? id : ''}
            className={(id ? `${id}` : 'kp-button') + (className ? ` ${className}` : '')}
            {...props}
        >
            {children}
        </button>
    );
};

export default KpButton;