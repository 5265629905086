import {useEffect, useMemo, useState} from 'react';
import './ReservedList.css'
import {
    converterEntityToOptionOrReverse,
    getOptionsFromArrayEntities
} from "../../../../../../features/entityhelper/EntityHelper";
import {BaseUrl} from "../../../../../../features/databaseinfo/BaseUrl";
import {createSignalRContext} from "react-signalr/signalr";
import useCheckConnectionToHub from "../../../../../../hooks/useCheckConnectionToHub";
import useChangeItemSignalR from "../../../../../../hooks/useChangeItemSignalR";
import useSignalRFunk from "../../../../../../hooks/useSignalRFunk";
import GuidsHeader from "../../../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../../../components/guids/search/GuidsSearchInput";
import GuidsBody from "../../../../../../components/guids/body/GuidsBody";
import TableBootstrap from "../../../../../../components/tableb/TableBootstrap";
import GuidsWrapper from "../../../../../../components/guids/wrapper/GuidsWrapper";
import useLoader from "../../../../../../hooks/useLoader";
import useCheckAccess from "../../../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../../../components/access/AccessDenied";
import EditIcon from "../../../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../../../components/UI/icons/BackCrossIcon";
import CrossIcon from "../../../../../../components/UI/icons/CrossIcon";
import {Button, Form, InputGroup, Modal} from "react-bootstrap";
import DropdownDefault from "../../../../../../components/UI/selects/dropdowndefault/DropdownDefault";
import ListIcon from "../../../../../../components/UI/icons/ListIcon";
import {useAuth} from "../../../../../../features/authprovider/AuthProvider";
const SignalRContext = createSignalRContext()

const ReservedList = ({component, updateRemainsCallback}) => {
    const [reservedForEdit, setReservedForEdit] = useState(null)
    const [showReservedEdit, setShowReservedEdit] = useState(false)
    const [searchValue, setSearchValue] = useState([])
    const [items, setItems] = useState([])
    const {hideAll} = useLoader()
    const {userInfo} = useAuth()
    const [warehouses, setWarehouses] = useState([])
    useEffect(() => {

    }, [])
    const {
        checkConnection,
        onError,
        onConnect,
        onReconnect
    } = useCheckConnectionToHub({
        SignalRContext,
        initialFunk: () => {
            updateReserveds();
            updateWarehouses()
        },
        onReconnectCallback: () => {
            updateReserveds();
            updateWarehouses()
        }
    })
    const {signalRFunk} = useSignalRFunk({
        SignalRContext,
        checkConnection
    })
    const {changeItem} = useChangeItemSignalR({
        SignalRContext,
        checkConnection,
        delay: 1000,
        method: 'ChangeReserved',
        argsExternal: [component?.id],
        callbackChangeItemFunk: newItem => {
            setItems(prev => prev.map(item => {
                if(item?.guid === newItem?.guid) return newItem
                else return item;
            }))
            setTimeout(() => {
                updateRemainsCallback()
            }, 1500)
        }
    })
    const updateReserveds = () => signalRFunk('GetAllReserveds', [component?.id], res => setItems(res))

    const updateWarehouses = () => signalRFunk('GetAllWarehouses', [], res => setWarehouses(res))
    const acceptItem = (id) => signalRFunk('AcceptReserved', [id], res => {
        setItems(prev => {
            let item = prev.find(x => x?.id === id)
            item.isClose = true;
            return [...prev, item]
        })
        setTimeout(() => {
            updateRemainsCallback()
        }, 1500)
    })
    const addItem = () => signalRFunk('AddReserved', [component?.id], res => {
        setItems(prev => [...prev, res])
        setTimeout(() => {
            updateRemainsCallback()
        }, 1500)
    })
    const removeItem = (guid) => signalRFunk('RemoveReserved', [guid], res => {
        setItems(prev => prev.filter(x => x?.guid !== guid))
        setTimeout(() => {
            updateRemainsCallback()
        }, 1500)
    })

    const memoReserveds = useMemo(() => {
        return items.filter(x => !x.isClose)
    }, [items, component])

    SignalRContext.useSignalREffect("UpdateReservedForAllUsers", () => {
        updateReserveds()
    })
    SignalRContext.useSignalREffect("UpdateRemains", () => {
        updateRemainsCallback()
    })
    const schema = [
        {
            title: 'ID',
            callbackItem: item => item?.id,
        },
        {
            title: 'Склад резервирования',
            callbackItem: item => item?.warehouse?.fullName || 'Не выбрано',
        },
        {
            title: 'Кол-во',
            callbackItem: item => item?.quantity,
        },
        {
            title: 'Дата завершения резервирования',
            callbackItem: item => new Date(item?.reservedDate || null)?.toLocaleDateString(),
        },
    ]

    const popoverActions = [
        {
            icon: <CrossIcon classNames={'cl-light-purple'}/>,
            title: 'Закрыть резервирование',
            onClick: item => acceptItem(item?.id),
            condition: item => !item?.isClose
        },
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item => {
                setShowReservedEdit(true)
                setReservedForEdit(item)
            }
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => removeItem(item?.guid)
        },
    ]

    const access = useCheckAccess("component/reserved")

    if(!access){
        hideAll()
        return <AccessDenied />
    }


    return (
        <SignalRContext.Provider
            withCredentials={false}
            connectEnabled={true}
            url={`${BaseUrl}/ancorr/api/reservedlisthub/?token=${userInfo?.accessToken || ''}`}
            accessTokenFactory={() => userInfo?.accessToken || ''}
            onOpen={onConnect}
            onError={onError}
            onReconnect={onReconnect}
        >
            <GuidsWrapper>
                <GuidsHeader>
                    <TitleDetailCard text={'Резервирование'}/>
                    <div className={'flex center gap'}>
                        <GuidsSearchInput searchValue={searchValue} setSearchValue={setSearchValue}/>
                        <Button variant={'primary'} onClick={() => addItem()}>Добавить резервирование</Button>
                    </div>
                </GuidsHeader>
                <GuidsBody>
                    <TableBootstrap
                        schema={schema}
                        items={memoReserveds}
                        popoverActions={popoverActions}
                        popoverCaption={"Действия"}
                    />
                </GuidsBody>
            </GuidsWrapper>
            <Modal show={showReservedEdit} onHide={() => {
                setShowReservedEdit(false)
                setReservedForEdit(null)
            }}>
                <Modal.Header>
                    <Modal.Title>Редактирование резервирования</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Склад резервирования</Form.Label>
                            <InputGroup>
                                <InputGroup.Text><ListIcon/></InputGroup.Text>
                                <DropdownDefault
                                    options={getOptionsFromArrayEntities({
                                        entities: warehouses || [],
                                        accessorName: 'brand',
                                        valueCallback: itemC => itemC?.id,
                                        titleCallback: itemC => itemC?.fullName,
                                        idCallback: itemC => itemC?.id
                                    }) || []}
                                    currentValue={converterEntityToOptionOrReverse({
                                        arrayEntities: warehouses,
                                        endpoint: 'option',
                                        accessorName: 'warehouse',
                                        valueCallback: itemC => itemC?.id,
                                        titleCallback: itemC => itemC?.fullName,
                                        idCallback: itemC => itemC?.id,
                                        searchPropertyForArray: reservedForEdit?.warehouse?.id,
                                    })}
                                    onChange={e => setReservedForEdit({...reservedForEdit, warehouse: converterEntityToOptionOrReverse({
                                            endpoint: 'entity',
                                            arrayEntities: warehouses || [],
                                            searchPropertyForArray: e?.value
                                        })})}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Дата окончания резервирования</Form.Label>
                            <InputGroup>
                                <InputGroup.Text><ListIcon/></InputGroup.Text>
                                <Form.Control type={'date'} value={reservedForEdit?.reservedDate || ''} onChange={e => setReservedForEdit({...reservedForEdit, reservedDate: e.target.value})}/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Количество</Form.Label>
                            <InputGroup>
                                <InputGroup.Text><ListIcon/></InputGroup.Text>
                                <Form.Control type={'number'} value={reservedForEdit?.quantity || ''} onChange={e => setReservedForEdit({...reservedForEdit, quantity: Number(e.target.value)})}/>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'danger'} onClick={() => {
                        setShowReservedEdit(false)
                        setReservedForEdit(null)
                    }}>Отмена</Button>
                    <Button variant={'primary'} onClick={() => {
                        setShowReservedEdit(false)
                        setReservedForEdit(null)
                        changeItem(reservedForEdit)
                    }}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
        </SignalRContext.Provider>
    );
};



export default ReservedList;