import {useState} from 'react';
import './FieldItemList.css'
import GuidsWrapper from "../../../../../components/guids/wrapper/GuidsWrapper";
import GuidsHeader from "../../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../../components/guids/search/GuidsSearchInput";
import GuidsBody from "../../../../../components/guids/body/GuidsBody";
import TableBootstrap from "../../../../../components/tableb/TableBootstrap";
import ListIcon from "../../../../../components/UI/icons/ListIcon";
import EditIcon from "../../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import {Button, Form, InputGroup, Modal} from "react-bootstrap";
import useAxiosHelper from "../../../../../hooks/useAxiosHelper";
const FieldItemList = ({fieldInItemList, setNewField, updateBlocks}) => {
    const [searchValue, setSearchValue] = useState([])
    const [showModalItemForEdit, setShowModalItemForEdit] = useState(false)
    const [itemForEdit, setItemForEdit] = useState(null)
    const {postMethod, deleteMethod} = useAxiosHelper({})

    const addFieldItem = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/field/add/field/item',
        queryParams: {
            fieldId: fieldInItemList?.id
        },
        successCallback: ({data}) => setNewField({...fieldInItemList, items: [...fieldInItemList?.items, data]})
    })
    const deleteFieldItem = (guid) => deleteMethod({
        urlWithoutBaseUrl: 'ancorr/api/field/delete/field/item',
        queryParams: {
            guid,
            fieldId: fieldInItemList?.id
        },
        successCallback: () => setNewField({...fieldInItemList, items: fieldInItemList?.items?.filter(x => x?.guid !== guid)})
    })
    const changeItem = (newItem) => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/field/update/field/item',
        queryParams: {
            fieldId: fieldInItemList?.id
        },
        data: newItem,
        successCallback: () => {
            setNewField({...fieldInItemList, items: fieldInItemList?.items?.map(item => {
                    if(item?.guid === newItem?.guid) return newItem;
                    else return item;
                })})
        }
    })
    const onSaveNewItemForField = () => {
        setShowModalItemForEdit(false)
        setItemForEdit(null)
        changeItem(itemForEdit)
        updateBlocks()
    }

    const popoverActions = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item => {
                setShowModalItemForEdit(true)
                setItemForEdit(item)
            }
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => deleteFieldItem(item?.guid)
        },
    ]

    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'СПИСОК VALUES'}/>
                <div className={'flex center gap'}>
                    <GuidsSearchInput searchValue={searchValue} setSearchValue={setSearchValue}/>
                    <Button
                        variant={'primary'}
                        className={'button-extension'}
                        onClick={() => addFieldItem()}
                    >
                        Добавить value
                    </Button>
                </div>
            </GuidsHeader>
            <GuidsBody>
                <TableBootstrap
                    schema={schema}
                    items={fieldInItemList?.items || []}
                    popoverActions={popoverActions}
                    popoverCaption={"Действия"}
                />
            </GuidsBody>
            <Modal show={showModalItemForEdit} onHide={() => {
                setShowModalItemForEdit(false)
                setItemForEdit(null)
            }}>
                <Modal.Header>
                    <Modal.Title>Редактирование value</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <InputGroup className={'mb-2'}>
                            <InputGroup.Text><ListIcon/></InputGroup.Text>
                            <Form.Control
                                type={'text'}
                                value={itemForEdit?.value || ''}
                                placeholder={'Value'}
                                onChange={e => setItemForEdit({...itemForEdit, value: e.target.value})}
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text><ListIcon/></InputGroup.Text>
                            <Form.Control
                                type={'number'}
                                value={itemForEdit?.position || ''}
                                placeholder={'Позиция'}
                                onChange={e => setItemForEdit({...itemForEdit, position: Number(e.target.value)})}
                            />
                        </InputGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'danger'} onClick={() => {
                        setShowModalItemForEdit(false)
                        setItemForEdit(null)
                    }}>Закрыть</Button>
                    <Button variant={'primary'} onClick={() => onSaveNewItemForField()}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
        </GuidsWrapper>
    );
};

const schema = [
    {
        title: 'ID',
        callbackItem: item => item?.id,
    },
    {
        title: 'Значение',
        callbackItem: item => item?.value,
    },
    {
        title: 'Позиция',
        callbackItem: item => item?.position || 'Не заполнено',
    },
]

export default FieldItemList;