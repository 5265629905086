import {useEffect, useMemo, useState} from 'react';
import './GuidsCurrencyList.css'
import GuidsWrapper from "../../../../../components/guids/wrapper/GuidsWrapper";
import GuidsHeader from "../../../../../components/guids/header/GuidsHeader";
import GuidsBody from "../../../../../components/guids/body/GuidsBody";
import TitleDetailCard from "../../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../../components/guids/search/GuidsSearchInput";
import TableBootstrap from "../../../../../components/tableb/TableBootstrap";
import useLoader from "../../../../../hooks/useLoader";
import {useNavigate} from "react-router";
import EditIcon from "../../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import useCheckAccess from "../../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../../components/access/AccessDenied";
import {AxiosHelper} from "../../../../../hooks/useAxiosHelper";
import {usePaginationController} from "../../../../../components/pagination/components";
import GuidsPagination from "../../../../../components/guids/pagination/GuidsPagination";
import useDocumentTitle from "../../../../../hooks/useDocumentTitle";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Button, Container} from "react-bootstrap";

const GuidsCurrencyList = () => {

    const [searchValue, setSearchValue] = useState('')
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const {hideAll} = useLoader()
    const navigate = useNavigate()

    useDocumentTitle({title: 'Валюты'})

    const client = useQueryClient();

    const {data: items} = useQuery({
        queryKey: ['currency-list', page, filters],
        queryFn: ({queryKey: [key, page, filters]}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/currency/list',
            queryParams: {
                offset: 17,
                page: page
            },
            data: filters,
        })
    })

    const {mutate: addItem} = useMutation({
        mutationFn: (newItem) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/currency/add',
        }),
        onSuccess: () => client.invalidateQueries({queryKey: {queryKey: ['currency-list']}}),
    })

    const {mutate: removeItem} = useMutation({
        mutationFn: (guid) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/currency/delete',
            queryParams: {
                guid
            }
        }),
        onSuccess: () => client.invalidateQueries({queryKey: {queryKey: ['currency-list']}}),
    })

    const {changePage, paginationModule} = usePaginationController({
        pagesCount: items?.pages || 1,
        changePageCallback: (data) => setPage(data)
    })

    useEffect(() => {
        if (items?.currentPage > items?.pages) {
            if (items?.pages === 0) setPage(1)
            else setPage(items?.pages)
        }
    }, [items]);

    const memoData = useMemo(
        () => items?.data
            ?.filter(currency => currency?.name?.toLowerCase()
                ?.includes(searchValue?.toLowerCase())),
        [items]
    );

    const popoverActions = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item => navigate(`currencies/detail/${item?.id}`)
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => removeItem(item?.guid)
        },
    ]

    const access = useCheckAccess("guids/currencies")
    if (!access) {
        hideAll()
        return <AccessDenied/>
    }

    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'СПИСОК ВАЛЮТ'}/>
                <Container fluid className={'d-flex justify-content-end gap-2'}>
                    <GuidsSearchInput onChange={newValue => setSearchValue(newValue)}/>
                    <Button onClick={() => addItem()}>Добавить</Button>
                </Container>
            </GuidsHeader>
            <GuidsBody>
                <TableBootstrap
                    schema={schema}
                    items={memoData || []}
                    popoverActions={popoverActions}
                    popoverCaption={"Действия"}
                />
            </GuidsBody>
            <GuidsPagination>
                {
                    paginationModule
                }
            </GuidsPagination>
        </GuidsWrapper>
    );
};

const schema = [
    {
        title: 'ID',
        callbackItem: item => item?.id,
    },
    {
        title: 'Полное наименование',
        callbackItem: item => item?.name,
    },
    {
        title: 'Код валюты',
        callbackItem: item => item?.currencyCode,
    },
    {
        title: 'Курс к рублю',
        callbackItem: item => item?.courseToRUB,
    },
]

export default GuidsCurrencyList;