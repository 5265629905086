import {useNavigate, useParams} from "react-router";
import DetailWrapper from "../../../../../components/detailcard/wrapper/DetailWrapper";
import TabsBlock from "../../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../../components/UI/buttons/buttontab/ButtonTab";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import DetailBody from "../../../../../components/detailcard/body/DetailBody";
import FieldsBlockRenderer from "../../../../../modules/fieldblockrenderer/FieldsBlockRenderer";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../../hooks/useAxiosHelper";

const GuidsCurrencyDetail = () => {
    const params = useParams()
    const navigate = useNavigate()

    const client = useQueryClient();
    const {data: currentCurrency} = useQuery({
        queryKey: ['current-currency'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: `ancorr/api/currency/get/${Number(params['id'] || 0)}`,
        }),
    })

    const {data: schema} = useQuery({
        queryKey: ['schema-currency'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/schema',
            queryParams: {
                destinationEntity: 'currency'
            },
        }),
    })

    const {mutate: changeItem} = useMutation({
        mutationFn: (newItem) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/currency/update',
            data: newItem,
        }),
        onSuccess: () => client.invalidateQueries({queryKey: {queryKey: ['current-client']}})
    })

    return (
        <DetailWrapper>
            <TabsBlock>
                <ButtonTab
                    icon={<BackCrossIcon classNames={'icon-extension'}/>}
                    title={'Назад к списку'}
                    onClick={() => navigate(-1)}
                />
            </TabsBlock>
            <DetailBody>
                <FieldsBlockRenderer
                    schema={schema}
                    model={currentCurrency}
                    onSave={newItem => changeItem(newItem)}
                />
            </DetailBody>
        </DetailWrapper>
    );
};

export default GuidsCurrencyDetail;