import './ComponentColumn.css'
import MultiField from "../../../../../../../components/multifield/MultiField";

const ComponentColumn = ({fields}) => {
    return (
        <div className="component-column">
            {fields.map(field => (
                <MultiField
                    key={field.title}
                    title={field.title}
                    readonly
                >
                    {field.value}
                </MultiField>
            ))}
        </div>
    );
};

export default ComponentColumn;