import {useState} from 'react';
import './CompaniesList.css'
import GuidsWrapper from "../../../../../../components/guids/wrapper/GuidsWrapper";
import GuidsHeader from "../../../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsBody from "../../../../../../components/guids/body/GuidsBody";
import TableBootstrap from "../../../../../../components/tableb/TableBootstrap";
import {Button, Container, Form, Modal} from "react-bootstrap";
import EditIcon from "../../../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../../../components/UI/icons/BackCrossIcon";
import GuidsSearchInput from "../../../../../../components/guids/search/GuidsSearchInput";
import {AxiosHelper} from "../../../../../../hooks/useAxiosHelper";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useAuth} from "../../../../../../features/authprovider/AuthProvider";
import {useParams} from "react-router";
const CompaniesList = () => {
    const params = useParams()
    const id = Number(params['id'] || 0);
    const [searchValue, setSearchValue] = useState('')
    const [showModalCheckInn, setShowModalCheckInn] = useState(false)
    const [innToCheck, setInnToCheck] = useState(0)
    const [showModalEditCompany, setShowModalEditCompany] = useState(false)
    const [companyToEdit, setCompanyToEdit] = useState(null)
    const {userInfo} = useAuth()
    const client = useQueryClient()

    const currentClient = client.getQueryData(['current-client', id])

    const {mutate: addCompany} = useMutation({
        mutationKey: ['current-client', id],
        mutationFn: ({innToCheck, id, createdUserId}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/client/add/company',
            queryParams: {
                inn: innToCheck,
                createdUserId: createdUserId,
                id: id
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-client', id]}) || setInnToCheck(0),
    })
    const {mutate: deleteCompanyFromClient} = useMutation({
        mutationKey: ['current-client', id],
        mutationFn: ({guid, id}) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/client/delete/company',
            queryParams: {
                id: id,
                guid: guid,
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-client', id]}) || setInnToCheck(0),
    })
    const {mutate: updateCompany} = useMutation({
        mutationKey: ['current-client', id],
        mutationFn: (newItem) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/client/update/company',
            data: newItem,
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-client', id]}),
    })

    const popoverActions = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item => setCompanyToEdit(item) || setShowModalEditCompany(true)
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => deleteCompanyFromClient({guid: item?.guid, id})
        },
    ]

    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'СПИСОК КОМПАНИЙ'}/>
                <Container fluid className={'d-flex justify-content-end gap-2'}>
                    <GuidsSearchInput onChange={newValue => setSearchValue(newValue)}/>
                    <Button onClick={() => setShowModalCheckInn(true)}>Добавить компанию</Button>
                </Container>
            </GuidsHeader>
            <GuidsBody>
                <TableBootstrap
                    schema={schema}
                    items={currentClient?.additionalCompanies || []}
                    popoverActions={popoverActions}
                    popoverCaption={"Действия"}
                />
            </GuidsBody>
            <Modal show={showModalCheckInn} onHide={() => setShowModalCheckInn(false) || setInnToCheck(0)}>
                <Modal.Header><Modal.Title>Привязка компании</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Введите ИНН:</Form.Label>
                            <Form.Control
                                type={'number'}
                                value={innToCheck}
                                onChange={e => setInnToCheck(Number(e.target.value || 0))}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => setShowModalCheckInn(false) || setInnToCheck(0)}>Закрыть</Button>
                    <Button variant={"primary"} onClick={() => setShowModalCheckInn(false) || addCompany({innToCheck, id, createdUserId: userInfo?.id})}>Привязать</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalEditCompany} onHide={() => setShowModalEditCompany(false) || setCompanyToEdit(null)}>
                <Modal.Header><Modal.Title>Редактирование компании</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>ИНН:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'number'}
                                value={companyToEdit?.inn || 0}
                                onChange={e => setCompanyToEdit({...companyToEdit, inn: Number(e.target.value || 0)})}
                            />
                            <Form.Label>КПП:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'number'}
                                value={companyToEdit?.kpp || 0}
                                onChange={e => setCompanyToEdit({...companyToEdit, kpp: Number(e.target.value || 0)})}
                            />
                            <Form.Label>Полное наименование:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.fullCompanyName || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, fullCompanyName: e.target.value})}
                            />
                            <Form.Label>Короткое наименование:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.shortCompanyName || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, shortCompanyName: e.target.value})}
                            />
                            <Form.Label>ОГРН:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'number'}
                                value={companyToEdit?.ogrn || 0}
                                onChange={e => setCompanyToEdit({...companyToEdit, ogrn: Number(e.target.value || 0)})}
                            />
                            <Form.Label>ОКПО:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'number'}
                                value={companyToEdit?.okpo || 0}
                                onChange={e => setCompanyToEdit({...companyToEdit, okpo: Number(e.target.value || 0)})}
                            />
                            <Form.Label>Индекс юридического адреса:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'number'}
                                value={companyToEdit?.legalAddressIndex || 0}
                                onChange={e => setCompanyToEdit({...companyToEdit, legalAddressIndex: Number(e.target.value || 0)})}
                            />
                            <Form.Label>Индекс почтового адреса:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'number'}
                                value={companyToEdit?.postalAddressIndex || 0}
                                onChange={e => setCompanyToEdit({...companyToEdit, postalAddressIndex: Number(e.target.value || 0)})}
                            />
                            <Form.Label>Индекс фактического адреса:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'number'}
                                value={companyToEdit?.actualAddressIndex || 0}
                                onChange={e => setCompanyToEdit({...companyToEdit, actualAddressIndex: Number(e.target.value || 0)})}
                            />
                            <Form.Label>ОКВЕД:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.okved || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, okved: e.target.value})}
                            />
                            <Form.Label>Юридический адрес:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.legalAddress || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, legalAddress: e.target.value})}
                            />
                            <Form.Label>Почтовый адрес:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.postalAddress || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, postalAddress: e.target.value})}
                            />
                            <Form.Label>Фактический адрес:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.actualAddress || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, actualAddress: e.target.value})}
                            />
                            <Form.Label>Расчетный счет:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.paymentAccount || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, paymentAccount: e.target.value})}
                            />
                            <Form.Label>Корреспондентский счет:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.correspondentAccount || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, correspondentAccount: e.target.value})}
                            />
                            <Form.Label>БИК:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.bik || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, bik: e.target.value})}
                            />
                            <Form.Label>Наименование банка:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.bankName || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, bankName: e.target.value})}
                            />
                            <Form.Label>Должность директора:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.directorPosition || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, directorPosition: e.target.value})}
                            />
                            <Form.Label>ФИО директора(полное):</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.fioDirector || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, fioDirector: e.target.value})}
                            />
                            <Form.Label>ФИО директора(инициалы):</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.fioDirectorShort || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, fioDirectorShort: e.target.value})}
                            />
                            <Form.Label>ФИО бухгалтера(полное):</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.fioAccountant || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, fioAccountant: e.target.value})}
                            />
                            <Form.Label>ФИО бухгалтера(инициалы):</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.fioAccountantShort || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, fioAccountantShort:e.target.value})}
                            />
                            <Form.Label>Действующего на основании(для договора):</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.actingBasicOn || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, actingBasicOn: e.target.value})}
                            />
                            <Form.Label>Телефон компании:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'number'}
                                value={companyToEdit?.companyPhone || 0}
                                onChange={e => setCompanyToEdit({...companyToEdit, companyPhone: Number(e.target.value || 0)})}
                            />
                            <Form.Label>Почта компании:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={companyToEdit?.companyEmail || ''}
                                onChange={e => setCompanyToEdit({...companyToEdit, companyEmail:e.target.value})}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => setShowModalEditCompany(false) || setCompanyToEdit(null)}>Закрыть</Button>
                    <Button variant={"primary"} onClick={() => {
                        updateCompany(companyToEdit)
                        setShowModalEditCompany(false)
                        setCompanyToEdit(null)
                    }}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
        </GuidsWrapper>
    );
};

const schema = [
    {
        title: 'ID',
        callbackItem: item => item?.id,
    },
    {
        title: 'ИНН',
        callbackItem: item => item?.inn,
    },
    {
        title: 'Полное наименование',
        callbackItem: item => item?.fullCompanyName,
    },
]

export default CompaniesList;