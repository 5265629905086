const paymentTerms = [
    {id: 1, value: '100 % предварительная оплата товара по счету. в срок в течение 5 календарных дней с момента выставления счета Поставщиком', title: '100 %', accessor: 'paymentTerms'},
    {id: 2, value: '50% предоплата согласно счета в срок в течение 5 календарных дней с момента подписания данной спецификации. Окончательная оплата производится в размере 50% - в течение 20 (двадцати) дней с даты приемки Товара Покупателем (подписание УПД).', title: '50 %', accessor: 'paymentTerms'},
    {id: 3, value: '100% отсрочка платежа на срок 10 (десять) календарных дней с с даты приемки Товара Покупателем (подписание УПД).', title: 'Отсрочка', accessor: 'paymentTerms'},
]
const deliveryMethod = [
    {id: 1, value: 'Самовывоз со склада Поставщика', title: 'Самовывоз', accessor: 'deliveryMethod'},
    {id: 2, value: 'Сборным грузом транспортной компанией на склад "Покупателя" за счет «Поставщика».', title: 'На склад за счет поставщика', accessor: 'deliveryMethod'},
    {id: 3, value: 'Сборным грузом транспортной компанией на склад "Покупателя" за счет «Покупателя».', title: 'На склад за счет покупателя', accessor: 'deliveryMethod'},
    {id: 4, value: 'Сборным грузом до терминал транспортной компании за счет «Поставщика».', title: 'В терминал за счет поставщика', accessor: 'deliveryMethod'},
    {id: 5, value: 'Сборным грузом до терминал транспортной компании за счет «Покупателя».', title: 'В терминал за счет покупателя', accessor: 'deliveryMethod'},
    {id: 6, value: 'Отдельной машиной транспортной компании на склад "Покупателя" за счет «Поставщика».', title: 'Отдельной машиной', accessor: 'deliveryMethod'},
]
const shippingTime = [
    {id: 1, value: '2–5 рабочих дня, с даты получения оплаты / или подписания спецификации', title: '2–5 рабочих дня', accessor: 'shippingTime'},
    {id: 2, value: 'до 5 рабочих дня, с даты получения оплаты / или подписания спецификации', title: 'до 5 рабочих дня', accessor: 'shippingTime'},
]
const supplyWarehouse = [
    {id: 1, value: 'г. Астрахань, ул. Ст. Кутумная, 28А. Режим работы: 9:00ч до 18:00ч.', title: 'Астрахань', accessor: 'supplyWarehouse'},
    {id: 2, value: 'г. Ярославль, 2-ой Промышленный проезд, 11. Режим работы: 8:30ч до 17:00 ', title: 'Ярославль', accessor: 'supplyWarehouse'},
    {id: 3, value: 'г. Ростов-на-Дону, ул.Орская, 31. Режим работы: 9:00ч до 18:00ч.', title: 'Ростов-на-Дону', accessor: 'supplyWarehouse'},
    {id: 4, value: 'г. Новороссийск, Линейный проезд, 11. Режим работы: 9:00ч до 18:00ч.', title: 'Новороссийск', accessor: 'supplyWarehouse'},
]

export {paymentTerms, deliveryMethod, shippingTime, supplyWarehouse};