import './BaseLayout.css'
import {Outlet} from "react-router";
import DefaultSidebar from "../../components/sidebar/DefaultSidebar";
import {useState} from "react";
const BaseLayout = () => {
    const [isOpenMenu, setIsOpenMenu] = useState(true)
    return (
        <div className="base__wrapper">
            <DefaultSidebar setIsOpenMenu={setIsOpenMenu}/>
            <main className={`main__content ${isOpenMenu ? '' : 'main__content_full'}`}>
                <Outlet/>
            </main>
        </div>
    );
};

export default BaseLayout;