import './TkpClientSystemV2.css'
import TkpLabel from "../../../tkplabel/TkpLabel";

const TkpClientSystemV2 = ({children, system, index, ...props}) => {


    return (
        <div className={'system-tkp__container'} {...props}>
            <div className={'system-tkp__header'}>
                <div className={'system-tkp__container_info'}>
                    <div className={'system-tkp__header_square'}><TkpLabel text={system.square + ' м²'}/></div>
                    <div className={'system-tkp__header_preview'}>
                        <div className={'system-tkp__header_title'}><span>Система окраски №{index + 1} -</span></div>
                        <TkpLabel text={system.title ? system.title : ''}/>
                    </div>
                </div>
                <div className={'system-tkp__container_selects'}>

                </div>
            </div>
            <div className={'slices-tkp__wrapper'}>
                {children}
            </div>
            <div className={'system-client-tkp__footer'}>
                <div className={'system-tkp__footer_item'}>
                    <span className={'system-tkp__footer_text'}>Подложка: {system?.background?.value || 'Не заполнено'}</span>
                </div>
                <div className={'system-tkp__footer_item'} style={{flex: 1.5}}>
                    <span className={'system-tkp__footer_text'}>Шероховатость: {system?.roughness?.value || 'Не заполнено'}</span>

                </div>
                <div className={'system-tkp__footer_item'} style={{justifyContent: 'flex-start', flex: 4}}>
                    <span className={'system-tkp__footer_text'}>Подготовка поверхности:</span>
                    {system.preparation.map((item, idx) => (
                        <span key={idx} className={'system-tkp__footer_text'}> {` / ${item?.value?.value || 'Не заполнено'} - ${item?.percent || 0}%`} </span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TkpClientSystemV2;