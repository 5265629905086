import './BillingListTS.css'

const BillingListTs = () => {
    return (
        <div>

        </div>
    );
};

export default BillingListTs;