import {Form} from "react-bootstrap";
import './RemainsCheckbox.css';
import {useRef} from "react";


const RemainsCheckbox = ({checked, children, onClick, disabled = false}) => {
    const refCheckbox = useRef(null);
    return (
        <div
            ref={refCheckbox}
            onClick={(e) => {
                e.target = refCheckbox.current;
                onClick(e);
            }}
            id={'remains-checkbox'}
        >
            <Form.Check.Input
                disabled={disabled}
                id={'remains-checkbox__input'}
                type='checkbox'
                checked={checked}
                readOnly={true}
            />
            <Form.Check.Label
                id={'remains-checkbox__label'}
            >
                {children}
            </Form.Check.Label>
        </div>
    );
};

export default RemainsCheckbox;