import {useState} from 'react';
import {useNavigate, useParams} from "react-router";
import useCheckConnectionToHub from "../../../../../hooks/useCheckConnectionToHub";
import useSignalRFunk from "../../../../../hooks/useSignalRFunk";
import useChangeItemSignalR from "../../../../../hooks/useChangeItemSignalR";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import DetailWrapper from "../../../../../components/detailcard/wrapper/DetailWrapper";
import TabsBlock from "../../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../../components/UI/buttons/buttontab/ButtonTab";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import DetailBody from "../../../../../components/detailcard/body/DetailBody";
import FieldsBlockRenderer from "../../../../../modules/fieldblockrenderer/FieldsBlockRenderer";
import {createSignalRContext} from "react-signalr/signalr";
import {useAuth} from "../../../../../features/authprovider/AuthProvider";
const SignalRContext = createSignalRContext()

const GuidsWarehousesDetail = () => {
    const [currentWarehouse, setCurrentWarehouse] = useState(null)
    const [schema, setSchema] = useState([])
    const params = useParams()
    const navigate = useNavigate()
    const {userInfo} = useAuth()
    const {
        checkConnection,
        onReconnect,
        onConnect,
        onError
    } = useCheckConnectionToHub({
        SignalRContext: SignalRContext,
        initialFunk: () => {
            updateSchemaDetailCard()
            updateCurrentWarehouse()
        },
        onReconnectCallback: () => {
            updateSchemaDetailCard()
            updateCurrentWarehouse()
        }
    })
    const {signalRFunk} = useSignalRFunk({
        SignalRContext: SignalRContext,
        checkConnection,
        argsExternal: []
    })

    const updateSchemaDetailCard = () => signalRFunk('GetSchemaForRender', ['warehouse'], res => setSchema(res))
    const updateCurrentWarehouse = () => signalRFunk('GetWarehouseForId', [Number(params['id'] || 0)], res => setCurrentWarehouse(res))

    const {changeItem} = useChangeItemSignalR({
        SignalRContext,
        checkConnection,
        delay: 1000,
        method: 'ChangeWarehouse',
        callbackChangeItemFunk: newItem => setCurrentWarehouse(newItem)
    })

    return (
        <SignalRContext.Provider
            url={`${BaseUrl}/ancorr/api/warehousesdetailhub/?token=${userInfo?.accessToken || ''}`}
            accessTokenFactory={() => userInfo?.accessToken || ''}
            connectEnabled={true}
            withCredentials={false}
            onReconnect={onReconnect}
            onOpen={onConnect}
            onError={onError}
        >
            <DetailWrapper>
                <TabsBlock>
                    <ButtonTab
                        icon={<BackCrossIcon classNames={'icon-extension'}/>}
                        title={'Назад к списку'}
                        onClick={() => navigate(-1)}
                    />
                </TabsBlock>
                <DetailBody>
                    <FieldsBlockRenderer
                        schema={schema}
                        model={currentWarehouse}
                        onSave={newItem => changeItem(newItem, false, [], true)}
                    />
                </DetailBody>
            </DetailWrapper>
        </SignalRContext.Provider>
    );
};

export default GuidsWarehousesDetail;