import {useMemo} from 'react';
import {Document, Font, Image, Page, Text, View} from "@react-pdf/renderer";
import {BaseUrl} from "../../../../features/databaseinfo/BaseUrl";
import styles from "./constants";
import KpPdfHeader from "./components/KpPdfHeader";
import KpPdfIntroInfo from "./components/KpPdfIntroInfo";
import table from "./components/table";
import KpPdfConditions from "./components/KpPdfConditions";
import KpPdfFooter from "./components/KpPdfFooter";

const KpPdf = ({kp}) => {
    const date = new Date();
    date.setDate(date.getDate() + 3);
    Font.register({
        family: 'Montserrat-Bold',
        src:
            `${BaseUrl}/photo/img/Montserrat/Montserrat-Bold.ttf`,
        fontWeight: "bold"
    });
    Font.register({
        family: "Montserrat-Black",
        src:
            `${BaseUrl}/photo/img/Montserrat/Montserrat-Black.ttf`,
        fontWeight: "Bold"
    });
    Font.register({
        family: "Montserrat-Regular",
        src:
            `${BaseUrl}/photo/img/Montserrat/Montserrat-Regular.ttf`,
        fontWeight: "normal"
    });
    Font.register({
        family: "Montserrat-Italic",
        src:
            `${BaseUrl}/photo/img/Montserrat/Montserrat-Italic.ttf`,
        fontWeight: "normal"
    });

    const materials = useMemo(() => {
        return kp.materials || []
    }, [kp])

    return (
        <Document style={styles.document}>
            <Page
                size={'A4'}
                orientation={'vertical'}
                style={styles.page}
            >
                <View style={[styles.wrapper, {gap: 10}]}>
                    <Image
                        src={
                            `${BaseUrl}/photo/img/BackgroundAncorr.png`
                        }
                        style={styles.backgroundImage}
                        fixed
                    />
                    <KpPdfHeader kp={kp}/>
                    <Text style={styles.text}>Коммерческое предложение</Text>
                    <KpPdfIntroInfo kp={kp}/>
                    <View style={styles.container}>
                        <table.Header/>
                        <table.Body materials={materials} kp={kp}/>
                        <table.Footer kp={kp} materials={materials}/>
                        <View style={styles.datePrice}>
                            <View break>
                                <Text>Указанные цены действуют до {date.toLocaleDateString()}</Text>
                                <Text>Цены действительны только при покупке всех товаров предложения.</Text>
                            </View>
                        </View>
                        <View break>
                            <KpPdfConditions kp={kp}/>
                            <KpPdfFooter kp={kp}/>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default KpPdf;