import './MenuItemList.css'
import {useEffect, useState} from "react";
import useLoader from "../../../../hooks/useLoader";
import useAxiosHelper from "../../../../hooks/useAxiosHelper";
import {usePaginationController} from "../../../../components/pagination/components";
import EditIcon from "../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../components/UI/icons/BackCrossIcon";
import useCheckAccess from "../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../components/access/AccessDenied";
import GuidsWrapper from "../../../../components/guids/wrapper/GuidsWrapper";
import GuidsHeader from "../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../components/guids/search/GuidsSearchInput";
import GuidsBody from "../../../../components/guids/body/GuidsBody";
import TableBootstrap from "../../../../components/tableb/TableBootstrap";
import GuidsPagination from "../../../../components/guids/pagination/GuidsPagination";
import ListIcon from "../../../../components/UI/icons/ListIcon";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import EditElementForm from "./components/EditElementForm";
import useModals from "../../../../hooks/usemodal/useModals";
import useCaching from "../../../../store/useCaching";
import PartnersList from "./components/PartnersList";
import SubmenuPartnersList from "./components/SubmenuPartnersList";
import {Button, Container} from "react-bootstrap";

const MenuItemList = () => {
    const [searchValue, setSearchValue] = useState([])
    const [items, setItems] = useState({})
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const {hideAll} = useLoader()
    const {postMethod, deleteMethod, getMethod} = useAxiosHelper({})
    const setPayload = useCaching.getState().setPayload;
    const removePayload = useCaching.getState().removePayload;
    const getPayload = useCaching.getState().getPayload;

    useDocumentTitle({title: 'Элементы меню'});

    const [add, modals] = useModals();

    useEffect(() => {
        if (items?.currentPage > items?.pages)
            setPage(items?.pages)
    }, [items]);

    useEffect(() => {
        if (page === 1) changePage(page);

        updateItems()
    }, [page]);

    const updateItems = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/menu/list',
        queryParams: {
            offset: 10,
            page: page
        },
        data: filters,
        successCallback: ({data}) => setItems(data)

    })

    const addItem = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/menu/add',
        successCallback: ({data}) => updateItems()
    })
    const removeItem = (guid) => deleteMethod({
        urlWithoutBaseUrl: 'ancorr/api/menu/delete',
        queryParams: {
            guid: guid
        },
        successCallback: () => updateItems()
    })

    const {changePage, paginationModule} = usePaginationController({
        pagesCount: items?.pages || 1,
        changePageCallback: (data) => setPage(data)
    })

    useEffect(() => {
        if (page === 1) changePage(page);
    }, [page]);

    const popoverActions = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item => {
                const KEY_CACHING = 'itemToEdit'
                setPayload(KEY_CACHING, {...item});
                add({
                    children: <EditElementForm KEY_CACHING={KEY_CACHING}/>,
                    isNeedSuccess: true,
                    onCancel: () => removePayload(KEY_CACHING),
                    onSuccess: () => {
                        postMethod({
                            urlWithoutBaseUrl: 'ancorr/api/menu/update',
                            data: getPayload(KEY_CACHING),
                            successCallback: () => {
                                updateItems();
                                removePayload(KEY_CACHING);
                            }
                        })
                    },
                    caption: 'Редактирование элемента меню'
                })
            }
        },
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Партнеры',
            onClick: item => {
                const KEY_CACHING = 'partners'
                setPayload(KEY_CACHING, {...item});
                add({
                    children: <PartnersList
                        KEY_CACHING={KEY_CACHING}
                        updateItems={updateItems}
                    />,
                    onCancel: () => removePayload(KEY_CACHING),
                    caption: 'Редактирование списка партнеров'
                })
            }
        },
        {
            icon: <ListIcon classNames={'cl-light-purple'}/>,
            title: 'Подменю',
            onClick: item => {
                const KEY_CACHING = 'Submenu list'
               setPayload(KEY_CACHING, {...item});
                add({
                    caption: 'Редактирование списка партнеров',
                    children: <SubmenuPartnersList
                        KEY_CACHING={KEY_CACHING}
                        updateItems={updateItems}
                        add={add}
                    />,
                    onCancel: () => removePayload(KEY_CACHING),
                })
            }
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => removeItem(item?.guid)
        },
    ]

    const access = useCheckAccess("guids/menu")

    if (!access) {
        hideAll()
        return <AccessDenied/>
    }

    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'СПИСОК ЭЛЕМЕНТОВ МЕНЮ'}/>
                <Container fluid className={'d-flex justify-content-end gap-2'}>
                    <GuidsSearchInput onChange={newValue => setSearchValue(newValue)}/>
                    <Button onClick={() => addItem()}>Добавить</Button>
                </Container>
            </GuidsHeader>
            <GuidsBody>
                <TableBootstrap
                    schema={schema}
                    items={items?.data || []}
                    popoverActions={popoverActions}
                    popoverCaption={"Действия"}
                />
            </GuidsBody>
            <GuidsPagination>
                {
                    paginationModule
                }
            </GuidsPagination>
            {modals}
        </GuidsWrapper>
    );
};

const schema = [
    {
        title: 'ID',
        callbackItem: item => item?.id,
    },
    {
        title: 'Name',
        callbackItem: item => item?.name,
    },
    {
        title: 'Title',
        callbackItem: item => item?.title,
    },
    {
        title: 'Color',
        callbackItem: item => item?.color,
    },
    {
        title: 'Path',
        callbackItem: item => item?.path,
    },
    {
        title: 'IsAdmin',
        callbackItem: item => item?.isAdmin ? 'Да' : 'Нет',
    },
]


export default MenuItemList;