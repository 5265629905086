import TkpSummaryMaterial from "../../../tkpsummarymaterial/TkpSummaryMaterial";
import TkpLabel from "../../../tkplabel/TkpLabel";
import {getRubString} from "../../../../../../features/formatters/forrmaterrub";

const ComponentClientEquipmentV2 = (visiblePrice) => {
    const classColumn = 'tkp-client-table__column';
    if (visiblePrice){
        return [
            {
                Key: 1,
                TitleColumn: 'Материал',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpSummaryMaterial materialSlice={item} sliceNumber={item.slice} classSummaryMaterial={classColumn}/>
                    )
                }
            },
            {
                Key: 2,
                TitleColumn: 'Кол-во',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.quantityIncludingPackaging?.toFixed(1) + ' л'} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 3,
                TitleColumn: 'Цена за литр',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={getRubString(item?.priceForLiter)} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 4,
                TitleColumn: 'Цена за м²',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={getRubString(item?.priceForMeterSquare)} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 5,
                TitleColumn: 'Цена за кг',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={getRubString(item?.priceForKg)} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 6,
                TitleColumn: 'Вес кг',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.totalWeight?.toFixed(0) + ' кг'} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 7,
                TitleColumn: 'Тара',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item.equipment?.equipmentInformation?.packageSize} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 8,
                TitleColumn: 'Стоимость кр. таре',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={getRubString(item?.totalPriceIncludingPackaging)} classLabel={classColumn}/>
                    )
                }
            },
        ]
    }
    else{
        return [
            {
                Key: 1,
                TitleColumn: 'Материал',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpSummaryMaterial materialSlice={item} sliceNumber={item.slice} classSummaryMaterial={classColumn}/>
                    )
                }
            },
            {
                Key: 2,
                TitleColumn: 'Кол-во',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.quantityIncludingPackaging?.toFixed(1) + ' л'} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 3,
                TitleColumn: 'Вес кг',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.totalWeight?.toFixed(0) + ' кг'} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 4,
                TitleColumn: 'Тара',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.equipment?.equipmentInformation?.packageSize} classLabel={classColumn}/>
                    )
                }
            },
        ]
    }
};

export default ComponentClientEquipmentV2;