import './AppealsList.css'
import {useMultiStepComponent} from "../../../../hooks/usemultistepcomponent/UseMultiStepComponents";
import DetailWrapper from "../../../../components/detailcard/wrapper/DetailWrapper";
import TabsBlock from "../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../components/UI/buttons/buttontab/ButtonTab";
import ListIcon from "../../../../components/UI/icons/ListIcon";
import DetailBody from "../../../../components/detailcard/body/DetailBody";
import RentAppealList from "./rentappeallist/RentAppealList";
import BuyAppealList from "./buyappeallist/BuyAppealList";

const AppealsList = ({assignedId, clientId, projectSellVariationId, projectRentVariationId}) => {

    const {
        step,
        titleStep,
        goTO
    } = useMultiStepComponent([
        {
            titleStep: 'Обращения аренды',
            component: <RentAppealList assignedId={assignedId} clientId={clientId} projectRentVariationId={projectRentVariationId}/>
        },
        {
            titleStep: 'Обращения покупки',
            component: <BuyAppealList assignedId={assignedId} clientId={clientId} projectSellVariationId={projectSellVariationId}/>
        },
    ])

    return (
        <DetailWrapper>
            <TabsBlock>
                <ButtonTab
                    icon={<ListIcon />}
                    title={'Обращения аренды'}
                    onClick={() => goTO(0)}
                    isActive={titleStep === "Обращения аренды"}
                />
                <ButtonTab
                    icon={<ListIcon />}
                    title={'Обращения покупки'}
                    onClick={() => goTO(1)}
                    isActive={titleStep === "Обращения покупки"}
                />
            </TabsBlock>
            <DetailBody>
                {step}
            </DetailBody>
        </DetailWrapper>
    );
};

export default AppealsList;