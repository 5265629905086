import React, {useMemo} from 'react';
import './DefaultTable.css'
import useCheckedArrays from "../../hooks/useCheckedArrays";
import {config, useTransition} from "@react-spring/web";
import TableWrapper from "../table/tablewrapper/TableWrapper";
import TableHead from "../table/head/TableHead";
import TableRow from "../table/row/TableRow";
import TableColumn from "../table/column/TableColumn";
import TableBody from "../table/body/TableBody";
const DefaultTable = ({
                          items,
                          components,
                          callbackActionItems,
                          VALUE_SIZES,
                          selectKeys,
                          tableColumnClassNames,
                          tableBodyClassNames,
                          tableHeadClassNames,
                          tableWrapperClassNames,
                          tableRowClassNames,
                          hideHeaderTable  = false
                      }) => {

    const refMap = useMemo(() => { return new WeakMap()}, [])
    const { checkChangePositionInArrayAndUpdate, isNewItem } = useCheckedArrays(items);
    const transition = useTransition(items, {
        keys: item => selectKeys(item),
        from: {
            opacity: 0,
            y: "0",
            x: "-50%",
        },
        enter: {
            opacity: 1,
            y: "0",
            x: "0",
        },
        update: (item, index) => async (next, cancel) => {
            if(isNewItem(item)){
                await next({
                    from: {
                        y: '0'
                    }
                })
            }
            else if(checkChangePositionInArrayAndUpdate(item)){
                await next({
                    y: `${-(refMap.get(item).offsetHeight + 10)}px`
                })
            }
            else{
                await next({
                    from: {
                        y: '0'
                    }
                })
            }
        },
        leave: (item, index) => async (next, cancel) => {
            await next({
                opacity: 0,
                y: "0",
                x: "50%",
            })
        },
        config: config.gentle
    })

    return (
        <TableWrapper classNames={tableWrapperClassNames} style={hideHeaderTable ? {border: 'none'} : null}>
            {!hideHeaderTable &&
                <TableHead classNames={tableHeadClassNames}>
                    <TableRow sizesColumns={VALUE_SIZES} style={{background: 'transparent', boxShadow: 'none'}} classNames={tableRowClassNames}>
                        {components.map(component => (
                            <TableColumn key={component.Key} style={{overflowY: 'hidden'}} classNames={tableColumnClassNames}>
                                {component.TitleColumn}
                            </TableColumn>
                        ))}
                    </TableRow>
                </TableHead>
            }
            <TableBody classNames={tableBodyClassNames} style={hideHeaderTable ? {height: '100%'} : null}>
                {transition((style, item) => (
                    <TableRow sizesColumns={VALUE_SIZES} style={hideHeaderTable ? {...style, borderBottom: 'none'} : style }
                              refMapSetter={ref => ref && refMap.set(item, ref)} classNames={tableRowClassNames}>
                        {components.map(component => (
                            <TableColumn key={component.Key} classNames={tableColumnClassNames}>
                                {component.invokeComponent(item, callbackActionItems)}
                            </TableColumn>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </TableWrapper>
    );
};

export default DefaultTable;