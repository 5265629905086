import './FilterInput.css'
import {useEffect, useState} from "react";
import {ReactComponent as CloseIcon} from "../../../../components/UI/icons/x.svg";

const FilterInput = ({value, setValue, placeholder = 'ввод'}) => {
    const [inputValue, setInputValue] = useState('');
    const [isModded,setIsModded] = useState(false);

    useEffect(() => {
        if (value === 0) setInputValue('');
        else setInputValue(value);
    }, [value]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        if (newValue === '') {
            setInputValue('');
            setValue(0);
            setIsModded(false);
            return;
        }
        if (newValue === '0') {
            setValue(0);
            setInputValue('0');
            setIsModded(true);
            return;
        }
        setInputValue(Number(newValue).toString());
        setValue(Number(newValue));
        setIsModded(true);
    }
    const clearInput = () => {
        setInputValue('');
        setValue(0);
        setIsModded(false);
    }


    return (
        <div className="filter-input">
            <input
                className={'filter-input__input'}
                type="number"
                value={inputValue}
                onChange={handleChange}
                placeholder={placeholder}
                onFocus={() => {setInputValue(value)}}
            />
            {isModded &&
                <button
                    className={'filter-input__close-button'}
                    onClick={clearInput}
                >
                    <CloseIcon className="filter-input__close-icon" />
                </button>
            }
        </div>
    );
};

export default FilterInput;