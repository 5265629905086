import {useMemo, useState} from 'react';
import './UserList.css'
import useLoader from "../../../../../hooks/useLoader";
import {useNavigate} from "react-router";
import useCheckConnectionToHub from "../../../../../hooks/useCheckConnectionToHub";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import {createSignalRContext} from "react-signalr/signalr";
import useCheckAccess from "../../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../../components/access/AccessDenied";
import useSignalRFunk from "../../../../../hooks/useSignalRFunk";
import GuidsWrapper from "../../../../../components/guids/wrapper/GuidsWrapper";
import GuidsHeader from "../../../../../components/guids/header/GuidsHeader";
import GuidsBody from "../../../../../components/guids/body/GuidsBody";
import TitleDetailCard from "../../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../../components/guids/search/GuidsSearchInput";
import TableBootstrap from "../../../../../components/tableb/TableBootstrap";
import PreviewIcon from "../../../../../components/UI/icons/PreviewIcon";
import CrossIcon from "../../../../../components/UI/icons/CrossIcon";
import {Button, Container, Form, InputGroup, Modal} from "react-bootstrap";
import EmailIcon from "../../../../../components/UI/icons/EmailIcon";
import ListIcon from "../../../../../components/UI/icons/ListIcon";

const SignalRContext = createSignalRContext()
const UserList = () => {

    const [registerRequest, setRegisterRequest] = useState({
        userName: '',
        password: '',
        email: ''
    })
    const [showModalRegisterForm, setShowModalRegisterForm] = useState(false)

    const [searchValue, setSearchValue] = useState('')
    const [items, setItems] = useState([])
    const {hideAll} = useLoader()
    const navigate = useNavigate()

    const {
        checkConnection,
        onReconnect,
        onConnect,
        onError
    } = useCheckConnectionToHub({
        SignalRContext: SignalRContext,
        initialFunk: () => {
            updateAllUsers()
        },
        onReconnectCallback: () => {
            updateAllUsers()
        }
    })
    const {signalRFunk} = useSignalRFunk({
        SignalRContext,
        checkConnection,
    })
    const updateAllUsers = () => signalRFunk('GetAllUsers', [], res => {
        if(res && Array.isArray(res))
            setItems(res)
    })
    const addNewUser = () => signalRFunk('CreateNewUser', [registerRequest], res => navigate(`detail/${res?.id}`))
    const removeUser = (guid) => signalRFunk('DeleteUser', [guid], () => setItems(prev => prev.filter(x => x?.guid !== guid)))

    const memoItems = useMemo(() => {
        return items?.filter(item => item?.fio?.toLowerCase().includes(searchValue?.toLowerCase()) || !item?.fio)
    }, [items, searchValue])

    const schema = [
        {
            title: 'Username',
            callbackItem: item => item?.userName,
        },
        {
            title: 'Email',
            callbackItem: item => item?.email,
        },
        {
            title: 'Фамилия',
            callbackItem: item => item?.lastName,
        },
        {
            title: 'Имя',
            callbackItem: item => item?.firstName,
        },
        {
            title: 'Отчество',
            callbackItem: item => item?.secondName,
        },
    ]
    const popoverActions = [
        {
            icon: <PreviewIcon classNames={'cl-light-green'}/>,
            title: 'Открыть',
            onClick: item => navigate(`detail/${item?.id}`)
        },
        {
            icon: <CrossIcon classNames={'cl-yellow-muted'}/>,
            title: 'Удалить',
            onClick: item => removeUser(item?.guid)
        },
    ]
    const clearRegisterForm = () => setRegisterRequest({
        userName: '',
        email: '',
        password: '',
    })

    const access = useCheckAccess('users/list')
    if(!access){
        hideAll()
        return <AccessDenied/>
    }

    return (
        <SignalRContext.Provider
            url={`${BaseUrl}/ancorr/api/userhublist/`}
            connectEnabled={true}
            withCredentials={false}
            onReconnect={onReconnect}
            onOpen={onConnect}
            onError={onError}
        >
            <GuidsWrapper>
                <GuidsHeader>
                    <TitleDetailCard text={'СПИСОК ПОЛЬЗОВАТЕЛЕЙ'}/>
                    <Container fluid className={'d-flex justify-content-end gap-2'}>
                        <GuidsSearchInput onChange={newValue => setSearchValue(newValue)}/>
                        <Button onClick={() => setShowModalRegisterForm(true)}>Добавить пользователя</Button>
                    </Container>
                </GuidsHeader>
                <GuidsBody>
                    <TableBootstrap
                        items={memoItems}
                        schema={schema}
                        popoverCaption={'Действия'}
                        popoverActions={popoverActions}
                    />
                </GuidsBody>
            </GuidsWrapper>
            <Modal show={showModalRegisterForm} onHide={() => clearRegisterForm()}>
                <Form onSubmit={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    addNewUser()
                    clearRegisterForm();
                    setShowModalRegisterForm(false)
                }}>
                    <Modal.Header>
                        <Modal.Title>Форма создания пользователя</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup className={'w-100 mb-3'} hasValidation>
                            <InputGroup.Text><EmailIcon/></InputGroup.Text>
                            <Form.Control
                                type={"email"}
                                value={registerRequest.email}
                                placeholder={"Email"}
                                onChange={e => setRegisterRequest({...registerRequest, email: e.target.value})}
                                required
                            />
                        </InputGroup>
                        <InputGroup className={'mb-3'} hasValidation>
                            <InputGroup.Text><ListIcon/></InputGroup.Text>
                            <Form.Control
                                type={"text"}
                                value={registerRequest.userName}
                                placeholder={"Username"}
                                onChange={e => setRegisterRequest({...registerRequest, userName: e.target.value})}
                                required
                            />
                        </InputGroup>
                        <InputGroup className={'mb-3'} hasValidation>
                            <InputGroup.Text><ListIcon/></InputGroup.Text>
                            <Form.Control
                                type={"text"}
                                value={registerRequest.password}
                                placeholder={"Password"}
                                onChange={e => setRegisterRequest({...registerRequest, password: e.target.value})}
                                required
                            />
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'danger'} onClick={() => {
                            clearRegisterForm();
                            setShowModalRegisterForm(false)
                        }}>Отменить</Button>
                        <Button variant={"primary"} type={'submit'}>Создать</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </SignalRContext.Provider>
    );
};

export default UserList;