import {useEffect, useState} from "react";
import {useAuth} from "../features/authprovider/AuthProvider";


const UseCheckAccess = (currentComponentName) => {
    const [access, setAccess] = useState(true)
    const {userInfo} = useAuth()
    useEffect(() => {
        checkAccess()
    }, [])
    const checkAccess = () => {
        let arrayAllowsInformationsForUser = allowsInformation.filter(x => userInfo?.roles?.some(ui => ui?.name === x?.role))
        if(arrayAllowsInformationsForUser?.some(x => x?.accessComponentsName?.some(w => w === currentComponentName)))
            setAccess(true)
        else
            setAccess(false)
    }

    return access;
};

export default UseCheckAccess;
const allowsInformation = [
    {
        role: 'Member',
        accessComponentsName: [
            'clients',
            'client/detail',
            'tkps',
            'tkp/detail',
            'users',
            'user/detail',
            'reporttkp',
            'logistics',
            'fields',
            'statuses',
            'guids',
            'logistics/reporttkp',
            'remains',
            'logist/logistics',
            'sales/tkp',
            'sales/specification',
            'sales/deal',
            'sales/billing',
            'sales/contract',
            'sales/kp',
        ]
    },
    {
        role: 'Admin',
        accessComponentsName: [
            'clients',
            'guids',
            'guids/components',
            'guids/brands',
            'guids/currencies',
            'guids/suppliers',
            'guids/packaging',
            'guids/nomenclatures',
            'guids/recipes',
            'guids/warehouses',
            'guids/tkpTemplates',
            'fields/list',
            'fields/detail',
            'users/list',
            'component/reserved',
            'component/supply',
            'component/shipment',
            'component/remains',
            'guids/menu',
            'remains',
            'admin/status',
        ]
    },
    {
        role: 'Guest',
        accessComponentsName: [
            'clients'
        ]
    }
]
