import './ContractDetailTS.css'

const ContractDetailTs = () => {
    return (
        <div>

        </div>
    );
};

export default ContractDetailTs;