import './EntitiesSelector.css'
import {useMemo, useState} from "react";
import GuidsSearchInput from "../../components/guids/search/GuidsSearchInput";
import GuidsWrapper from "../../components/guids/wrapper/GuidsWrapper";
import GuidsHeader from "../../components/guids/header/GuidsHeader";
import GuidsBody from "../../components/guids/body/GuidsBody";

const EntitiesSelector = ({entities, nameSelector, keySelector, onClickItem, selectedEntities = []}) => {
    const [searchText, setSearchText] = useState('')
    const memoItems = useMemo(() => {
        return entities.filter(x => nameSelector?.(x)?.toLowerCase()?.includes(searchText?.toLowerCase())) || entities
    }, [entities, searchText])
    return (
        <GuidsWrapper>
            <GuidsHeader>
                <div className={'flex center gap w-100'}>
                    <GuidsSearchInput searchValue={searchText} setSearchValue={setSearchText}/>
                </div>
            </GuidsHeader>
            <GuidsBody>
                <div className={'entity-selector__items'}>
                    {memoItems?.map(item => (
                        <div key={keySelector?.(item)} className={`entity-selector__item ${selectedEntities?.some(x => keySelector?.(item) === x) ? 'entity-selector__item_select' : ''}`} onClick={() => {
                            if(selectedEntities?.some(x => keySelector?.(item) === x))
                                onClickItem?.({item: item, isSelect: true})
                            else
                                onClickItem?.({item: item, isSelect: false})
                        }}>
                            <span className={'entity-selector__name'}>{nameSelector?.(item)}</span>
                        </div>
                    ))}
                </div>
            </GuidsBody>
        </GuidsWrapper>
    );
};

export default EntitiesSelector;