import {forwardRef, useImperativeHandle, useState} from "react";
import useLoader from "../../../../../hooks/useLoader";
import {useMutation} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../../hooks/useAxiosHelper";
import {Button, Form, Modal, Stack} from "react-bootstrap";
import TextInput from "../../../../../components/UI/inputs/text/TextInput";
import ContactEditInputModule from "../../ContactEditInputModule";
import CompanyEditInputModule from "../../CompanyEditInputModule";

const CreatingClientModule = forwardRef(({onChange}, ref) => {

    const [visibleModal, setVisibleModal] = useState(false)
    const {show, hide} = useLoader()
    const [value, setValue] = useState(null)

    const {mutate: addClient} = useMutation({
        mutationKey: ['client', 'modal', 'add'],
        mutationFn: (data) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: `ancorr/api/client/add/client`,
            data: data,
        }),
        onMutate: () => show(),
        onSettled: () => hide(),
        onSuccess: (data) => {
            setValue(data)
            onChange?.(data)
        }
    })

    useImperativeHandle(ref, () => ({
        open: () => setVisibleModal(true),
        close: () => setVisibleModal(false),
    }), [])
    return (
        <Modal onHide={() => setVisibleModal(false)} show={visibleModal} size={"lg"}>
            <Modal.Header><Modal.Title>Создание клиента</Modal.Title></Modal.Header>
            <Modal.Body>
                <Form>
                    <Stack className={'mb-3'}>
                        <TextInput
                            sx={{width: 400}}
                            label={'Наименование'}
                            controlled
                            defaultValue={value?.generalName}
                            onChange={newValue => setValue({...value, generalName: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <ContactEditInputModule
                            controlled
                            defaultValue={value?.mainContactInfo}
                            label={'Основной контакт'}
                            onChange={newValue => setValue({...value, mainContactInfo: newValue, mainContactInfoId: newValue?.id})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <CompanyEditInputModule
                            controlled
                            defaultValue={value?.mainCompany}
                            label={'Основная компания'}
                            onChange={newValue => setValue({...value, mainCompany: newValue, mainCompanyId: newValue?.id})}
                        />
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"danger"} onClick={() => setVisibleModal(false)}>Закрыть</Button>,
                <Button
                    onClick={() => {
                        addClient?.({clientDTO: value})
                    }}
                >
                    Привязать
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default CreatingClientModule;