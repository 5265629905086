export default [
    {
        title: 'Название',
        callbackItem: item => item?.warehouse?.fullName || 'Не известно',
    },
    {
        title: 'Доступно',
        callbackItem: item => item?.available || '0',
    },
    {
        title: 'Доступно с поставкой',
        callbackItem: item => item?.availableWithSupply || '0',
    },
    {
        title: 'Можно произвести',
        callbackItem: item => item?.canProduce || '0',
    },
    {
        title: 'Можно произвести с поставкой',
        callbackItem: item => item?.canProduceWithSupply || '0',
    }
]