import {Autocomplete, TextField} from "@mui/material";
import useCompaniesSearcher from "../../../hooks/clients/useCompaniesSearcher";
import {useRef} from "react";
import CreatingCompanyModule from "./modals/add/CreatingCompanyModule";
import EditCompanyModule from "./modals/edit/EditCompanyModule";
import EditIcon from "../../../components/UI/icons/EditIcon";
import {Stack} from "react-bootstrap";
import {useMutation} from "@tanstack/react-query";
import {AxiosHelper} from "../../../hooks/useAxiosHelper";
import useControlledValue from "../../../hooks/controlled/useControlledValue";

const CompanyEditInputModule = ({defaultValue = null, onChange, label= '', sx, controlled = false}) => {

    const createModuleControlsRef = useRef(null)
    const editModuleControlsRef = useRef(null)
    const {optionsCompanies, inputCompanies, updateInputCompanies, onBlur: onBlurCompanies} = useCompaniesSearcher()
    const [value, setValue] = useControlledValue({defaultValue, controlled})

    const {mutate: updateCompany} = useMutation({
        mutationKey: ['company', 'input', 'update'],
        mutationFn: (id) => AxiosHelper.getMethod({
            urlWithoutBaseUrl: `ancorr/api/company/get/${id}`
        }),
        onSuccess: (data) => {
            setValue(data)
            onChange?.(data)
        }
    })

    return (
       <Stack direction={"horizontal"} className={'gap-2'}>
           <Autocomplete
               value={value}
               onChange={(event, newValue) => {
                   if(newValue?.shortCompanyName === 'Добавить новую компанию')
                       createModuleControlsRef?.current?.open()
                   else{
                       setValue(newValue)
                       onChange?.(newValue)
                   }
               }}
               onInputChange={(event, newInputValue) => {
                   if(newInputValue !== 'Добавить новую компанию')
                       updateInputCompanies(newInputValue)
               }}
               onBlur={() => onBlurCompanies()}
               isOptionEqualToValue={(option, value) => option?.id === value?.id}
               inputValue={inputCompanies}
               options={optionsCompanies}
               suppressContentEditableWarning={true}
               suppressHydrationWarning={true}
               getOptionLabel={option => `${option?.shortCompanyName || 'Не заполнено'} ${option?.inn || ''}`}
               getOptionKey={option => option?.id}
               sx={sx ? sx : { width: 400 }}
               size="small"
               filterOptions={(options, params) => {

                   return [...options, {shortCompanyName: 'Добавить новую компанию', id: 999999}]
               }}
               renderInput={(params) => <TextField {...params} variant={'standard'} label={label} placeholder={'Наименование, инн'} />}
           />
           {value &&
               <Stack onClick={() => editModuleControlsRef.current?.open()} className={'justify-content-center align-content-center pointer'}>
                   <EditIcon/>
               </Stack>
           }
           <CreatingCompanyModule
               ref={createModuleControlsRef}
               onChange={newValue => {
                   onChange?.(newValue)
                   setValue(newValue)
                   createModuleControlsRef?.current?.close()
               }}
           />
           <EditCompanyModule
               ref={editModuleControlsRef}
               controlled
               defaultValue={value}
               onChange={newValue => {
                   setValue(newValue)
                   if(newValue?.id)
                       updateCompany(newValue?.id)
                   else
                       onChange?.(newValue)
                   editModuleControlsRef?.current?.close()
               }}
           />
       </Stack>
    );
};

export default CompanyEditInputModule;