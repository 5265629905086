import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";

const SortableWrapper = ({localItems = [], setLocalItems, keySelector, positionSelector, onChangePosition, children}) => {

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    )

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={event => {
                const {active, over} = event;
                if (active.id !== over.id) {

                    const oldIndex = localItems.findIndex(x => keySelector?.(x) === keySelector?.(active));
                    const newIndex = localItems.findIndex(x => keySelector?.(x) === keySelector?.(over));

                    let newArray =  arrayMove(localItems, oldIndex, newIndex);

                    onChangePosition?.({
                        oldItemKey: keySelector?.(localItems[oldIndex]),
                        newItemKey: keySelector?.(localItems[newIndex]),
                        oldPosition: positionSelector?.(localItems[oldIndex]),
                        newPosition: positionSelector?.(localItems[newIndex])
                    })
                    setLocalItems(newArray)
                }
            }}
        >
            <SortableContext
                items={localItems}
                strategy={verticalListSortingStrategy}
            >
                {children}
            </SortableContext>
        </DndContext>
    );
};

export default SortableWrapper;