import {Text, View} from "@react-pdf/renderer";
import {getCurrencyString} from "../../../../../../features/formatters/forrmaterrub";

const TkpSummaryTableEquipmentPdfV2 = ({equipment, defaultStyles, visiblePrice, tkp}) => {
    return (
        <View key={equipment?.guid} style={[defaultStyles.item, defaultStyles.equipment]}>
            <View style={[defaultStyles.column, {flex: 2, paddingLeft: 10, alignItems: "flex-start"}]}>
                <Text style={defaultStyles.names}>{equipment?.equipment?.printName}</Text>
            </View>
            <View style={[defaultStyles.column, {flex: 0.5}]}>
                <Text style={defaultStyles.tableHeaderText}>{equipment?.quantityIncludingPackaging?.toFixed(1)}</Text>
            </View>
            {visiblePrice && <View style={[defaultStyles.column, {flex: 0.5}]}>
                <Text style={defaultStyles.tableHeaderText}>{getCurrencyString(equipment?.priceForLiter, tkp?.currencyForHandle?.currencyCode)}</Text>
            </View>}
            {visiblePrice && <View style={[defaultStyles.column, {flex: 0.5}]}>
                <Text style={defaultStyles.tableHeaderText}>{getCurrencyString(equipment?.priceForMeterSquare, tkp?.currencyForHandle?.currencyCode)}</Text>
            </View>}
            {visiblePrice && <View style={[defaultStyles.column, {flex: 0.5}]}>
                <Text style={defaultStyles.tableHeaderText}>{getCurrencyString(equipment?.priceForKg, tkp?.currencyForHandle?.currencyCode)}</Text>
            </View>}
            <View style={[defaultStyles.column, {flex: 0.3}]}>
                <Text style={defaultStyles.tableHeaderText}>{equipment?.totalWeight?.toFixed(0)}</Text>
            </View>
            <View style={[defaultStyles.column, {flex: 0.3}]}>
                <Text style={defaultStyles.tableHeaderText}>{equipment?.equipment?.packageSize}</Text>
            </View>
            {visiblePrice && <View style={[defaultStyles.column, {flex: 1}]}>
                <Text style={defaultStyles.tableHeaderText}>{getCurrencyString(equipment?.totalPriceIncludingPackaging, tkp?.currencyForHandle?.currencyCode)}</Text>
            </View>}
        </View>
    );
};

export default TkpSummaryTableEquipmentPdfV2;