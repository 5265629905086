export const formatter = new Intl.NumberFormat('ru-RU' ,{
    style: 'currency',
    currency: 'RUB'
})
const formatterUSD = new Intl.NumberFormat('en-US' ,{
    style: 'currency',
    currency: 'USD'
})
const formatterEUR = new Intl.NumberFormat('de-DE' ,{
    style: 'currency',
    currency: 'EUR'
})
export const getRubString = (text) => {
    return formatter.format(text)
}
export const getCurrencyString = (text, code) => {
    if(code === 'руб.')
        return formatter.format(text)
    else if(code === 'USD')
        return formatterUSD.format(text)
    else if(code === 'EUR')
        return formatterEUR.format(text)
    else
        return formatter.format(text)
}
const warehouses = [
    { short: 'ярославл', full: 'Ярославль'},
    { short: 'ростов', full: 'Ростов-На-Дону'},
    { short: 'астрахан', full: 'Астрахань'}
]
export const getFullOutputWarehouseFromShortString = (shortString) => {
    let warehouse = warehouses.find(x => x.short.toLowerCase().includes(shortString?.toLowerCase()))
    return warehouse?.full || 'Нет данных'
}