import {useEffect, useMemo, useState} from 'react';
import './GuidsSupplierList.css'
import useLoader from "../../../../../hooks/useLoader";
import {useNavigate} from "react-router";
import EditIcon from "../../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import useCheckAccess from "../../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../../components/access/AccessDenied";
import GuidsHeader from "../../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../../components/guids/search/GuidsSearchInput";
import GuidsBody from "../../../../../components/guids/body/GuidsBody";
import TableBootstrap from "../../../../../components/tableb/TableBootstrap";
import GuidsWrapper from "../../../../../components/guids/wrapper/GuidsWrapper";
import {AxiosHelper} from "../../../../../hooks/useAxiosHelper";
import {usePaginationController} from "../../../../../components/pagination/components";
import GuidsPagination from "../../../../../components/guids/pagination/GuidsPagination";
import useDocumentTitle from "../../../../../hooks/useDocumentTitle";
import schema from "./schema";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Button, Container} from "react-bootstrap";

const GuidsSupplierList = () => {

    const [searchValue, setSearchValue] = useState('')
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const {hideAll} = useLoader()
    const navigate = useNavigate()
    const QUERY_KEY_LIST = 'suppliers-list';

    useDocumentTitle({title: 'Поставщики'})

    const client = useQueryClient();
    const {data: items} = useQuery({
        queryKey: [QUERY_KEY_LIST, page, filters],
        queryFn: ({queryKey: [key, page, filters]}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/supplier/list',
            queryParams: {
                offset: 10,
                page
            },
            data: filters
        })
    })
    const {mutate: addItem} = useMutation({
        mutationFn: () => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/supplier/add',
        }),
        onSuccess: () => client.invalidateQueries({queryKey: [QUERY_KEY_LIST]})
    })
    const {mutate: removeItem} = useMutation({
        mutationFn: (guid) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/supplier/delete',
            queryParams: {
                guid
            }
        }),
        onSuccess: () => client.invalidateQueries({queryKey: [QUERY_KEY_LIST]})
    })

    const {changePage, paginationModule} = usePaginationController({
        pagesCount: items?.pages || 1,
        changePageCallback: (data) => setPage(data)
    })

    useEffect(() => {
        if(items?.currentPage > items?.pages) {
            if (items?.pages === 0) setPage(1)
            else setPage(items?.pages)
        }
    }, [items]);

    const memoData = useMemo(
        () => items?.data
            ?.filter(supplier => supplier?.fullName.toLowerCase()
                ?.includes(searchValue?.toLowerCase())
            ),
        [items, searchValue]
    );

    const popoverActions = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item => navigate(`suppliers/detail/${item?.id}`)
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => removeItem(item?.guid)
        },
    ]

    const access = useCheckAccess("guids/suppliers")

    if(!access){
        hideAll()
        return <AccessDenied />
    }

    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'СПИСОК ПОСТАВЩИКИ'}/>
                <Container fluid className={'d-flex justify-content-end gap-2'}>
                    <GuidsSearchInput onChange={newValue => setSearchValue(newValue)}/>
                    <Button onClick={() => addItem()}>Добавить</Button>
                </Container>
            </GuidsHeader>
            <GuidsBody>
                <TableBootstrap
                    schema={schema}
                    items={memoData || []}
                    popoverActions={popoverActions}
                    popoverCaption={"Действия"}
                />
            </GuidsBody>
            <GuidsPagination>
                {
                    paginationModule
                }
            </GuidsPagination>
        </GuidsWrapper>
    );
};

export default GuidsSupplierList;