import './RemainsList.css'
import TableBootstrap from "../../../../../../components/tableb/TableBootstrap";
import EditIcon from "../../../../../../components/UI/icons/EditIcon";
const RemainsList = ({remains, unitMeasurement}) => {

    const popoverActions = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Тест',
            onClick: item => {}
        },
    ]

    return (
        <TableBootstrap
            items={remains || []}
            popoverActions={popoverActions}
            schema={tableSchemaRemains}
            popoverCaption={'Действия'}
        />
    );
};

const tableSchemaRemains = [
    {
        title: 'ID',
        callbackItem: item => item?.id,
    },
    {
        title: 'Склад',
        callbackItem: item => item?.warehouse?.fullName,
    },
    {
        title: 'Доступно',
        callbackItem: item => `${item?.available || 0}`,
    },
    {
        title: 'В наличии с поставками',
        callbackItem: item => `${item?.availableWithSupply || 0}`,
    },
    {
        title: 'В наличии',
        callbackItem: item => `${item?.inStock || 0}`,
    },
    {
        title: 'В резерве',
        callbackItem: item => `${item?.reserved || 0}`,
    },
    {
        title: 'Поступит',
        callbackItem: item => `${item?.supply || 0}`,
    },
    {
        title: 'Ближайшая дата поставки',
        callbackItem: item => item?.nearestSupplyDate,
    },
]

export default RemainsList;