import {Button} from "react-bootstrap";

const UpdateInformationBx24Kp = ({newBxInfo, currentKp, updateInfo}) => {
    return (
        <div className={'modal-update-bxinfo__wrapper'}>
            <div className={'modal-update-bxinfo__container'}>
                <div className={'modal-update-bxinfo__block'}>
                    <h3 className={'modal-update-bxinfo__title'}>Текущая информация Б24</h3>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Сделка:</label>
                        <span className={'modal-update-bxinfo__text'}>{currentKp?.dealNameB24 || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Компания:</label>
                        <span className={'modal-update-bxinfo__text'}>{currentKp?.companyNameB24 || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Судно:</label>
                        <span className={'modal-update-bxinfo__text'}>{currentKp?.shipB24 || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>ID сделки:</label>
                        <span className={'modal-update-bxinfo__text'}>{currentKp?.dealIdB24 || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>ID пользователя:</label>
                        <span className={'modal-update-bxinfo__text'}>{currentKp?.userIdB24 || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Ответственный</label>
                        <span className={'modal-update-bxinfo__text'}>{currentKp?.assignedB24 || ''}</span>
                    </div>
                </div>
                <div className={'modal-update-bxinfo__block'}>
                    <h3 className={'modal-update-bxinfo__title'}>Новая информация Б24</h3>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Сделка:</label>
                        <span className={'modal-update-bxinfo__text'}>{newBxInfo?.dealNameB24 || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Компания:</label>
                        <span className={'modal-update-bxinfo__text'}>{newBxInfo?.companyNameB24 || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Судно:</label>
                        <span className={'modal-update-bxinfo__text'}>{newBxInfo?.shipB24 || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>ID сделки:</label>
                        <span className={'modal-update-bxinfo__text'}>{newBxInfo?.dealIdB24 || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>ID пользователя:</label>
                        <span className={'modal-update-bxinfo__text'}>{newBxInfo?.userIdB24 || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Ответственный</label>
                        <span className={'modal-update-bxinfo__text'}>{newBxInfo?.assignedB24 || ''}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateInformationBx24Kp;