import {useEffect, useRef, useState} from "react";
import {Form} from "react-bootstrap";
import FilterInput from "../../../UI/filterinput/FilterInput";


const SpecificWeightInputs = ({filters, setFilters, updatePosition}) => {
    const refInputs = useRef(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        if (isFirstLoad) setIsFirstLoad(false);
        else updatePosition({target: refInputs.current});
    }, [filters.specificWeightMax, filters.specificWeightMin])

    return (
        <div className={'remains-filters__none-accordion'}>
            <Form.Label className={'filter-inputs__label'}>Удельный вес</Form.Label>
            <div
                className="filters-inputs__inputs"
                ref={refInputs}
            >
                <FilterInput
                    value={filters.specificWeightMin}
                    setValue={value => setFilters({...filters, specificWeightMin: value})}
                    placeholder={"От"}
                />
                <FilterInput
                    value={filters.specificWeightMax}
                    setValue={value => setFilters({...filters, specificWeightMax: value})}
                    placeholder={"До"}
                />
            </div>
        </div>
    );
};

export default SpecificWeightInputs;