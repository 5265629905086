import React, {useEffect, useMemo, useState} from 'react';

const UseCheckedArrays = (items = []) => {

    const [isFirstRendering, setIsFirstRendering] = useState(true)
    const [prevArray, setPrevArray] = useState([])
    const [currentArray, setCurrentArray] = useState([])
    const prevArrayMap = useMemo(() => {
        let map = new WeakMap();
        prevArray.forEach((item, index) => {
            map.set(item, index)
        })
        return map;
    }, [prevArray])
    const currArrayMap = useMemo(() => {
        let map = new WeakMap();
        currentArray.forEach((item, index) => {
            map.set(item, index)
        })
        return map;
    }, [currentArray])

    useEffect(() => {
        setPrevArray(items)
        setCurrentArray(items)
        setIsFirstRendering(false)
    }, []);
    useEffect(() => {
        if(!isFirstRendering){
            setCurrentArray( state => {
                setPrevArray(state)
                return items;
            })
        }
    }, [items]);

    const checkChangePositionInArrayAndUpdate = (item) => {
        let prevItem = prevArrayMap.get(item)
        let currentItem = currArrayMap.get(item)

        if(prevItem !== currentItem) {
            prevArrayMap.set(item, currentItem)
            return true;
        }
        else return false;
    }
    const isNewItem = (item) => {
        let prevItem = prevArrayMap.get(item)

        if(!prevItem) {
            return true;
        }
        else return false;
    }
    return {
        currArrayMap,
        currentArray,
        prevArrayMap,
        prevArray,
        checkChangePositionInArrayAndUpdate,
        isNewItem
    };
};

export default UseCheckedArrays;