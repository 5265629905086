import './TableBootstrapTow.css'
import ListIcon from "../../UI/icons/ListIcon";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';
import {Menu, MenuItem} from "@mui/material";
import {generateGuid} from "../../../features/guidgenerator/GuidGenerator";
import {useState} from "react";

const TableBootstrapRow = ({rowHeight, draggable, keySelector, childrenItem, schema, popoverActions, popoverCaption, item}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (callback) => {
        setAnchorEl(null);
        callback?.()
    };

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: keySelector?.(childrenItem)})

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    return (
        <tr className={'table-b__body_row'} ref={setNodeRef} style={rowHeight ? {height: rowHeight, ...style} : style} >
            {draggable &&
                <td {...attributes} {...listeners} className={'table-b__body_col'}>
                    <div className={'table-b__body_col flex center'}>
                        <ListIcon />
                    </div>
                </td>
            }
            {schema?.map((itemSchema, idxSchema) => (
                <td key={idxSchema} className={'table-b__body_col'}>
                    <div className={'table-b__body_col flex center'}>
                        {itemSchema?.callbackItem(item)}
                    </div>
                </td>
            ))}
            {popoverActions &&
                <td className={'table-b__body_col'}>
                    <div className={'flex center text-center'}>
                        <i onClick={handleClick} className="fa-solid fa-ellipsis-vertical icon icon-action"></i>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => handleClose()}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            {popoverActions?.map((popover) => {
                                if (!popover?.condition) {
                                    return (
                                        <MenuItem
                                            key={generateGuid()}
                                            onClick={() => handleClose(() => popover?.onClick(item))}
                                        >
                                            {popover?.title}
                                        </MenuItem>
                                    )
                                } else if (popover?.condition?.(item)) {
                                    return (
                                        <MenuItem
                                            key={generateGuid()}
                                            onClick={() => handleClose(() => popover?.onClick(item))}
                                        >
                                            {popover?.title}
                                        </MenuItem>
                                    )
                                } else return null;
                            })}
                        </Menu>
                        {/*<PopoverAction caption={popoverCaption}>
                                {popoverActions?.map((popover, idxPopover) => {


                                    if (!popover?.condition) {
                                        return (
                                            <PopoverBtn
                                                icon={popover?.icon}
                                                title={popover?.title}
                                                key={idxPopover}
                                                classNamesText={popover?.classNamesText}
                                                onClick={() => popover?.onClick(item)}
                                            />
                                        )
                                    } else if (popover?.condition?.(item)) {
                                        return (
                                            <PopoverBtn
                                                icon={popover?.icon}
                                                title={popover?.title}
                                                key={idxPopover}
                                                classNamesText={popover?.classNamesText}
                                                onClick={() => popover?.onClick(item)}
                                            />
                                        )
                                    } else return null;
                                })}
                            </PopoverAction>*/}
                    </div>
                </td>}
        </tr>
    );
};

export default TableBootstrapRow;