import './TkpClientPreviewV2.css'
import {useParams} from "react-router";
import {useEffect, useMemo, useRef, useState} from "react";
import axios from "axios";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import {PDFDownloadLink} from "@react-pdf/renderer";
import DefaultTable from "../../../../../components/defaulttable/DefaultTable";
import {getCurrencyString} from "../../../../../features/formatters/forrmaterrub";
import ComponentClientSliceV2 from "./features/ComponentClientSliceV2";
import ComponentClientDiluentV2 from "./features/ComponentClientDiluentV2";
import ComponentClientEquipmentV2 from "./features/ComponentClientEquipmentV2";
import TkpClientSystemV2 from "./components/TkpClientSystemV2";

const TkpClientPreviewV2 = () => {
    const params = useParams()
    const [tkp, setTkp] = useState({})
    const [summaryTableEquipments, setSummaryTableEquipments] = useState([])
    const [summaryTableDiluents, setSummaryTableDiluents] = useState([])
    const [systems, setSystems] = useState([])


    const VALUE_SIZES_EQUIPMENT = () => {
        if (tkp?.settings?.visiblePrice) return '6fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr'
        else return '6fr 1fr 1fr 1fr'
    }
    const VALUE_SIZES_DILUENT = () => {
        if (tkp?.settings?.visiblePrice) return '6fr 1fr 1fr 1fr 1fr 1fr 1fr'
        else return '6fr 1fr 1fr 1fr'
    }
    const VALUE_SIZES_SLICE = () => {
        if (tkp?.settings?.visiblePrice) return '2fr 0.6fr 1fr 0.6fr 0.7fr 0.7fr 0.5fr 0.5fr 0.7fr 0.7fr 0.7fr 0.6fr 2fr 1fr'
        else return '2fr 0.6fr 1fr 0.6fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.6fr 2fr 1fr'
    }

    useEffect(() => {
        axios.get(`${BaseUrl}/ancorr/api/tkp/v2/preview/get/${params.guid}`).then(({data}) => {
            setTkp(state => data)
        }).finally(() => {

        })
    }, [])

    useEffect(() => {
        if (tkp.summaryEquipments)
            setSummaryTableEquipments(tkp.summaryEquipments)
        if (tkp.summaryDiluents)
            setSummaryTableDiluents(tkp.summaryDiluents)
        if (tkp.systems)
            setSystems(tkp.systems)
        if (tkp?.settings?.visiblePrice) {

        }

    }, [tkp])
    const memoSummaryTableEquipments = useMemo(() => {
        return summaryTableEquipments
    }, [summaryTableEquipments])
    const memoSummaryTableDiluents = useMemo(() => {
        return summaryTableDiluents
    }, [summaryTableDiluents])
    const memoSystems = useMemo(() => {
        return systems
    }, [systems])


    const parentRef = useRef(null)

    return (
        <>
            <div className="tkp-client">
                <img
                    src={
                        `${BaseUrl}/photo/img/Logo.png`
                    }
                    alt={"Logo.png"}
                    className='tkp-client__logo'
                />
            </div>
            <div ref={parentRef} className={'content tkp-round-border'}>
                <div className="content__title title-content">
                    <div className="title-content__header">
                        ТЕХНИКО-КОММЕРЧЕСКОЕ ПРЕДЛОЖЕНИЕ
                    </div>
                    <div className="title-content__wrapper">
                        <div className="title-content__name">
                            {tkp?.companyName?.toUpperCase() || "Не заполнено"?.toUpperCase()}
                        </div>
                        <div className="title-content__name-ship">
                            СУДНО: {tkp?.ship?.toUpperCase() || 'Не заполнено'?.toUpperCase()}
                        </div>
                        <div className="title-content__info info-title-content">
                            <div className="info-title-content__creator">
                                ПОДГОТОВИЛ: {tkp?.assigned?.toUpperCase() || 'Не заполнено'.toUpperCase()}
                            </div>
                            <div className="info-title-content__date">
                                ДАТА
                                СОЗДАНИЯ: {new Date(tkp?.createdTimeDb).toLocaleDateString().toUpperCase() || 'Не заполнено'.toUpperCase()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'content-child'}>
                    <h2>Сводная таблица</h2>
                    <div className={'content__header'}>
                        <div className={'text__header'}>
                            <h3 className={'title_text'}>Технико-коммерческое предложение для {tkp.companyName}</h3>
                            <h3 className={'date_text'}>Дата
                                создания: {new Date(tkp.createdTimeDb).toLocaleDateString()}</h3>
                        </div>
                        <div className={'btns__header avoid'}>
                            {/*<button className={'avoid btn-download'} onClick={() => window.print()}>Печать</button>
                            {tkp.Guid && <PDFDownloadLink document={<TkpClientPdf tkp={tkp}/>} fileName="TKP.pdf">
                                {({blob, url, loading, error}) =>
                                    loading ? 'Loading document...' :
                                        <button className={'avoid btn-download'}>Скачать PDF</button>
                                }
                            </PDFDownloadLink>}*/}
                            {/*<button className={'avoid btn'} onClick={() => savePdf()}>Скачать PDF</button>*/}
                        </div>
                    </div>
                    <div className={'content__body'}>
                        <div className={'table__content materials'}>
                            <DefaultTable
                                items={memoSummaryTableEquipments || []}
                                components={ComponentClientEquipmentV2(tkp?.settings?.visiblePrice)}
                                VALUE_SIZES={VALUE_SIZES_EQUIPMENT()}
                                selectKeys={item => item.guid}
                                tableHeadClassNames={'tkp-client-table__head'}
                            />
                        </div>
                        <div className={'blue-stripe'}>
                            Итого за материал: {getCurrencyString(tkp.priceForMaterialIncludingPackaging, tkp?.currencyForHandle?.currencyCode)}
                        </div>
                        <div className={'table__content diluents'}>
                            <DefaultTable
                                items={memoSummaryTableDiluents || []}
                                components={ComponentClientDiluentV2(tkp?.settings?.visiblePrice)}
                                VALUE_SIZES={VALUE_SIZES_DILUENT()}
                                selectKeys={item => item.guid}
                                tableHeadClassNames={'tkp-client-table__head'}
                            />
                        </div>
                        <div className={'blue-stripe'}>
                            <span>Итого за разбавитель: {getCurrencyString(tkp.priceForDiluentIncludingPackaging, tkp?.currencyForHandle?.currencyCode)}</span>
                        </div>
                        <div className={'blue-stripe'}>
                            <span>Общее: {getCurrencyString(tkp.totalPriceIncludingPackaging, tkp?.currencyForHandle?.currencyCode)}</span>
                            <span>Вес: {tkp?.totalWeight?.toFixed(0) || 0} кг</span>
                        </div>
                    </div>
                    <div className={'content__footer'}>

                    </div>
                </div>
            </div>
            <div className={'systems__wrapper'}>
                <h2>Системы окраски</h2>
                <div className={'content-child_systems tkp-round-border'}>
                    {memoSystems?.map((system, indexSystem) => (
                        <TkpClientSystemV2 key={indexSystem} index={indexSystem} system={system}>
                            <DefaultTable
                                selectKeys={item => item.guid}
                                VALUE_SIZES={VALUE_SIZES_SLICE()}
                                items={system.slices || []}
                                components={ComponentClientSliceV2(tkp?.settings?.visiblePrice, tkp)}
                                tableHeadClassNames={'tkp-client-table__head'}
                            />
                        </TkpClientSystemV2>
                    ))}
                </div>
            </div>
        </>
    );
};

export default TkpClientPreviewV2;