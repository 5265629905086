import React from 'react';

const EmailIcon = ({classNames}) => {
    return (
        <svg className={classNames} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.77793 9.31786C3.52799 9.13933 3.40302 9.05007 3.29407 9.03848C3.13113 9.02114 2.96783 9.10518 2.88723 9.24784C2.83333 9.34324 2.83333 9.49513 2.83334 9.79891V15.2011C2.83333 15.8719 2.83332 16.4255 2.87016 16.8765C2.90844 17.3449 2.99057 17.7755 3.19665 18.18C3.51623 18.8072 4.02616 19.3171 4.65337 19.6367C5.05781 19.8428 5.4884 19.9249 5.95682 19.9632C6.40781 20 6.96143 20 7.63223 20H16.3678C17.0386 20 17.5922 20 18.0432 19.9632C18.5116 19.9249 18.9422 19.8428 19.3466 19.6367C19.9738 19.3171 20.4838 18.8072 20.8034 18.18C21.0094 17.7755 21.0916 17.3449 21.1298 16.8765C21.1667 16.4255 21.1667 15.8719 21.1667 15.2011V9.7989C21.1667 9.65001 21.1667 9.57557 21.153 9.52358C21.0914 9.28856 20.8359 9.15468 20.6075 9.23776C20.557 9.25614 20.495 9.29905 20.3711 9.38487L13.9193 13.8516C13.4618 14.1693 13.0602 14.4482 12.6039 14.5588C12.2048 14.6556 11.788 14.6525 11.3904 14.5499C10.9358 14.4326 10.5383 14.1478 10.0855 13.8234L3.77793 9.31786Z" fill="#6C7275" fillOpacity="0.5"/>
            <path d="M20.444 7.30727C20.5978 7.20077 20.6748 7.14752 20.722 7.06187C20.7587 6.99536 20.7797 6.89003 20.7713 6.81454C20.7605 6.71733 20.7191 6.65314 20.6365 6.52478C20.32 6.03304 19.8644 5.62713 19.3466 5.36331C18.9422 5.15724 18.5116 5.0751 18.0432 5.03683C17.5922 4.99998 17.0386 4.99999 16.3678 5H7.63225C6.96145 4.99999 6.40781 4.99998 5.95682 5.03683C5.4884 5.0751 5.05781 5.15724 4.65337 5.36331C4.18751 5.60068 3.78484 5.94384 3.47864 6.36112C3.38231 6.49241 3.33414 6.55805 3.31827 6.65767C3.30586 6.73558 3.32319 6.84381 3.3593 6.91395C3.40548 7.00364 3.48558 7.06085 3.64577 7.17527L10.9584 12.3986C11.5644 12.8314 11.693 12.9067 11.8068 12.9361C11.9393 12.9703 12.0783 12.9713 12.2113 12.9391C12.3255 12.9114 12.4552 12.838 13.0675 12.4141L20.444 7.30727Z" fill="#6C7275" fillOpacity="0.5"/>
        </svg>

    );
};

export default EmailIcon;