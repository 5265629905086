import './GuidsSearchInput.css'
import {InputAdornment, TextField} from "@mui/material";
import {useState} from "react";
import SearchIcon from "../../UI/icons/SearchIcon";
const GuidsSearchInput = ({onChange}) => {

    const [searchValue, setSearchValue] = useState('')

    return (
        <TextField
            value={searchValue}
            sx={{width: 400}}
            onChange={e => {
                setSearchValue(e.target.value)
                onChange?.(e.target.value)
            }}
            placeholder={'Поиск'}
            size={"small"}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default GuidsSearchInput;