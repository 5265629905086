import {Accordion, AccordionDetails, Autocomplete, IconButton, TextField} from "@mui/material";
import {Delete, Add} from "@mui/icons-material";
import AccordionSummaryCourse from "./AccordionSummaryCourse";
import './CourseItem.css'
import {useQuery, useQueryClient, useMutation} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../../hooks/useAxiosHelper";
import {useState} from "react";
import InputCourse from "../inputCourse/InputCourse";
import TextInput from "../../../../../components/UI/inputs/text/TextInput";
import useLoader from "../../../../../hooks/useLoader";


const CourseItem = ({course, keyStateCoursesList}) => {
    const [isOpened, setIsOpened] = useState(false);
    const client = useQueryClient();
    const {show, hide} = useLoader()
    const STATE_COURSE_KEY = `course_${course?.id}`;

    const {data: currentCourse} = useQuery({
        queryKey: [STATE_COURSE_KEY, course.id],
        queryFn: ({queryKey: [name, id]}) => AxiosHelper.getMethod({
            urlWithoutBaseUrl: `ancorr/api/course/get/guid/${id}`,
        })
    })
    const {mutate: removeCourse} = useMutation({
        mutationKey: [keyStateCoursesList],
        mutationFn: (guid) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/course/delete/guid',
            queryParams: {
                guid
            }
        }),
        onMutate: () => show(),
        onSettled: () => hide(),
        onSuccess: () => client.invalidateQueries({queryKey: [keyStateCoursesList]})
    })
    const {mutate: updateCourse} = useMutation({
        mutationKey: [STATE_COURSE_KEY],
        mutationFn: (updatedCourse) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/course/update/guid',
            data: updatedCourse,
        }),
        onMutate: (updatedCourse) => client.setQueryData([STATE_COURSE_KEY], (oldValue) => updatedCourse),
        onSuccess: () => client.invalidateQueries({queryKey: [STATE_COURSE_KEY]})
    })
    const {mutate: addInfoCourse} = useMutation({
        mutationKey: [STATE_COURSE_KEY],
        mutationFn: (id) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: `ancorr/api/course/add/info`,
            queryParams: {idGuidCourse: id}
        }),
        onMutate: () => show(),
        onSettled: () => hide(),
        onSuccess: () => client.invalidateQueries({queryKey: [STATE_COURSE_KEY]})
    })

    return (
        <Accordion
            onChange={() => setIsOpened(!isOpened)}
        >
            <AccordionSummaryCourse
                id={course.guid + '-header'}
                aria-controls={course?.guid + '-content'}
            >
                <div className="curse-item__fields">
                    <div className="curse-item__fields-group">
                        {currentCourse &&
                            <TextInput

                                useDebounce
                                label={'Направление'}
                                defaultValue={currentCourse?.outputCourse}
                                onChange={newValue => {
                                    updateCourse({...currentCourse, outputCourse: newValue});
                                }}
                            />
                        }
                        {/*<TextField*/}
                        {/*    label={'Наименование'}*/}
                        {/*    onChange={(e) => {*/}
                        {/*    }}*/}
                        {/*    onClick={event => event.stopPropagation()}*/}
                        {/*    variant="standard"*/}
                        {/*    sx={{width: 300}}*/}
                        {/*/>*/}
                    </div>
                    {isOpened &&
                        <div className="curse-item__fields-group">
                            <IconButton
                                onClick={event => {
                                    event.stopPropagation();
                                    addInfoCourse(currentCourse?.id);
                                }}
                            >
                                <Add/>
                            </IconButton>
                            <IconButton
                                onClick={event => {
                                    event.stopPropagation();
                                    removeCourse(course?.guid);
                                }}
                            >
                                <Delete/>
                            </IconButton>
                        </div>
                    }
                </div>
            </AccordionSummaryCourse>
            <AccordionDetails>
                {currentCourse &&
                    currentCourse.inputCourse.map(inputCourse =>
                        <InputCourse
                            inputCourse={inputCourse}
                            stateCourseKey={STATE_COURSE_KEY}
                            key={inputCourse.guid}
                        />
                    )
                }
            </AccordionDetails>
        </Accordion>
    );
};

export default CourseItem;