import {useEffect, useMemo, useState} from 'react';
import './ContractsList.css'
import useLoader from "../../../../hooks/useLoader";
import {useNavigate} from "react-router";
import useAxiosHelper from "../../../../hooks/useAxiosHelper";
import EditIcon from "../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../components/UI/icons/BackCrossIcon";
import useCheckAccess from "../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../components/access/AccessDenied";
import GuidsWrapper from "../../../../components/guids/wrapper/GuidsWrapper";
import GuidsHeader from "../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../components/guids/search/GuidsSearchInput";
import GuidsBody from "../../../../components/guids/body/GuidsBody";
import TableBootstrap from "../../../../components/tableb/TableBootstrap";
import useMessageHub from "../../../../hooks/useMessageHub";
import BX24 from "bx24-api";
import {updateBxInfo} from "../../../newTkp/features/TkpHandlers";
import {usePaginationController} from "../../../../components/pagination/components";
import ListIcon from "../../../../components/UI/icons/ListIcon";
import humanizer from "humanize-duration";
import {Button, Form, Modal} from "react-bootstrap";
import KanbanRenderer from "../../../../modules/kanban/KanbanRenderer";
import ImageAvatar from "../../../../components/imageavatar/ImageAvatar";
import {getRubString} from "../../../../features/formatters/forrmaterrub";
import GuidsPagination from "../../../../components/guids/pagination/GuidsPagination";
import DropdownDefault from "../../../../components/UI/selects/dropdowndefault/DropdownDefault";
import {ReactComponent as RubleIcon} from "../../../../components/UI/icons/badge-russian-ruble.svg";
import {ReactComponent as AddIcon} from "../../../../components/UI/icons/add-icon.svg";
const ContractsList = ({tkpId, dealId, clientId, assignedId, kpId}) => {
    const [lanes, setLanes] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const navigate = useNavigate()
    const [bxInfo, setBxInfo] = useState(null)
    const [isKanban, setIsKanban] = useState(false)
    const [bxInfoIsUpdate, setBxInfoIsUpdate] = useState(false)
    const [changeStageModalVisibleItem, setChangeStageModalVisibleItem] = useState(null)
    const [changeStageModalVisible, setChangeStageModalVisible] = useState(false)
    const addMessage = useMessageHub()
    const {hideAll} = useLoader()



    const [items, setItems] = useState({})
    const [filters, setFilters] = useState({
        number: 0,
        assignedIds: assignedId ? [assignedId] : [],
        createdIds: [],
        modifyIds: [],
        clientIds: clientId ? [clientId] : [],
        stagesIds: [],
        dealIds: dealId ? [dealId] : [],
        tkpIds: tkpId ? [tkpId] : [],
        kpIds: kpId ? [kpId] : [],
        searchTitle: '',
        BXDealId: 0
    })
    const [page, setPage] = useState(1)
    const {getMethod, deleteMethod, postMethod} = useAxiosHelper({})


    useEffect(() => {
        if(window.name.toLowerCase().includes('bitrix')){
            BX24.resizeWindow(document.body.clientWidth, 1000).then(() => {

            })
            BX24.isAdmin()
                .then(isAdminRes => {
                    updateBxInfo()
                        .then(res => {
                            if(res){
                                setBxInfo(res)
                                setFilters({...filters, BXDealId: res?.dealId || 0, BXUserId: res?.userId || 0, BXIsAdmin:  isAdminRes})
                            }
                        })
                        .catch(res => {
                            addMessage(res.message || "Нет данных ошибки")
                        })
                        .finally(res => {
                            setBxInfoIsUpdate(true)
                        })
                })
        }
        else
            setBxInfoIsUpdate(true)
        getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/lanes',
            queryParams: {
                destinationEntity: 'contract'
            },
            successCallback: ({data}) => setLanes(data)
        })


    }, []);

    const memoData = useMemo(() => items?.data,[items, searchValue])

    const addItem = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/contract/add',
        data: {
            assignedId,
            tkpId,
            kpId,
            dealId,
            clientId
        },
        successCallback: ({data}) => updateItems()
    })

    const deleteItem = (guid) => deleteMethod({
        urlWithoutBaseUrl: 'ancorr/api/contract/delete',
        queryParams: {
            guid
        },
        successCallback: () => updateItems()
    })

    const changeStageItem = (newItem) => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/contract/edit/stage',
        data: newItem,
        successCallback: () => updateItems()
    })

    const updateItems = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/contract/list',
        queryParams: {
            offset: 15,
            page: page,
            isKanban: isKanban
        },
        data: filters,
        successCallback: ({data}) => setItems(data)

    })

    const {changePage, paginationModule} = usePaginationController({
        pagesCount: items?.pages || 1,
        changePageCallback: (data) => data === 0 ? setPage(1) : setPage(data)
    })

    useEffect(() => {
        if(items?.currentPage > items?.pages){
            if(items?.pages === 0)
                setPage(1)
            else
                setPage(items?.pages)

        }
    }, [items]);

    useEffect(() => {
        if (page === 1) changePage(page);
        if(bxInfoIsUpdate)
            updateItems()
    }, [page, bxInfoIsUpdate, isKanban]);

    const popoverActions = [
        {
            icon: <ListIcon classNames={'cl-light-purple'}/>,
            title: 'Смена стадии',
            onClick: item => {
                setChangeStageModalVisibleItem(item)
                setChangeStageModalVisible(true)
            }
        },
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item => navigate(`detail/${item?.id}`)
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => deleteItem(item?.guid)
        },
    ]

    const schema = [
        {
            title: 'Дата создания',
            callbackItem: item => new Date(item?.createdTimeDb)?.toLocaleDateString('ru-RU') || "Нет данных",
        },
        {
            title: 'Название',
            callbackItem: item => item?.name || "Не заполнено",
        },
        {
            title: 'Стадия',
            callbackItem: item => lanes?.find(x => x?.statusValue === item?.stage?.statusValue)?.statusName || "",
        },
    ]

    const convertFromStatusInfoToFieldItem = () => {
        let lane = lanes?.find(x => x?.statusValue === changeStageModalVisibleItem?.stage?.statusValue)
        return {
            id: lane?.id,
            title: lane?.statusName,
            accessor: lane?.statusValue,
            value: lane?.statusValue,
        }
    }

    const formatDate = (dateToFormat) => {
        const date = new Date(dateToFormat);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;
        return `${formattedDay}.${formattedMonth}.${year}`;
    }
    const oldDate = (date) => {
        const currentDate = new Date();
        return humanizer(currentDate - new Date(date), {
            language: ['ru'],
            largest: 1,
            maxDecimalPoints: 1
        }) + ' назад';
    }

    const access = useCheckAccess("sales/contract")

    if(!access){
        hideAll()
        return <AccessDenied />
    }

    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'СПИСОК ДОГОВОРОВ'}/>
                <div className={' flex center gap'}>
                    <GuidsSearchInput searchValue={searchValue} setSearchValue={setSearchValue}/>
                    <button
                        className={'kp__button'}

                        onClick={addItem}
                    >
                        <AddIcon
                            className={'kp__add-icon'}
                        />
                        Добавить договор
                    </button>
                </div>
            </GuidsHeader>
            <Form className={'w-100 flex left'}>
                <Form.Check // prettier-ignore
                    checked={isKanban}
                    type="switch"
                    label="Вид канбана"
                    onChange={e => setIsKanban(!isKanban)}
                />
            </Form>
            <GuidsBody>
                {isKanban
                    ?
                    <KanbanRenderer
                        lanes={lanes}
                        data={memoData}
                        stageKey={item => item?.stage?.statusValue}
                        sortableKey={item => new Date(item?.modifyTimeDb)}
                        addItem={addItem}
                        renderDataItemComponent={item => {
                            return (
                                <div className={'kp-card__wrapper'}>
                                    <div className="kp-card__container">
                                        <div className={'kp-card__created-time'}>
                                            {formatDate(item?.createdTimeDb)}
                                        </div>
                                    </div>
                                    <p className="m-0 p-0 kp-card__name">{item?.title}</p>
                                    <div className="m-0 p-0 text-muted kp-card__creds">
                                        <ImageAvatar urlPath={item?.createdBy?.avatar?.urlPath}/>
                                        {item?.createdBy?.lastName && item?.createdBy?.firstName ?
                                            `${item?.createdBy?.lastName} ${item?.createdBy?.firstName}`
                                            :
                                            `${item?.createdBy?.email}`
                                        }
                                    </div>
                                    <div className={'kp-card__footer'}>
                                        <div className={'kp-card__footer-price'}>
                                            <RubleIcon/>
                                            {getRubString(item?.totalPriceIncludingPackaging)}
                                        </div>
                                        <div className="kp-card__old-date">
                                            {oldDate(item?.modifyTimeDb)}
                                        </div>
                                    </div>
                                </div>
                            )
                        }}
                        changeLaneItem={e => changeStageItem({...e?.item, stage: e?.to})}
                        onClickItem={item => navigate(`detail/${item?.id}`)}
                    />
                    :
                    <TableBootstrap
                        popoverCaption={'Действия'}
                        items={memoData}
                        schema={schema}
                        popoverActions={popoverActions}
                    />
                }
            </GuidsBody>
            {!isKanban &&
                <GuidsPagination>
                    {
                        paginationModule
                    }
                </GuidsPagination>
            }
            <Modal
                show={changeStageModalVisible}
                onHide={() => {
                    setChangeStageModalVisibleItem(null)
                    setChangeStageModalVisible(false)
                }}
            >
                <Modal.Header><Modal.Title>Смена стадии</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Выберите стадию:</Form.Label>
                            <DropdownDefault
                                options={lanes?.map(x => {
                                    return {
                                        id: x?.id,
                                        title: x?.statusName,
                                        accessor: x?.statusValue,
                                        value: x?.statusValue,
                                    }
                                })}
                                currentValue={convertFromStatusInfoToFieldItem()}
                                placeholder={'Стадия'}
                                onChange={e => setChangeStageModalVisibleItem({
                                    ...changeStageModalVisibleItem,
                                    stage: lanes?.find(x => x?.statusValue === e?.value)
                                })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => {
                        setChangeStageModalVisibleItem(null)
                        setChangeStageModalVisible(false)
                    }}>Закрыть</Button>
                    <Button variant={"primary"} onClick={() => {
                        changeStageItem(changeStageModalVisibleItem)
                        setChangeStageModalVisibleItem(null)
                        setChangeStageModalVisible(false)
                    }}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
        </GuidsWrapper>
    );
};

export default ContractsList;