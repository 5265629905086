import React, {useEffect} from 'react';
import './Loader.css'
import {useSpring, animated} from "@react-spring/web";
const Loader = ({show, setVisiblePreloader}) => {
    const [styles, api] = useSpring(() => ({
        opacity: 1
    }))
    useEffect(() => {
        if(show){
            api.start(() => ({
                opacity: 1
            }))
        }
        return () => {
            setVisiblePreloader(false)
        }
    }, [])
    useEffect(() => {
        if(show){
            api.start(() => ({
                opacity: 1
            }))
        }
        else{
            api.start(() => ({
                opacity: 0,
                config: { duration: 100},
                onRest: () => {
                    setVisiblePreloader(false)
                },

            }))
        }
    }, [show])
    return (
        <animated.div style={styles} className={'fixed-loader-container'}>
            Загрузка
        </animated.div>
    );
};

export default Loader;