export default [
    {
        title: 'Название',
        callbackItem: item => item?.warehouse?.fullName,
    },
    {
        title: 'Доступно',
        callbackItem: item => item?.available,
    },
    {
        title: 'Доступно с поставкой',
        callbackItem: item => item?.availableWithSupply,
    },
    {
        title: 'Можно произвести',
        callbackItem: item => item?.canProduce,
    },
    {
        title: 'Можно произвести с поставкой',
        callbackItem: item => item?.canProduceWithSupply,
    }
]