import './Item.css'
import useClassNames from "../../../../hooks/useClassNames";

const Item = ({children, classes = []}) => {

    const itemClasses = useClassNames({
        cls: 'stretch-line__item',
        additional: classes,
    })

    return (
        <div className={itemClasses}>
            {children}
        </div>
    );
};

export default Item;