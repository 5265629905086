import './Component.css'
import TableBootstrap from "../../../../../components/tableb/TableBootstrap";
import ComponentColumn from "./components/ComponentColumn/ComponentColumn";
import schema from './constants/schema';
import {getFields} from "./helpers/getFields";

const Component = ({component}) => {

    const fields = getFields(component);

    return (
        <div>
            <div className="component__header">
                Информация о продукте
            </div>
            <div className="component__fields">
                {fields.map((fieldsColumn, index) => (
                    <ComponentColumn
                        key={index}
                        fields={fieldsColumn}
                    />
                ))}
            </div>
            <div className="component__header">
                Остатки по складам
            </div>
            <div>
                <TableBootstrap
                    schema={schema}
                    items={component?.remains}
                    popoverActions={false}
                    popoverCaption={"Действия"}
                />
            </div>
        </div>

    );
};

export default Component;