import axios from "axios";
import {BaseUrl} from "../../../../features/databaseinfo/BaseUrl";

export class MaterialsService {
    static getMaterials(requestFolderId = null, requestNomenclatureId = null) {
        return axios.post(
            `${BaseUrl}/ancorr/api/folder/list`,
            JSON.stringify({requestFolderId: requestFolderId, requestNomenclatureId: requestNomenclatureId}),
            {headers: {'Content-Type': 'application/json'}}
        );
    }

    static getComponent(id) {
        return axios.get(`${BaseUrl}/ancorr/api/component/get/component/${id}`);
    }

    static getEquipment(id) {
        return axios.get(`${BaseUrl}/ancorr/api/equipment/get/equipment/${id}`);
    }
}