import './UserDetailCard.css'
import {useNavigate, useParams} from "react-router";
import DetailWrapper from "../../../../../components/detailcard/wrapper/DetailWrapper";
import TabsBlock from "../../../../../components/tabsblock/TabsBlock";
import DetailBody from "../../../../../components/detailcard/body/DetailBody";
import ButtonTab from "../../../../../components/UI/buttons/buttontab/ButtonTab";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import FieldsBlockRenderer from "../../../../../modules/fieldblockrenderer/FieldsBlockRenderer";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../../hooks/useAxiosHelper";

const UserDetailCard = () => {
    const navigate = useNavigate()
    const params = useParams();
    const client = useQueryClient()
    const {data: schema} = useQuery({
        queryKey: ['schema', 'user'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/schema',
            queryParams: {
                destinationEntity: 'user'
            },
        }),
        refetchOnWindowFocus: false
    })
    const {data: currentEntity} = useQuery({
        queryKey: ['current-user'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: `ancorr/api/user/get/${Number(params['id'] || 0)}`,
        })
    })

    const {mutate: updateChangesEntity} = useMutation({
        mutationKey: ['current-user'],
        mutationFn: (newItem) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/user/update',
            data: newItem,
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-user']}),
        onMutate: (newItem) => client?.setQueryData(['current-user'], (oldItem) => newItem)
    })

    return (
        <DetailWrapper>
            <TabsBlock>
                <ButtonTab
                    icon={<BackCrossIcon classNames={'icon-extension'}/>}
                    title={'Назад к списку'}
                    onClick={() => navigate(-1)}
                />
            </TabsBlock>
            <DetailBody>
                <FieldsBlockRenderer
                    schema={schema}
                    model={currentEntity}
                    onSave={newItem => {
                        updateChangesEntity(newItem)
                    }}
                />
            </DetailBody>
        </DetailWrapper>
    );
};

export default UserDetailCard;