import './KpNav.css'
import KpButton from "../kpbutton/KpButton";
import {ReactComponent as CloseIcon} from "../../../../../../components/UI/icons/chevron-down.svg";
import {ReactComponent as TrashIcon} from "../../../../../../components/UI/icons/trash.svg";
import {ReactComponent as ListIcon} from "../../../../../../components/UI/icons/list-ul.svg";
import {ReactComponent as LinkIcon} from "../../../../../../components/UI/icons/link-45deg.svg";
import {ReactComponent as PDFIcon} from "../../../../../../components/UI/icons/filetype-pdf.svg";
import {useNavigate, useParams} from "react-router";
import {useInteractionBackend} from "../../../../../../hooks/useInteractionBackend";
import {KPService} from "../../API/KPService";

const KpNav = ({setVisiblePreviewPdf}) => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [removeKp] = useInteractionBackend({
        cb: KPService.deleteKP,
        thenCb: () => navigate('/sales/kp'),
        type: 'axios'
    })

    return (
        <div className="kp-nav kp-nav__group">
            <div className="kp-nav__group">
                <KpButton
                    className='kp-nav__btn kp-nav__button-close'
                    onClick={() => navigate(-1)}
                >
                    <CloseIcon className={'kp-nav__icon-close'}/>
                    Закрыть
                </KpButton>
                <h4 className='kp-nav__title'>КОММЕРЧЕСКОЕ ПРЕДЛОЖЕНИЕ</h4>
            </div>
            <div className="kp-nav__group">
                <KpButton
                    className='kp-nav__btn kp-nav__button-remove'
                    onClick={() => removeKp({id})}
                >
                    <TrashIcon className={'kp-nav__icon-remove'}/>
                    Удалить КП
                </KpButton>
                <KpButton
                    className='kp-nav__btn'
                    onClick={() => navigate(-1)}
                    disabled
                >
                    <ListIcon className={'kp-detail__btn-icon icon-reverse'}/>
                    Стадия
                </KpButton>
                <KpButton
                    className='kp-nav__btn'
                    onClick={() => navigator.clipboard.writeText(window.location.href)}
                >
                    <LinkIcon className={'kp-detail__btn-icon link-icon'}/>
                    Получить ссылку
                </KpButton>
                <KpButton
                    className='kp-nav__btn'
                    onClick={() => setVisiblePreviewPdf(true)}
                >
                    <PDFIcon className={'kp-nav__btn-icon pdf-icon'}/>
                    Просмотр PDF
                </KpButton>
            </div>
        </div>
    );
};

export default KpNav;