import {useEffect, useState} from "react";


const UseClassNames = ({cls, mods = {}, additional = []}) => {
    const [classNames, setClassNames] = useState('');

    useEffect(() => {
        setClassNames([
            cls,
            ...additional,
            ...Object.entries(mods)
                .filter(([cls, value]) => Boolean(value))
                .map(([cls, value]) => cls)
        ]
            .join(' '));
    }, [cls, mods, additional]);

    return classNames;
};

export default UseClassNames;