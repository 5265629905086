import useDebouncingFunction from "../debouncingfunction/useDebouncingFunction";
import {useRef} from "react";

const UseManyKeysDebouncing = (func, delay) => {

    const waiting = useRef([])
    const currentKeyRef = useRef(null)

    const next = (...argsFunc) => {

        func?.(...argsFunc)
        if(waiting.current.length === 0) {
            currentKeyRef.current = null
            return;
        }

        let newWaiting = [...waiting.current]

        let {key, args} = newWaiting?.shift()
        currentKeyRef.current = key
        debouncingFunc(...args)
        waiting.current = newWaiting
    }

    const debouncingFunc = useDebouncingFunction(next, delay)

    return (key, ...args) => {
        if(!currentKeyRef.current){
            debouncingFunc(...args)
            currentKeyRef.current = key
        }
        else if(currentKeyRef.current === key)
            debouncingFunc(...args)
        else if(waiting.current?.some(x => x?.key === key))
            waiting.current = waiting.current?.map(item => {
                if(item?.key === key) return {key, args: [...args]}
                else return item;
            })
        else
            waiting.current = [...waiting.current, {key, args: [...args]}]
    }
};

export default UseManyKeysDebouncing;