import './RentVariationDetail.css'
import {useNavigate, useParams} from "react-router";
import {AxiosHelper} from "../../../../hooks/useAxiosHelper";
import FieldsBlockRenderer from "../../../../modules/fieldblockrenderer/FieldsBlockRenderer";
import DetailWrapper from "../../../../components/detailcard/wrapper/DetailWrapper";
import TabsBlock from "../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../components/UI/buttons/buttontab/ButtonTab";
import DetailBody from "../../../../components/detailcard/body/DetailBody";
import BackCrossIcon from "../../../../components/UI/icons/BackCrossIcon";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import useAxiosErrorHandler from "../../../../hooks/axioserrorhandler/useAxiosErrorHandler";

const RentVariationDetail = () => {
    const navigate = useNavigate()
    const params = useParams();
    const id = Number(params['id'] || 0);
    const client = useQueryClient()
    const handleErrorResponse = useAxiosErrorHandler()
    const {data: schema} = useQuery({
        queryKey: ['schema', 'projectRent'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/schema',
            queryParams: {
                destinationEntity: 'projectRent'
            },
        }),
        refetchOnWindowFocus: false,
        onError: (error) => handleErrorResponse(error)
    })
    const {data: currentProject} = useQuery({
        queryKey: ['current-projectRent', id],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: `ancorr/api/project/rent/get/${id}`,
        }),
        onError: (error) => handleErrorResponse(error)
    })

    const {mutate: updateChangesProject} = useMutation({
        mutationKey: ['current-projectRent', id],
        mutationFn: ({newItem}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/project/rent/update',
            data: newItem,
        }),
        onSuccess: (data, {setterCallback}) => {
            setterCallback(true)
            client.invalidateQueries({queryKey: ['current-projectRent', id]})
        },
        onError: (error, {setterCallback}) => {
            setterCallback(false)
            handleErrorResponse(error)
        },
        onMutate: ({newItem}) => client?.setQueryData(['current-projectRent', id], (oldItem) => newItem)
    })

    return (
        <DetailWrapper>
            <TabsBlock>
                <ButtonTab
                    icon={<BackCrossIcon />}
                    title={'Назад'}
                    onClick={() => navigate(-1)}
                />
            </TabsBlock>
            <DetailBody>
                <FieldsBlockRenderer
                    schema={schema}
                    model={currentProject}
                    waitingSave={(newItem, setterCallback) => {
                        updateChangesProject?.({newItem, setterCallback})
                    }}
                    dependencyEntity={[
                        {
                            method: 'ancorr/api/helper/get/users',
                            keySelector: item => item?.id,
                            titleSelector: item => item ? `${item?.lastName || 'Без фамилии'} ${item?.firstName || 'Без имени'} ` : 'Не заполнен',
                            valueSelector: item => item?.id,
                        },
                    ]}
                    backendMode={'axios'}
                />
            </DetailBody>
        </DetailWrapper>
    );
};

export default RentVariationDetail;