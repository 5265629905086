import {formatter, getRubString, getCurrencyString} from "../formatters/forrmaterrub";
import React from "react";

const DELIVERY_LITER_PRICE = 15;
const COEF1 = 0.4;
const COEF2 = 0.28;
const COEF_PRICE_MORAVIA = 1.56;

export const getPriceProviderPerLiter = (equipment, isCamelCase = false) => {

    let packageSizeStr = isCamelCase ? 'packageSize' : 'PackageSize'

    let priceToReturn = 0
    let packageSize = 0
    if(equipment[packageSizeStr])
        packageSize = equipment[packageSizeStr]

    let listComponentsstr = isCamelCase ? 'listComponents' : 'ListComponents'
    let deliveryPricestr = isCamelCase ? 'deliveryPrice' : 'DeliveryPrice'
    let unitQuantityForEquipmentstr = isCamelCase ? 'unitQuantityForEquipment' : 'UnitQuantityForEquipment'
    let quantityForEquipmentstr = isCamelCase ? 'quantityForEquipment' : 'QuantityForEquipment'
    let unitVolumestr = isCamelCase ? 'unitVolume' : 'UnitVolume'
    let volumestr = isCamelCase ? 'volume' : 'Volume'
    let DeliveryPriceEquipmentstr = isCamelCase ? 'deliveryPriceEquipment' : 'DeliveryPriceEquipment'


    equipment[listComponentsstr]?.forEach(component => {
        let quantityPrice = component[deliveryPricestr]/100;
        let quantityForComponent = 0;
        let Volume = 0
        if(component[unitQuantityForEquipmentstr])
            quantityForComponent = component[unitQuantityForEquipmentstr]?.toLowerCase()?.includes('мл'.toLowerCase()) ? component[quantityForEquipmentstr]/1000 : component[quantityForEquipmentstr]
        else if(component[quantityForEquipmentstr])
            quantityForComponent = component[quantityForEquipmentstr]
        if(component[unitVolumestr])
            Volume = component[unitVolumestr]?.toLowerCase()?.includes('мл'.toLowerCase()) ? component[volumestr]/1000 : component[volumestr]
        else if(component[volumestr])
            Volume = component[volumestr]
        let percentFromVolume = quantityForComponent/Volume*100;
        priceToReturn = priceToReturn + (quantityPrice * percentFromVolume)
    })
    if(!priceToReturn || !isFinite(priceToReturn)){
        let quantityPrice = equipment[DeliveryPriceEquipmentstr]/100;
        let quantityForComponent = 0;
        let Volume = 0
        if(equipment[unitVolumestr]){
            Volume = equipment[unitVolumestr]?.toLowerCase()?.includes('мл'.toLowerCase()) ? equipment[volumestr]/1000 : equipment[volumestr]
            quantityForComponent = equipment[unitVolumestr]?.toLowerCase()?.includes('мл'.toLowerCase()) ? equipment[volumestr]/1000 : equipment[volumestr]
        }
        else if(equipment[volumestr]){
            Volume = equipment[volumestr]
            quantityForComponent = equipment[volumestr]
        }

        let percentFromVolume = quantityForComponent/Volume*100;
        priceToReturn = quantityPrice * percentFromVolume
    }
    if(!priceToReturn || !isFinite(priceToReturn)){
        priceToReturn = 0
    }

    return priceToReturn / packageSize
}
export const handleMaxPrice = (deliveryPrice, defaultDiscountValue, discountManager, DeliveryCurrencyEquipment, USD, handleToString, pricePerLiterDelivery = 0, pricePerLiterService = 0, isCamelCase = false) => {


    const price =  calcPriceSt(deliveryPrice, defaultDiscountValue, discountManager, DeliveryCurrencyEquipment, USD, handleToString, pricePerLiterDelivery, pricePerLiterService, isCamelCase)
    if(handleToString){
        if(price === '-1 000,00 ₽') return <span>Перебор!</span>
        else return price
    }
    else{
        return price
    }

}
export const calcPriceSt = (price, defaultDiscountValue, discountManager = 0, DeliveryCurrencyEquipment = "", USD = 1, handleToString, pricePerLiterDelivery = 0, pricePerLiterService = 0, isCamelCase = false) => {

    let rozn = 0;
    if (DeliveryCurrencyEquipment.toLowerCase().includes("руб")) {
        rozn = (price * COEF_PRICE_MORAVIA) + DELIVERY_LITER_PRICE;
    } else {
        rozn = (((price + COEF1) * COEF2 + (price + COEF1)) * COEF_PRICE_MORAVIA) * USD.Value + DELIVERY_LITER_PRICE;
    }



    let percSumDiscountParNom = (rozn / 100) * (-discountManager);
    let percSumM = (rozn / 100) * (-defaultDiscountValue);
    let result = 0;

    if (percSumM > 0 && (rozn + percSumDiscountParNom) < rozn) {
        result = -1000
    } else if (percSumM > 0) {
        result = (rozn + percSumDiscountParNom)
    } else if ((rozn + percSumDiscountParNom) < (percSumM + rozn)) {
        result = -1000
    } else {
        result = (rozn + percSumDiscountParNom)
    }

    result += pricePerLiterDelivery + pricePerLiterService

    if(handleToString){
        return formatter.format(Math.floor(result))
    }
    else{
        return result
    }

};
export const calcPriceMin = (price, defaultDiscountValue, discountManager= 0, DeliveryCurrencyEquipment= "", USD = 1, handleToString,  pricePerLiterDelivery = 0, pricePerLiterService = 0, isCamelCase = false) => {

    let rozn = 0;
    if(DeliveryCurrencyEquipment.toLowerCase().includes("руб")){
        rozn = (price * COEF_PRICE_MORAVIA) + DELIVERY_LITER_PRICE

    }
    else {
        rozn = (((price + COEF1) * COEF2 + (price + COEF1)) * COEF_PRICE_MORAVIA) * USD.Value + DELIVERY_LITER_PRICE
    }

    let percSumM = (rozn / 100) * (-defaultDiscountValue);
    let percSumDiscountParNom = (rozn / 100) * (-discountManager);


    let result = 0;

    if (percSumDiscountParNom > 0){
        result = (percSumM + rozn)
    }
    else if((percSumM + rozn + percSumDiscountParNom) < (percSumM + rozn)){
        result = (percSumM + rozn)
    }
    else{
        result = (percSumM + rozn + percSumDiscountParNom)
    }

    result += pricePerLiterDelivery + pricePerLiterService

    if(handleToString){
        return formatter.format(Math.floor(result))
    }
    else{
        return result
    }

}
export const getSupplyPriceProviderPerUnit = (type, equipment = null, component = null) => {

    let priceToReturn = 0;
    let packageSize = 0;

    if (type === "equipment")
    {
        if (!equipment)
        return priceToReturn;

        packageSize = equipment?.equipmentInformation?.packageSize || 1;

        if (!equipment?.components)
            equipment.components = [];

        equipment?.components?.forEach(componentQuantity => {
            if (componentQuantity?.component){

                let supplyPrice = componentQuantity?.component?.supplyPrice?.price ?? 0;
                if (supplyPrice === 0)
                    supplyPrice = 0;

                let volume = componentQuantity?.component?.componentInformation?.volume ?? 1;
                if (volume === 0)
                    volume = 1;

                let quantityPrice = supplyPrice / 100;
                if (componentQuantity?.component?.componentInformation?.unitMeasurement?.unitName === "шт"){
                    let percentForvolume = componentQuantity?.quantityComponent / volume * 100;
                    priceToReturn = priceToReturn + (quantityPrice * percentForvolume);
                }
                else {
                    let endpointQuantity = tryHandleUnitMeasurementValue(componentQuantity?.component.componentInformation?.unitMeasurement, componentQuantity?.unitMeasurement, componentQuantity?.quantityComponent, volume);

                    let percentForvolume = endpointQuantity / volume * 100;
                    priceToReturn = priceToReturn + (quantityPrice * percentForvolume);
                }
            }
        })
    }
    else if(type === "component")
    {
        if (!component)
        return priceToReturn;

        packageSize = component?.componentInformation?.volume || 1;

        priceToReturn = component?.supplyPrice?.price || 0;
    }

    if (packageSize === 0)
        packageSize = 1;

    return priceToReturn / packageSize;
}
const tryHandleUnitMeasurementValue = (toUnitMeasurement, fromUnitMeasurement, currentAvailable, packageSize) => {
    let availableQuantityResult = 0;

    if (!toUnitMeasurement || !fromUnitMeasurement)
        return availableQuantityResult;

    if (fromUnitMeasurement?.unitName === "л (дм3)")
    {
        if (toUnitMeasurement?.unitName === "л (дм3)")
        {
            availableQuantityResult = currentAvailable;
        }
        else if (toUnitMeasurement?.unitName === "мл (см3)")
        {
            availableQuantityResult = currentAvailable * 1000;
        }
        else if (toUnitMeasurement?.unitName === "шт")
        {
            availableQuantityResult = currentAvailable / packageSize;
        }
    }
    else if (fromUnitMeasurement?.unitName === "мл (см3)")
    {
        if (toUnitMeasurement?.unitName === "л (дм3)")
        {
            availableQuantityResult = currentAvailable / 1000;
        }
        else if (toUnitMeasurement?.unitName === "мл (см3)")
        {
            availableQuantityResult = currentAvailable;
        }
        else if (toUnitMeasurement?.unitName === "шт")
        {
            availableQuantityResult = currentAvailable / packageSize;
        }
    }
    else if (fromUnitMeasurement?.unitName === "шт")
    {
        if (toUnitMeasurement?.unitName === "л (дм3)")
        {
            availableQuantityResult = currentAvailable * packageSize;
        }
        else if (toUnitMeasurement.unitName === "мл (см3)")
        {
            availableQuantityResult = currentAvailable * packageSize * 1000;
        }
        else if (toUnitMeasurement?.unitName === "шт")
        {
            availableQuantityResult = currentAvailable;
        }
    }

    return availableQuantityResult;
}
export const calcPriceMinNew = (pricePerUnitSupply, maxDiscount, discountManager= 0, pricePerUnitDelivery = 0, pricePerUnitService = 0, currency) => {

    let rozn = 0;

    let currencyVal = 0;
    if (!currency)
        currencyVal = 90;
    else
        currencyVal = currency?.courseToRUB ?? 0;

    if (currency?.currencyCode.toLowerCase().includes("руб"))
        rozn = (pricePerUnitSupply * COEF_PRICE_MORAVIA) + DELIVERY_LITER_PRICE;
    else
        rozn = (((pricePerUnitSupply + COEF1) * COEF2 + (pricePerUnitSupply + COEF1)) * COEF_PRICE_MORAVIA) * currencyVal + DELIVERY_LITER_PRICE;


    let percSumDiscountParNom = (rozn / 100) * (-discountManager);
    let percSumM = (rozn / 100) * (-maxDiscount);

    let result = 0;

    if (percSumDiscountParNom > 0){
        result = (percSumM + rozn)
    }
    else if((percSumM + rozn + percSumDiscountParNom) < (percSumM + rozn)){
        result = (percSumM + rozn)
    }
    else{
        result = (percSumM + rozn + percSumDiscountParNom)
    }

    result += pricePerUnitDelivery + pricePerUnitService

    return result

}
export const handleMaxPriceNew = (pricePerUnitSupply, maxDiscount, discountManager, pricePerUnitDelivery = 0, pricePerUnitService = 0, currency = null) => {


    let priceForUnit = 0;
    let currencyVal = 0;

    if (!currency)
        currencyVal = 90;
    else
        currencyVal = currency?.courseToRUB ?? 0;

    let rozn = 0;
    if (currency?.currencyCode?.toLowerCase()?.includes("руб"))
        rozn = (pricePerUnitSupply * COEF_PRICE_MORAVIA) + DELIVERY_LITER_PRICE;
    else
        rozn = (((pricePerUnitSupply + COEF1) * COEF2 + (pricePerUnitSupply + COEF1)) * COEF_PRICE_MORAVIA) * currencyVal + DELIVERY_LITER_PRICE;

    let percSUmDiscountParNom = (rozn / 100) * (-discountManager);
    let percSumM = (rozn / 100) * (-maxDiscount);

    if (percSumM > 0 && (rozn + percSUmDiscountParNom) < rozn)
        priceForUnit = -1000;
    else if (percSumM > 0)
        priceForUnit = (rozn + percSUmDiscountParNom);
    else if ((rozn + percSUmDiscountParNom) < (percSumM + rozn))
        priceForUnit = -1000;
    else
        priceForUnit = (rozn + percSUmDiscountParNom);

    priceForUnit += pricePerUnitDelivery + pricePerUnitService;



    return priceForUnit;

}
export const calcMinMaxPrices = (priceForUnit, maxDiscount) => {
    const maxPrice = priceForUnit;
    const minPrice = maxPrice + (maxPrice / 100) * (-maxDiscount);
    const strMinPrice = getRubString(minPrice);
    const strMaxPrice = maxPrice > 0 ? getRubString(maxPrice) : 'Не верная скидка';
    return {strMinPrice, strMaxPrice, minPrice, maxPrice};
}
export const getPrices = () => {



};