import './KPListTS.css'

const KpListTs = () => {
    return (
        <div>

        </div>
    );
};

export default KpListTs;