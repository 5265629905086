import {useContext} from 'react';
import {MessageHubContext} from "../features/messagehub/MessageHubProvider";
const UseMessageHub = () => {
    const messageHubContext = useContext(MessageHubContext)
    const addMessage = (text, options = null) => {
        messageHubContext?.add(text, options);
    }
    return addMessage;
};

export default UseMessageHub;