import {useEffect, useState} from "react";
import {useInteractionBackend} from "../../../../../hooks/useInteractionBackend";
import {getByAccessor} from "../../../API/remainsAPI";
import RemainsCheckbox from "../../remainscheckbox/RemainsCheckbox";
import newArrayIds from "../../../helpers/newArrayIds";
import ClearBtn from "../../../UI/clearbtn/ClearBtn";
import {useAuth} from "../../../../../features/authprovider/AuthProvider";


const FormCoveragesFilter = ({filters, setFilters, updatePosition}) => {
    const [initialFilters, setInitialFilters] = useState(filters.formCoveragesIds);
    const [formCoverages, setFormCoverages] = useState(initialFilters);
    const {userInfo} = useAuth();

    const [fetchFormCoverages] = useInteractionBackend({
        type: 'axios',
        cb: getByAccessor,
        thenCb: ({data}) => setFormCoverages(data)
    })

    useEffect(() => {
        const token = userInfo?.accessToken || '';
        fetchFormCoverages({token, accessor: 'FormCoverage'});
    }, [])

    return (
        <div>
            {
                formCoverages?.map(coverage => {
                    return (
                        <RemainsCheckbox
                            key={coverage.guid}
                            checked={filters.formCoveragesIds.includes(coverage.id)}
                            onClick={(e) => {
                                let newArr = newArrayIds(coverage.id, filters.formCoveragesIds);
                                setFilters({...filters, formCoveragesIds: newArr});
                                updatePosition(e);
                            }}
                        >
                            {coverage.value}
                        </RemainsCheckbox>
                    )
                })
            }
            <ClearBtn
                isActive={filters.formCoveragesIds.length > 0}
                onClick={(e) => {
                    setFilters({...filters, formCoveragesIds: initialFilters});
                    updatePosition(e);
                }}
            />
        </div>
    );
};

export default FormCoveragesFilter;