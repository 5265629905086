import './ComponentMaterials.css'
import {ReactComponent as ComponentIcon} from "../../../../../components/UI/icons/folder.svg";
import {useBreadcrumbsStore} from "../../../../../components/breadcrumbs";
import {useNavigate} from "react-router";

const ComponentMaterials = ({component}) => {
    const addBreadcrumb = useBreadcrumbsStore.getState().addBreadcrumb;
    const navigate = useNavigate();
    return (
        <div className='component-materials'
            onClick={e => {
                addBreadcrumb(
                    component.name,
                    () => navigate(`/profile/materials/component/${component.id}`)
                );
                navigate(`/profile/materials/component/${component.id}`);
            }}
        >
            <ComponentIcon className="component-materials__icon"/>
            <span className="component-materials__name">
                {component.name}
            </span>
        </div>
    );
};

export default ComponentMaterials;