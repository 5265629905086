import './InheritInput.css'
import {useState} from "react";
import useDebouncingFunction from "../../../../hooks/debouncingfunction/useDebouncingFunction";
import {NumericFormat} from "react-number-format";
import useControlledValue from "../../../../hooks/controlled/useControlledValue";


const InheritInput = ({
                        defaultValue = '',
                        controlled = false,
                        isNumber = false,
                        onChange,
                        suffics = '',
                        placeholder = '',
                        className = '',
                        useDebounce = false,
                        sx = {}
}) => {
    const [value, setValue] = useControlledValue({defaultValue, controlled, initialStateValue: ''})
    const [isFocus, setIsFocus] = useState(false)



    const updateValue = (newValue) => {
        onChange?.(newValue)
    }

    const debounceUpdateValue = useDebouncingFunction(updateValue, 1200)

    if(isNumber)
        return (
            <NumericFormat
                className={`inherit-input ${className}`}
                onValueChange={(values) => {
                    let value = values.floatValue ? values.floatValue : 0;

                    if(useDebounce)
                        debounceUpdateValue(value)
                    else
                        updateValue(value)
                }}
                thousandSeparator
                valueIsNumericString
                suffix={suffics}
                value={value}
                style={sx}
            />
        )

    return (
        <input
            className={`inherit-input ${className}`}
            placeholder={placeholder}
            onFocus={e => setIsFocus(true)}
            onBlur={e => setIsFocus(false)}
            onChange={e => {
                setValue(e.target.value)

                if(useDebounce)
                    debounceUpdateValue(e.target.value)
                else
                    updateValue(e.target.value)
            }}
            value={value + (!isFocus ? suffics : '')}
            style={sx}
        />
    );
};

export default InheritInput;