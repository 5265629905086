import './EquipmentNomenclatureы.css';
import {ReactComponent as EquipmentIcon} from "../../../../../components/UI/icons/folder.svg";
import {useBreadcrumbsStore} from "../../../../../components/breadcrumbs";
import {useNavigate} from "react-router";

const EquipmentNomenclatures = ({equipmentNomenclature}) => {
    const addBreadcrumb = useBreadcrumbsStore.getState().addBreadcrumb;
    const navigate = useNavigate();
    return (
        <div className="equipment-nomenclatures"
             onClick={e => {
                 addBreadcrumb(
                     equipmentNomenclature.name,
                     () => navigate(`/profile/materials/equipment/${equipmentNomenclature.id}`)
                 );
                 navigate(`/profile/materials/equipment/${equipmentNomenclature.id}`);
             }}
        >
            <EquipmentIcon className="equipment-nomenclatures__icon"/>
            <span className="equipment-nomenclatures__name">
                {equipmentNomenclature.name}
            </span>
        </div>
    );
};

export default EquipmentNomenclatures;