import {Form} from "react-bootstrap";
import MultiField from "../../../../components/multifield/MultiField";
import handlePreparation from "../../helpers/handlePreparation";
import InheritInput from "../../../../components/UI/inputs/inheritinput/InheritInput";
import {useEffect, useState} from "react";
import useAxiosHelper from "../../../../hooks/useAxiosHelper";
import './FormEditSystem.css'

const FormEditSystem = ({system, setSystem}) => {
    const [optionsTypeMetal, setOptionsTypeMetal] = useState([])
    const [optionsWidth, setOptionsWidth] = useState([])
    const [optionsTypeP, setOptionsTypeP] = useState([])
    const {getMethod} = useAxiosHelper({})

    useEffect(() => {
        getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/enums',
            queryParams: {
                accessor: 'Background',
            },
            successCallback: ({data}) => setOptionsTypeMetal(data)
        })
        getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/enums',
            queryParams: {
                accessor: 'Preparation',
            },
            successCallback: ({data}) => setOptionsTypeP(data)
        })
        getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/enums',
            queryParams: {
                accessor: 'Roughness',
            },
            successCallback: ({data}) => setOptionsWidth(data)
        })
    }, [])
    return (
        <Form className={'system-modal'}>
            <div className="system-modal__body">
                <div className="system-modal__item">
                    <MultiField
                        title={'Наименование системы'}
                    >
                        <MultiField.Input
                            value={system?.title}
                            onChange={newValue => setSystem({
                                ...system,
                                title: newValue
                            })}
                            placeholder={'Наименование системы'}
                        />
                    </MultiField>
                </div>
                <div className="system-modal__item">
                    <MultiField title={'Подготовка поверхности №1'}>
                        <MultiField.Dropdown
                            items={optionsTypeP}
                            value={system?.preparation?.[0]?.value}
                            onChange={(e) => setSystem({
                                ...system,
                                preparation: handlePreparation(system?.preparation, 0, optionsTypeP.find(x => x.value === e.target.value), 'value')
                            })}
                        />
                    </MultiField>
                </div>
                <div className="system-modal__item">
                    <MultiField title={'% подготовки'}>
                        <MultiField.Input
                            isNumber
                            value={system?.preparation?.[0]?.percent}
                            onChange={newValue => setSystem({
                                ...system,
                                preparation: handlePreparation(system?.preparation, 0, newValue, 'percent')
                            })}
                            placeholder={'% подготовки'}
                        />
                    </MultiField>
                </div>
                <div className="system-modal__item">
                    <MultiField
                        title={'Площадь'}
                    >
                        <InheritInput
                            controlled
                            isNumber
                            defaultValue={system?.square || 0}
                            onChange={newValue => setSystem({
                                ...system,
                                square: newValue
                            })}
                            suffics={' м'}
                            placeholde={'Площадь'}
                        />
                    </MultiField>
                </div>
                <div className="system-modal__item">
                    <MultiField title={'Подготовка поверхности №2'}>
                        <MultiField.Dropdown
                            items={optionsTypeP}
                            value={system?.preparation?.[1]?.value}
                            onChange={(e) => setSystem({
                                ...system,
                                preparation: handlePreparation(system?.preparation, 1, optionsTypeP.find(x => x.value === e.target.value), 'value')
                            })}
                        />
                    </MultiField>
                </div>
                <div className="system-modal__item">
                    <MultiField title={'% подготовки'}>
                        <MultiField.Input
                            isNumber
                            value={system?.preparation?.[1]?.percent}
                            onChange={newValue => setSystem({
                                ...system,
                                preparation: handlePreparation(system?.preparation, 1, newValue, 'percent')
                            })}
                            placeholder={'% подготовки'}
                        />
                    </MultiField>
                </div>
                <div className="system-modal__body_medium">
                    <div className="system-modal__item">
                        <MultiField title={'Подложка'}>
                            <MultiField.Dropdown
                                items={optionsTypeMetal}
                                value={system?.background}
                                onChange={(e) => setSystem({
                                    ...system,
                                    background: optionsTypeMetal.find(x => x.value === e.target.value)
                                })}
                            />
                        </MultiField>
                    </div>
                    <div className="system-modal__item">
                        <MultiField title={'Шероховатость'}>
                            <MultiField.Dropdown
                                items={optionsWidth}
                                value={system?.roughness}
                                onChange={(e) => setSystem({
                                    ...system,
                                    roughness: optionsWidth.find(x => x.value === e.target.value)
                                })}
                            />
                        </MultiField>
                    </div>
                </div>
                <div className="system-modal__item">
                    <MultiField title={'Подготовка поверхности №3'}>
                        <MultiField.Dropdown
                            items={optionsTypeP}
                            value={system?.preparation?.[2]?.value}
                            onChange={(e) => setSystem({
                                ...system,
                                preparation: handlePreparation(system?.preparation, 2, optionsTypeP.find(x => x.value === e.target.value), 'value')
                            })}
                        />
                    </MultiField>
                </div>
                <div className="system-modal__item">
                    <MultiField title={'% подготовки'}>
                        <MultiField.Input
                            isNumber
                            value={system?.preparation?.[2]?.percent}
                            onChange={newValue => setSystem({
                                ...system,
                                preparation: handlePreparation(system?.preparation, 2, newValue, 'percent')
                            })}
                            placeholder={'% подготовки'}
                        />
                    </MultiField>
                </div>
            </div>
        </Form>
    );
};

export default FormEditSystem;