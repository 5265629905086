import React, {useEffect, useState} from 'react';
import {usePaginationController} from "../../../../../components/pagination/components";
import useCheckAccess from "../../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../../components/access/AccessDenied";
import useAxiosHelper from "../../../../../hooks/useAxiosHelper";
import EditIcon from "../../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import {useNavigate} from "react-router";
import GuidsHeader from "../../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../../components/guids/search/GuidsSearchInput";
import GuidsBody from "../../../../../components/guids/body/GuidsBody";
import TableBootstrap from "../../../../../components/tableb/TableBootstrap";
import GuidsPagination from "../../../../../components/guids/pagination/GuidsPagination";
import GuidsWrapper from "../../../../../components/guids/wrapper/GuidsWrapper";
import useLoader from "../../../../../hooks/useLoader";
import {updateBxInfo} from "../../../../newTkp/features/TkpHandlers";

const TkpTemplateList = () => {
    const [bxInfo, setBXInfo] = useState(null);
    const [searchValue, setSearchValue] = useState([]);
    const [items, setItems] = useState({});
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const {postMethod, deleteMethod} = useAxiosHelper({});
    const {hideAll} = useLoader()
    const navigate = useNavigate()


    useEffect(() => {
        document.title = "Мой клиент: Шаблоны ТКП";
        setBXInfo(updateBxInfo());
    }, [])
    useEffect(() => {
        if(items?.currentPage > items?.pages)
            setPage(items?.pages)
    }, [items]);
    useEffect(() => {
        if (page === 1) changePage(page);

        updateItems()
    }, [page]);
    const updateItems = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/template/tkp/list',
        queryParams: {
            offset: 10,
            page: page
        },
        data: filters,
        successCallback: ({data}) => setItems(data)
    })
    const removeItem = (guid) => deleteMethod({
        urlWithoutBaseUrl: 'ancorr/api/template/tkp/delete',
        queryParams: {
            guid: guid
        },
        successCallback: () => updateItems()
    })
    const createTKP = (id) => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/template/tkp/create',
        data: {
            TemplateId : id,
            BXDealId : bxInfo.dealId,
            AssignedId: null
        },
        successCallback: ({data}) => navigate(`/sales/tkp/detail/${data?.id}`)
    })

    const {changePage, paginationModule} = usePaginationController({
        pagesCount: items?.pages || 1,
        changePageCallback: (data) => setPage(data)
    })

    const popoverActions = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Создать ТКП',
            onClick: item => createTKP(item?.id)
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => removeItem(item?.guid)
        },
    ]

    const access = useCheckAccess("guids/tkpTemplates");

    if(!access){
        hideAll()
        return <AccessDenied />
    }

    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'ШАБЛОНЫ ТКП'}/>
                <div className={'flex center gap'}>
                    <GuidsSearchInput searchValue={searchValue} setSearchValue={setSearchValue}/>
                </div>
            </GuidsHeader>
            <GuidsBody>
                <TableBootstrap
                    schema={schema}
                    items={items?.data || []}
                    popoverActions={popoverActions}
                    popoverCaption={"Действия"}
                />
            </GuidsBody>
            <GuidsPagination>
                {
                    paginationModule
                }
            </GuidsPagination>
        </GuidsWrapper>
    );
};

const schema = [
    {
        title: 'ID',
        callbackItem: item => item?.id,
    },
    {
        title: 'Наименование',
        callbackItem: item => item?.title,
    },
    {
        title: 'Дата создания',
        callbackItem: item => new Date(item?.createdTimeDb)?.toLocaleDateString('ru-RU') || "Нет данных",
    },
]

export default TkpTemplateList;