import {
    getCoatingTypeFromSliceV2,
    getPricesDiluentV2,
    getQuantityDiluent, handleHidingPowerV2, handleThicknessTms
} from "../../../../features/TkpHandlers";
import {Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import {BaseUrl} from "../../../../../../features/databaseinfo/BaseUrl";
import {getCurrencyString} from "../../../../../../features/formatters/forrmaterrub";
import TkpHeaderPdfV2 from "./TkpHeaderPdfV2";

const TkpSystemPdfV2 = ({system, index, defaultStyles, visiblePrice, tkp, USD}) => {
    const quantityDiluent = (slice) => {
        let quantityDiluent = 0;
        if (slice?.material?.equipmentInformation?.lkmInformation?.diluent?.percentFromMaterial) {
            quantityDiluent = getQuantityDiluent(slice?.quantity, slice?.material?.equipmentInformation?.lkmInformation?.diluent?.percentFromMaterial)
        }
        return quantityDiluent?.toFixed(2)
    }
    const getLocalPriceDiluent = (slice) => {
        let quantityDiluent = 0;
        let pricePerLiterDelivery = tkp?.settings?.distributeDeliveryToPrice ? ((tkp?.settings?.deliveryPrice / tkp?.totalLitersl) || 0) : 0;
        let pricePerLiterService = tkp?.settings?.distributeServiceToPrice ? ((tkp?.settings?.servicePricel / tkp?.totalLitersl) || 0) : 0;
        if (slice?.material?.equipmentInformation?.lkmInformation?.diluent?.percentFromMaterial) {
            quantityDiluent = getQuantityDiluent(slice?.quantity, slice?.material?.equipmentInformation?.lkmInformation?.diluent?.percentFromMaterial);
        }
        let { TotalPrice} = getPricesDiluentV2(quantityDiluent, slice?.material, 0, USD, pricePerLiterDelivery, pricePerLiterService);
        return TotalPrice ? TotalPrice : 0
    }
    return (
        <Page
            size={"A4"}
            orientation={"landscape"}
            style={[defaultStyles.page, styles.systemPage]}
        >
            <View style={defaultStyles.wrapper}>
                <Image
                    src={
                        `${BaseUrl}/photo/img/BackgroundAncorr.png`
                    }
                    style={defaultStyles.backgroundImage}
                />
                <TkpHeaderPdfV2 tkp={tkp} />
                <View style={styles.system}>
                    <View style={styles.systemHeader}>
                        <View style={styles.systemHeaderTitleView}>
                            <Text style={[styles.systemHeaderTitleText, { fontFamily: 'Montserrat-Bold'}]}>Система окраски №{index + 1} - {(system?.square + 'м²') || 'Не заполнено'}</Text>
                        </View>
                        <View style={[styles.systemHeaderTitleView, { fontWeight: 400, fontSize: 12}]}>
                            <Text style={[styles.systemHeaderTitleText, { fontWeight: 200, fontSize: 12}]}>{system?.title || 'Не заполнено'}</Text>
                        </View>
                    </View>
                    <View style={styles.systemBody}>
                        <View style={[defaultStyles.items, defaultStyles.tableHeader]}>
                            <View style={[defaultStyles.item, defaultStyles.tableHeader]}>
                                <View style={[defaultStyles.column, {flex: 0.5}]}>
                                    <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>
                                        Слой
                                    </Text>
                                </View>
                                <View style={[defaultStyles.column, {flex: 2.5}]}>
                                    <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>
                                        Материал
                                    </Text>
                                </View>
                                <View style={[defaultStyles.column, {flex: 0.6}]}>
                                    <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>
                                        % от S
                                    </Text>
                                </View>
                                <View style={[defaultStyles.column, {flex: 0.6}]}>
                                    <View style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>
                                        <Text>ФП</Text>
                                        <Text>(%)</Text>
                                    </View>
                                </View>
                                <View style={[defaultStyles.column, {flex: 1.6}]}>
                                    <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>
                                        Тип
                                    </Text>
                                </View>
                                <View style={[defaultStyles.column, {flex: 0.5}]}>
                                    <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>
                                        ТСП
                                    </Text>
                                </View>
                                <View style={[defaultStyles.column, {flex: 0.5}]}>
                                    <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>
                                        ТМС
                                    </Text>
                                </View>
                                <View style={[defaultStyles.column, {flex: 0.7}]}>
                                    <View style={[defaultStyles.tableHeaderText, {fontSize: 8}]}>
                                        <Text style={[{textAlign: 'center'}]}>СО</Text>
                                        <Text style={[{textAlign: 'center'}]}>(%)</Text>
                                    </View>
                                </View>
                                <View style={[defaultStyles.column, {flex: 0.7}]}>
                                    <View style={[defaultStyles.tableHeaderText, {fontSize: 8}]}>
                                        <Text style={[{textAlign: 'center'}]}>Укрыв.</Text>
                                        <Text style={[{textAlign: 'center'}]}>(м²/л)</Text>
                                    </View>
                                </View>
                                <View style={[defaultStyles.column, {flex: 0.8}]}>
                                    <View style={[defaultStyles.tableHeaderText, {fontSize: 8}]}>
                                        <Text style={[{textAlign: 'center'}]}>Вес</Text>
                                        <Text style={[{textAlign: 'center'}]}>(кг)</Text>
                                    </View>
                                </View>
                                <View style={[defaultStyles.column, {flex: 1}]}>
                                    <View style={[defaultStyles.tableHeaderText, {fontSize: 8}]}>
                                        <Text style={[{textAlign: 'center'}]}>Расч. кол-во</Text>
                                        <Text style={[{textAlign: 'center'}]}>(литр)</Text>
                                    </View>
                                </View>
                                {visiblePrice && <View style={[defaultStyles.column, {flex: 1}]}>
                                    <View style={[defaultStyles.tableHeaderText, {fontSize: 8, alignItems: 'center', justifyContent: 'center'}]}>
                                        <Text style={[{textAlign: 'center'}]}>Цена за литр</Text>
                                        <Text style={[{textAlign: 'center'}]}>(руб)</Text>
                                    </View>
                                </View>}
                                {visiblePrice && <View style={[defaultStyles.column, {flex: 1.6}]}>
                                    <View style={[defaultStyles.tableHeaderText, {fontSize: 8}]}>
                                        <Text style={[{textAlign: 'center'}]}>Итого</Text>
                                        <Text style={[{textAlign: 'center'}]}>(руб)</Text>
                                    </View>
                                </View>}
                                <View style={[defaultStyles.column, {flex: 2}]}>
                                    <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>
                                        Разбавитель
                                    </Text>
                                </View>
                                <View style={[defaultStyles.column, {flex: 1}]}>
                                    <View style={[defaultStyles.tableHeaderText, {fontSize: 8}]}>
                                        <Text style={[{textAlign: 'center'}]}>Кол-во</Text>
                                        <Text style={[{textAlign: 'center'}]}>(литр)</Text>
                                    </View>
                                </View>
                                {visiblePrice && <View style={[defaultStyles.column, {flex: 1}]}>
                                    <View style={[defaultStyles.tableHeaderText, {fontSize: 8}]}>
                                        <Text style={[{textAlign: 'center'}]}>Итого</Text>
                                        <Text style={[{textAlign: 'center'}]}>(руб)</Text>
                                    </View>
                                </View>}
                            </View>
                        </View>
                        <View style={defaultStyles.items}>
                            {system?.slices?.map(slice => (
                                <View key={slice?.guid} style={[defaultStyles.item]}>
                                    <View style={[defaultStyles.column, {flex: 0.5}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{slice?.slice}</Text>
                                    </View>
                                    <View style={[defaultStyles.column, {flex: 2.5, alignItems: "flex-start"}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{slice?.material?.printName}</Text>
                                    </View>
                                    <View style={[defaultStyles.column, {flex: 0.6}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{slice?.percentFromSquare}</Text>
                                    </View>
                                    <View style={[defaultStyles.column, {flex: 0.6}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{slice?.lossFactor}</Text>
                                    </View>
                                    <View style={[defaultStyles.column, {flex: 1.6}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{getCoatingTypeFromSliceV2(slice, 'material')}</Text>
                                    </View>
                                    <View style={[defaultStyles.column, {flex: 0.5}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{slice?.thicknessTsp?.toFixed(0)}</Text>
                                    </View>
                                    <View style={[defaultStyles.column, {flex: 0.5}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{handleThicknessTms(slice?.thicknessTsp, slice?.material?.equipmentInformation?.lkmInformation?.dryResidue || 0)?.toFixed(0)}</Text>
                                    </View>
                                    <View style={[defaultStyles.column, {flex: 0.7}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{`${slice?.material?.equipmentInformation?.lkmInformation?.dryResidue?.toFixed(0) || 0}%`}</Text>
                                    </View>
                                    <View style={[defaultStyles.column, {flex: 0.7}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{slice?.material && slice?.material?.id ? handleHidingPowerV2(slice?.material, slice?.thicknessTsp, slice?.lossFactor)?.toFixed(2) : 'Нет данных'}</Text>
                                    </View>
                                    <View style={[defaultStyles.column, {flex: 0.8}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{slice?.totalWeight?.toFixed(0)}</Text>
                                    </View>
                                    <View style={[defaultStyles.column, {flex: 1}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{slice?.quantity?.toFixed(2)}</Text>
                                    </View>
                                    {visiblePrice && <View style={[defaultStyles.column, {flex: 1}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{getCurrencyString(slice?.priceForLiter, tkp?.currencyForHandle?.currencyCode)}</Text>
                                    </View>}
                                    {visiblePrice && <View style={[defaultStyles.column, {flex: 1.6}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{getCurrencyString(slice?.totalPrice, tkp?.currencyForHandle?.currencyCode)}</Text>
                                    </View>}
                                    <View style={[defaultStyles.column, {flex: 2, borderLeft: '1px solid black', opacity: 0.8}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{slice?.material?.equipmentInformation?.lkmInformation?.diluent?.equipment?.name?.replace('Разбавитель', '') || 'Нет данных'}</Text>
                                    </View>
                                    <View style={[defaultStyles.column, {flex: 1}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{quantityDiluent(slice)}</Text>
                                    </View>
                                    {visiblePrice && <View style={[defaultStyles.column, {flex: 1}]}>
                                        <Text style={[defaultStyles.tableHeaderText, {fontSize: 8, textAlign: 'center'}]}>{getCurrencyString(getLocalPriceDiluent(slice), tkp?.currencyForHandle?.currencyCode)}</Text>
                                    </View>}
                                </View>
                            ))}
                        </View>
                    </View>
                    <View style={styles.systemFooter}>
                        <View style={styles.systemFooterItem}>
                            <Text style={[styles.systemFooterText, defaultStyles.tableHeaderText]}>Подложка: {system?.background?.value || 'Не заполнено'}</Text>
                        </View>
                        <View style={[styles.systemFooterItem, {flex: 1.5}]}>
                            <Text style={[styles.systemFooterText, defaultStyles.tableHeaderText]}>Шероховатость: {system?.roughness?.value || 'Не заполнено'}</Text>

                        </View>
                        <View style={[styles.systemFooterItem, {flex: 4, justifyContent: 'flex-start'}]}>
                            <Text style={[styles.systemFooterText, defaultStyles.tableHeaderText]}>Подготовка поверхности:</Text>
                            {system?.preparation?.map(item => item?.percent !== 0 && (
                                <Text style={[styles.systemFooterText, defaultStyles.tableHeaderText]}>{` / ${item?.value?.value || 'Не заполнено'} - ${item?.percent || 0}%`}</Text>
                            ))}
                        </View>
                    </View>
                </View>
            </View>
        </Page>
    );
};

const styles = StyleSheet.create({

    textSystemHeader: {
        width: '100%',
        flex: '1 1 5%',
        maxHeight: '5%',
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center"
    },
    systemPage: {
        backgroundColor: 'white',
        padding: 20,
    },
    system: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: '100%',
        flex: 13,
    },
    systemHeader: {
        padding: 10,
        gap: 5,
        width: '100%',
        // flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    systemHeaderTitleView: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        // height: '50%',
        // flex: 15,

    },
    systemHeaderTitleText: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        // height: '100%',
        fontSize: 14,
        fontWeight: 600,
        width: '100%',
    },
    systemBody: {
        width: '100%',
        flex: 15,
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column"
    },
    systemFooter: {
        width: '100%',
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        gap: 10,
    },
    systemFooterItem: {
        width: '100%',
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        gap: 10,
        fontSize: 11
    },
    systemFooterText: {

    }
})

export default TkpSystemPdfV2;