import {useAccordionButton} from "react-bootstrap";
import {ReactComponent as ChevronDown} from "../../../../../components/UI/icons/chevron-down.svg";
import './Toggle.css'
import {useState} from "react";


const Toggle = ({children, eventKey}) => {
    const decoratedOnClick = useAccordionButton(eventKey);
    const [collapsed, setCollapsed] = useState(false);
    return (
        <button
            className={`accordion-toggle${ collapsed ? " collapsed" : ""}`}
            onClick={(e) => {
                decoratedOnClick(e);
                setCollapsed(!collapsed);
            }}
            type="button"
        >
            <ChevronDown className="accordion-toggle__icon"/>
            {children}
        </button>
    );
};

export default Toggle;