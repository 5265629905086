import {useState, createContext, useRef} from 'react';
import Loader from "../../components/loader/Loader";
export const LoaderContext = createContext(null)

const LoaderProvider = ({children}) => {
    const [visiblePreloader, setVisiblePreloader] = useState(false)
    const [animationPreloader, setAnimationPreloader] = useState(false)
    const countRef = useRef(0)
    const show = () => {
        if(countRef.current > 0)
            countRef.current++;
        else{
            countRef.current++;
            setVisiblePreloader(true);
            setAnimationPreloader(true);
        }
    }
    const hide = () => {
        if(countRef.current !== 0)
            countRef.current--;

        if(countRef.current === 0){
            setAnimationPreloader(false);
            countRef.current = 0;
        }
    }
    const hideAll = () => {
        setAnimationPreloader(false);
        countRef.current = 0;
    }
    return (
        <LoaderContext.Provider value={{show: show, hide: hide, hideAll: hideAll}}>
            {children}
            {visiblePreloader && <Loader show={animationPreloader} setVisiblePreloader={setVisiblePreloader}/>}
        </LoaderContext.Provider>
    );
};

export default LoaderProvider;