import './Materials.css'
import usePrintItems from "./hooks/usePrintItems";
import useMaterialsStore from "./store/materialsStore";
import useMaterialsController from "./hooks/useMaterialsController";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import {useNavigate, useParams} from "react-router";

const Materials = () => {
    const materials = useMaterialsStore(state => state.materials);
    const navigate = useNavigate();
    const {type} = useParams();

    useMaterialsController();

    const items = usePrintItems({materials});

    return (
        <div className='materials'>
            <Breadcrumbs rootOnClick={() => navigate('/profile/materials')}/>
            {(type === undefined || type === 'folder' || type === 'nomenclature') &&
                <div className="materials__header">
                    Название папки
                </div>
            }
            {items}
        </div>
    );
};

export default Materials;