import './DealListTS.css'
import ListIcon from "../../../../components/UI/icons/ListIcon";
import {useMultiStepComponent} from "../../../../hooks/usemultistepcomponent/UseMultiStepComponents";
import DetailWrapper from "../../../../components/detailcard/wrapper/DetailWrapper";
import TabsBlock from "../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../components/UI/buttons/buttontab/ButtonTab";
import DetailBody from "../../../../components/detailcard/body/DetailBody";
import RentDealList from "./rentdeallist/RentDealList";
import BuyDealList from "./buydeallist/BuyDealList";

const DealListTs = ({assignedId, clientId, projectSellVariationId, projectRentVariationId}) => {

    const {
        step,
        titleStep,
        goTO
    } = useMultiStepComponent([
        {
            titleStep: 'Сделки аренды',
            component: <RentDealList assignedId={assignedId} clientId={clientId} projectRentVariationId={projectRentVariationId}/>
        },
        {
            titleStep: 'Сделки покупки',
            component: <BuyDealList assignedId={assignedId} clientId={clientId} projectSellVariationId={projectSellVariationId}/>
        },
    ])

    return (
        <DetailWrapper>
            <TabsBlock>
                <ButtonTab
                    icon={<ListIcon />}
                    title={'Сделки аренды'}
                    onClick={() => goTO(0)}
                    isActive={titleStep === "Сделки аренды"}
                />
                <ButtonTab
                    icon={<ListIcon />}
                    title={'Сделки покупки'}
                    onClick={() => goTO(1)}
                    isActive={titleStep === "Сделки покупки"}
                />
            </TabsBlock>
            <DetailBody>
                {step}
            </DetailBody>
        </DetailWrapper>
    );
};

export default DealListTs;