import {useState} from 'react';
import useDebouncingFunction from "../debouncingfunction/useDebouncingFunction";
import {useMutation} from "@tanstack/react-query";

import axios from "axios";
const updateSuggestionAddressAxios = (data) => {
    return axios.post('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', JSON.stringify(data), {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token " + '2f25b1499099b7560418c09422c079c25f43d99e'
        }
    })
}
const UseAddressSearcher = () => {

    const {mutate: updateAddressOptionFn} = useMutation({
        mutationKey: ['address', 'suggestion'],
        mutationFn: updateSuggestionAddressAxios,
        onSuccess: ({data: {suggestions: data}}) => setOptionsAddress(data)
    })
    const [optionsAddress, setOptionsAddress] = useState([])
    const [inputAddress, setInputAddress] = useState('')
    const updateInputAddressSuggestion = useDebouncingFunction((newInputValue) => {
        if(newInputValue)
            updateAddressOptionFn({query: newInputValue})
    }, 1200)
    return {
        optionsAddress,
        inputAddress,
        updateInputAddress: (newInputValue) => {
            setInputAddress(newInputValue)
            updateInputAddressSuggestion(newInputValue)
        },
        onBlur: () => {
            setInputAddress('')
            setOptionsAddress([])
        }
    };
};

export default UseAddressSearcher;