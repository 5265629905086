import {useEffect, useState} from 'react';
import './StatusGroupList.css'
import StatusInfoList from "../../components/statusinfolist/StatusInfoList";
import useAxiosHelper from "../../../../../hooks/useAxiosHelper";
import GuidsWrapper from "../../../../../components/guids/wrapper/GuidsWrapper";
import GuidsHeader from "../../../../../components/guids/header/GuidsHeader";
import GuidsBody from "../../../../../components/guids/body/GuidsBody";
import TitleDetailCard from "../../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../../components/guids/search/GuidsSearchInput";
import TableBootstrap from "../../../../../components/tableb/TableBootstrap";
import GuidsPagination from "../../../../../components/guids/pagination/GuidsPagination";
import {Button, Container, Form, Modal} from "react-bootstrap";
import {usePaginationController} from "../../../../../components/pagination/components";
import EditIcon from "../../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import useCheckAccess from "../../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../../components/access/AccessDenied";
import useLoader from "../../../../../hooks/useLoader";
import ListIcon from "../../../../../components/UI/icons/ListIcon";
const StatusGroupList = () => {
    const [searchValue, setSearchValue] = useState('')
    const [statusForEdit, setStatusForEdit] = useState(null)
    const [visibleStatusForEditSubItems, setVisibleStatusForEditSubItems] = useState(false)
    const [visibleStatusForEdit, setVisibleStatusForEdit] = useState(false)
    const {hideAll} = useLoader()
    const [items, setItems] = useState({})
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const {deleteMethod, postMethod} = useAxiosHelper({})

    useEffect(() => {
        document.title = "Мой клиент: Статусы"
    }, [])
    const updateAllStatuses = () => {
        postMethod({
            urlWithoutBaseUrl: 'ancorr/api/statuses/list',
            data: filters,
            successCallback: ({data}) => setItems(data)
        })
    }

    useEffect(() => {
        if(items?.currentPage > items?.pages)
            setPage(items?.pages)
    }, [items]);

    useEffect(() => {
        if (page === 1) changePage(page);

        updateAllStatuses()
    }, [page]);

    const addNewStatus = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/statuses/add/status',
        successCallback: () => updateAllStatuses()
    })
    const changeStatus = (newItem) => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/statuses/update/status',
        data: newItem,
        successCallback: () => updateAllStatuses()
    })
    const removeStatus = (guid) => deleteMethod({
        urlWithoutBaseUrl: 'ancorr/api/statuses/delete/status',
        successCallback: () => updateAllStatuses(),
        queryParams: {
            guid
        }
    })


    const {changePage, paginationModule} = usePaginationController({
        pagesCount: items?.pages || 1,
        changePageCallback: (data) => setPage(data)
    })

    const popoverActions = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item => setStatusForEdit(item) || setVisibleStatusForEdit(true)
        },
        {
            icon: <ListIcon classNames={'cl-light-purple'}/>,
            title: 'Список элементов',
            onClick: item => setStatusForEdit(item) || setVisibleStatusForEditSubItems(true)
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => removeStatus(item?.guid)
        },
    ]



    const schema = [
        {
            title: 'Дата создания',
            callbackItem: item => new Date(item?.createdTimeDb)?.toLocaleDateString('ru-RU') || "Нет данных",
        },
        {
            title: 'Название',
            callbackItem: item => item?.nameGroup || "Не заполнено",
        },
        {
            title: 'Конечная сущность',
            callbackItem: item => item?.destinationEntity || "Не заполнено",
        },
        {
            title: 'Тип',
            callbackItem: item => item?.type || "Не заполнено",
        },
    ]

    const access = useCheckAccess("admin/status")

    if(!access){
        hideAll()
        return <AccessDenied />
    }

    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'СТАТУСЫ'}/>
                <Container fluid className={'d-flex justify-content-end gap-2'}>
                    <GuidsSearchInput onChange={newValue => setSearchValue(newValue)}/>
                    <Button onClick={() => addNewStatus()}>Добавить</Button>
                </Container>
            </GuidsHeader>
            <GuidsBody>
                <TableBootstrap
                    schema={schema}
                    items={items?.data || []}
                    popoverActions={popoverActions}
                    popoverCaption={"Действия"}
                />
            </GuidsBody>
            <GuidsPagination>
                {
                    paginationModule
                }
            </GuidsPagination>
            <Modal size={"lg"} show={visibleStatusForEditSubItems} onHide={() => setVisibleStatusForEditSubItems(false) || setStatusForEdit(null)}>
                <Modal.Header><Modal.Title>Элементы</Modal.Title></Modal.Header>
                <Modal.Body>
                    <StatusInfoList
                        statusForEdit={statusForEdit}
                        updateAllStatuses={updateAllStatuses}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setVisibleStatusForEditSubItems(false) || setStatusForEdit(null)} variant={"danger"}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={visibleStatusForEdit} onHide={() => setVisibleStatusForEdit(false) || setStatusForEdit(null)}>
                <Modal.Header><Modal.Title>Редактирование элемента</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Наименование</Form.Label>
                            <Form.Control
                                type={'text'}
                                value={statusForEdit?.nameGroup || ''}
                                onChange={e => setStatusForEdit({...statusForEdit, nameGroup: e.target.value})}
                            />
                            <Form.Label>Конечная сущность</Form.Label>
                            <Form.Control
                                type={'text'}
                                value={statusForEdit?.destinationEntity || ''}
                                onChange={e => setStatusForEdit({...statusForEdit, destinationEntity: e.target.value})}
                            />
                            <Form.Label>Тип</Form.Label>
                            <Form.Control
                                type={'text'}
                                value={statusForEdit?.type || ''}
                                onChange={e => setStatusForEdit({...statusForEdit, type: e.target.value})}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => setVisibleStatusForEdit(false) || setStatusForEdit(null)}>Закрыть</Button>
                    <Button variant={"primary"} onClick={() => {
                        changeStatus(statusForEdit);
                        setVisibleStatusForEdit(false)
                        setStatusForEdit(null)
                    }}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
        </GuidsWrapper>
    );
};



export default StatusGroupList;