import {create} from "zustand";
import {devtools} from "zustand/middleware";


const useMaterialsStore = create()(devtools((set, get) => ({
    materials: {},
    setMaterials: (data) => {
        set({materials: data})
    },
}), {name: 'materialsStore'}));

export default useMaterialsStore;