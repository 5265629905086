import TkpLabel from "../../../tkplabel/TkpLabel";
import {
     getCoatingTypeFromSliceV2, getQuantityDiluent,
     handleHidingPowerV2,
    handleThicknessTms
} from "../../../../features/TkpHandlers";
import {getCurrencyString} from "../../../../../../features/formatters/forrmaterrub";

const ComponentClientSliceV2 = (visiblePrice, tkp) => {
    const classColumn = 'tkp-client-table__column';
    if(visiblePrice) {
        return [
            {
                Key: 1,
                TitleColumn: 'Материал',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.material?.name || 'Не заполнено'} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 2,
                TitleColumn: '% от S',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.percentFromSquare?.toFixed(0) + "%"} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 3,
                TitleColumn: 'Тип покрытия',
                invokeComponent: (item, callbackAction) => {
                    let coatingType = getCoatingTypeFromSliceV2(item, "material")
                    return (
                        <TkpLabel text={coatingType} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 4,
                TitleColumn: 'Фактор потерь',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.LossFactor?.toFixed(0) + "%"} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 5,
                TitleColumn: 'Толщина ТСП',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.thicknessTsp?.toFixed(0) + " мкм"} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 6,
                TitleColumn: 'Толщина ТМС',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel
                            text={handleThicknessTms(item?.thicknessTsp, item?.material?.equipmentInformation?.lkmInformation?.dryResidue || 0).toFixed(0) + " мкм"}
                            classLabel={classColumn}
                        />
                    )
                }
            },
            {
                Key: 7,
                TitleColumn: 'Сухой остаток',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel
                            text={`${item?.material?.equipmentInformation?.lkmInformation?.dryResidue?.toFixed(0) || 0}%`}
                            classLabel={classColumn}
                        />
                    )
                }
            },
            {
                Key: 8,
                TitleColumn: 'Укрыв. (м²/л)',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel
                            text={item?.material && item?.material?.id ? handleHidingPowerV2(item?.material, item?.thicknessTsp, item?.lossFactor).toFixed(2) : 'Нет данных'}
                            classLabel={classColumn}
                        />
                    )
                }
            },
            {
                Key: 9,
                TitleColumn: 'Вес КГ',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.totalWeight?.toFixed(0) + ' кг'} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 10,
                TitleColumn: 'Расчетое кол-во',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.quantity?.toFixed(2) + ' л'} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 11,
                TitleColumn: 'Цена за литр',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={getCurrencyString(item?.priceForLiter, tkp?.currencyForHandle?.currencyCode)} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 12,
                TitleColumn: 'Итого',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={getCurrencyString(item?.totalPrice, tkp?.currencyForHandle?.currencyCode)} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 13,
                TitleColumn: 'Разбавитель',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel
                            text={item?.material?.equipmentInformation?.lkmInformation?.diluent?.equipment?.name.replace("Разбавитель", "") || 'Нет данных'}
                            classLabel={classColumn}
                        />
                    )
                }
            },
            {
                Key: 14,
                TitleColumn: 'Расчетное кол-во',
                invokeComponent: (item, callbackAction) => {
                    let quantityDiluent = 0;
                    if(item?.material?.equipmentInformation?.lkmInformation?.diluent?.percentFromMaterial){
                        quantityDiluent = getQuantityDiluent(item.quantity, item?.material?.equipmentInformation?.lkmInformation?.diluent?.percentFromMaterial)
                    }
                    return (
                        <TkpLabel text={quantityDiluent?.toFixed(2) + ' л'} classLabel={classColumn}/>
                    )
                }
            },
        ]
    }
    else{
        return [
            {
                Key: 1,
                TitleColumn: 'Материал',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.material?.name || 'Не заполнено'} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 2,
                TitleColumn: '% от S',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.percentFromSquare?.toFixed(0) + "%"} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 3,
                TitleColumn: 'Тип покрытия',
                invokeComponent: (item, callbackAction) => {
                    let coatingType = getCoatingTypeFromSliceV2(item, "material")
                    return (
                        <TkpLabel text={coatingType} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 4,
                TitleColumn: 'Фактор потерь',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.lossFactor?.toFixed(0) + "%"} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 5,
                TitleColumn: 'Толщина ТСП',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.thicknessTsp?.toFixed(0) + " мкм"} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 6,
                TitleColumn: 'Толщина ТМС',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel
                            text={handleThicknessTms(item?.thicknessTsp, item?.material?.equipmentInformation?.lkmInformation?.dryResidue || 0)?.toFixed(0) + " мкм"}
                            classLabel={classColumn}
                        />
                    )
                }
            },
            {
                Key: 7,
                TitleColumn: 'Сухой остаток',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel
                            text={`${item?.material?.equipmentInformation?.lkmInformation?.dryResidue?.toFixed(0) || 0}%`}
                            classLabel={classColumn}
                        />
                    )
                }
            },
            {
                Key: 8,
                TitleColumn: 'Укрыв. (м²/л)',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel
                            text={item?.material && item?.material?.id ? handleHidingPowerV2(item?.material, item?.thicknessTsp, item?.lossFactor)?.toFixed(2) : 'Нет данных'}
                            classLabel={classColumn}
                        />
                    )
                }
            },
            {
                Key: 9,
                TitleColumn: 'Вес КГ',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel
                            text={item?.totalWeight?.toFixed(0) + ' кг'}
                            classLabel={classColumn}
                        />
                    )
                }
            },
            {
                Key: 10,
                TitleColumn: 'Расчетое кол-во',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel text={item?.quantity?.toFixed(2) + ' л'} classLabel={classColumn}/>
                    )
                }
            },
            {
                Key: 11,
                TitleColumn: 'Разбавитель',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel
                            text={item?.material?.equipmentInformation?.lkmInformation?.diluent?.equipment?.name?.replace("Разбавитель", "") || 'Нет данных'}
                            classLabel={classColumn}
                        />
                    )
                }
            },
            {
                Key: 12,
                TitleColumn: 'Расчетное кол-во',
                invokeComponent: (item, callbackAction) => {
                    let quantityDiluent = 0;
                    if(item?.material?.equipmentInformation?.lkmInformation?.diluent?.percentFromMaterial){
                        quantityDiluent = getQuantityDiluent(item.quantity, item?.material?.equipmentInformation?.lkmInformation?.diluent?.percentFromMaterial)
                    }
                    return (
                        <TkpLabel text={quantityDiluent?.toFixed(2) + ' л'} classLabel={classColumn}/>
                    )
                }
            },
        ]
    }
};

export default ComponentClientSliceV2;