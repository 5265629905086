import {Accordion} from "react-bootstrap";
import Item from "./item/Item";
import Toggle from "./toggle/Toggle";
import Body from "./body/Body";
import './RemainsAccordion.css'

const RemainsAccordion = ({children}) => {
    return (
        <Accordion id={'remains__accordion'} alwaysOpen={true}>
            {children}
        </Accordion>
    );
};

RemainsAccordion.Item = Item;
RemainsAccordion.Header = Toggle;
RemainsAccordion.Body = Body;

export default RemainsAccordion;