import {Outlet} from "react-router";
import './CrmLayout.css';

const CrmLayout = () => {

    return (
        <div className="crm__wrapper">
            <main className="app-content-crm">
                <Outlet/>
            </main>
        </div>
    );
};

export default CrmLayout;
