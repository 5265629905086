import InheritInput from "../../../../../../components/UI/inputs/inheritinput/InheritInput";
import {getCurrencyString} from "../../../../../../features/formatters/forrmaterrub";
import {Form} from "react-bootstrap";
import ListIcon from "../../../../../../components/UI/icons/ListIcon";
import EditIcon from "../../../../../../components/UI/icons/EditIcon";
import CrossIcon from "../../../../../../components/UI/icons/CrossIcon";
import TableBootstrap from "../../../../../../components/tableb/TableBootstrap";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../../../hooks/useAxiosHelper";
import useLoader from "../../../../../../hooks/useLoader";

const KpMaterialsList = ({currentKp, changeKp, getKp, setItemKPForEdit, setVisibleEditModalKPItem, deleteMaterial, setIsFirstLoad}) => {
    const {show, hide} = useLoader()

   const client = useQueryClient();
    const {mutate: changePositionMaterial} = useMutation({
        mutationKey: ['current-kp', currentKp?.id],
        mutationFn: ({oldItemKey, newItemKey}) => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/kp/edit/position/material',
            queryParams: {
                oldItemKey: oldItemKey,
                newItemKey: newItemKey
            },
        }),
        onSuccess: () => {
            getKp()
            client.invalidateQueries({queryKey: ['current-kp', currentKp?.id]})
        },
        onMutate: () => show(),
        onSettled: () => hide()
    })

    const changePosition = (position) => {
        changePositionMaterial(position);
    }
    const schema = [
        {
            title: 'Наименование',
            callbackItem: item => (
                <div className="kp-detail__item_name">
                    {item?.materialType === "equipment" ? item?.equipment?.name : item?.component?.name}
                </div>
            ),
        },
        {
            title: 'Тип',
            callbackItem: item => (
                <div className="kp-detail__item_type">
                    {item?.materialType === "equipment" ? 'Готовая продукция' : 'Компонент'}
                </div>
            ),
        },
        {
            title: 'Количество',
            callbackItem: item => (
                <InheritInput
                    isNumber
                    defaultValue={item?.isCanPartialBuy ? item?.quantity : item?.quantityIncludingPackaging}
                    onChange={newValue => changeKp({...item, quantity: newValue}, true)}
                    className={'kp-detail__item_quantity'}
                    useDebounce
                    controlled
                />
            ),
        },
        {
            title: 'Единица измерения',
            callbackItem: item => (
                <div className="kp-detail__item_unit">
                    {item?.unitMeasurement?.unitName || 'Не определено'}
                </div>
            ),
        },
        {
            title: 'Цена за единицу объема',
            callbackItem: item => (
                <div className="kp-detail__item_price">
                    {getCurrencyString(item?.price || 0, currentKp?.currencyForHandle?.currencyCode)}
                </div>
            ),
        },
        {
            title: 'Скидка',
            callbackItem: item => (
                <InheritInput
                    isNumber
                    defaultValue={item?.discount || '0'}
                    onChange={newValue => changeKp({...item, discount: newValue}, true)}
                    className={'kp-detail__item_discount'}
                    useDebounce
                    controlled
                />
            ),
        },
        {
            title: 'Итого кратно таре',
            callbackItem: item => (
                <div className="kp-detail__item_total">
                    {getCurrencyString(item?.isCanPartialBuy
                            ? item?.totalPrice
                            : item?.totalPriceIncludingPackaging || 0,
                        currentKp?.currencyForHandle?.currencyCode
                    )}
                </div>
            ),
        },
        {
            title: 'Частичная продажа',
            callbackItem: item => (
                <Form.Check
                    className={'kp-detail__item_total'}
                    checked={item?.isCanPartialBuy}
                    onChange={e => changeKp({
                        ...item,
                        isCanPartialBuy: !item?.isCanPartialBuy
                    }, false)}
                />
            ),
        },
    ]
    const popoverActions = [
        {
            icon: <ListIcon classNames={'icon-extension'}/>,
            title: 'Обеспечение',
            onClick: () => {}
        },
        {
            icon: <EditIcon classNames={'icon-extension'}/>,
            title: 'Редактировать',
            onClick: (item) => setItemKPForEdit(item) || setVisibleEditModalKPItem(true)
        },
        {
            icon: <CrossIcon classNames={'icon-extension'}/>,
            title: 'Удалить',
            onClick: (item) => {
                deleteMaterial({
                    guid: item.guid,
                    kpId: currentKp?.id,
                    material: item
                })
                setIsFirstLoad(1);
            }
        },
    ]
    return (
        <TableBootstrap
            popoverActions={popoverActions}
            popoverCaption={'Действия'}
            items={currentKp?.materials}
            schema={schema}
            draggable
            keySelector={material => material?.id}
            positionSelector={material => material?.position}
            onChangePosition={position => changePosition(position)}
        />
    );
};

export default KpMaterialsList;