import {useEffect, useState} from 'react';
import './RemainsFilters.css'
import useRemainsStore from "../../store/remainsStore";
import {Form} from "react-bootstrap";
import {useInteractionBackend} from "../../../../hooks/useInteractionBackend";
import {getWarehouses} from "../../API/remainsAPI";
import RemainsAccordion from "../remainsaccordion/RemainsAccordion";
import BrandFilter from "../remainsaccordion/brandfilter/BrandFilter";
import SubmitButton from "../../UI/submitbutton/SubmitButton";
import CurrenciesFilters from "../remainsaccordion/currenciesfilter/CurrenciesFilters";
import WarehouseFilter from "../remainsaccordion/warehousefilter/WarehouseFilter";
import DryingTimeInputs from "../remainsaccordion/dryingtimeinputs/DryingTimeInputs";
import FormCoveragesFilter from "../remainsaccordion/formcoveragesfilter/FormCoveragesFilter";
import TypeNomenclatureFilter from "../remainsaccordion/typenomenclaturefilter/TypeNomenclatureFilter";
import CoatingTypeFilter from "../remainsaccordion/coatintypefilter/CoatingTypeFilter";
import ThicknessInputs from "../remainsaccordion/thicknessinputs/ThicknessInputs";
import VolumeInputs from "../remainsaccordion/volumeinputs/VolumeInputs";
import DryResidueInputs from "../remainsaccordion/dryresidueinputs/DryResidueInputs";
import SpecificWeightInputs from "../remainsaccordion/specificweightinputs/SpecificWeightInputs";
import OverlapInputs from "../remainsaccordion/overlapinputs/OverlapInputs";
import WeightInputs from "../remainsaccordion/weightinputs/WeightInputs";
import PriceForUnitInputs from "../remainsaccordion/priceforunitinputs/PriceForUnitInputs";
import DiscountInputs from "../remainsaccordion/discountinputs/DiscountInputs";
import MarginInputs from "../remainsaccordion/margininputs/MarginInputs";
import FamiliesFilter from "../remainsaccordion/familiesfilter/FamiliesFilter";

const RemainsFilters = () => {
    let [initFilters, setInitFilters] = useState([]);
    const backendFilters = useRemainsStore(state => state.filters);
    const setBackendFilters = useRemainsStore.getState().setFilters;
    const [filters, setFilters] = useState(backendFilters);
    const [position, setPosition] = useState({top: 0, left: 0});
    const [warehouses, setWarehouses] = useState([]);

    const [isFirstLoad, setIsFirstLoad] = useState(true);


    const [fetchWarehouses] = useInteractionBackend({
        type: 'axios',
        cb: getWarehouses,
        thenCb: ({data}) => setWarehouses(data)
    })

    const updateBackendFilters = () => {
        setBackendFilters(filters);
    }
    const updatePosition = (e) => {
        let rect = e.target.getBoundingClientRect();
        let x = rect.right;
        let y = rect.top + rect.height / 2;
        setPosition({left: x, top: y})
    }

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
        } else {
            const page = 1;
            const setPage = useRemainsStore.getState().setPage;
            setPage(page);
        }
    }, [backendFilters])

    useEffect(() => {
        fetchWarehouses();
        setInitFilters(backendFilters);
    }, []);

    return (
        <div className={'remains-filters'}>
            <Form
                className='w-100 remains-filters__form'
                onSubmit={e => e.preventDefault()}
                onKeyPress={e => {
                    if (e.key === 'Enter') e.preventDefault();
                }}
            >
                <div className="remains-filters__wrapper">
                    <RemainsAccordion>
                        <WarehouseFilter
                            filters={filters}
                            setFilters={setFilters}
                            updatePosition={updatePosition}
                        />
                        <RemainsAccordion.Item eventKey='0'>
                            <RemainsAccordion.Header eventKey='0'>
                                Бренды:
                            </RemainsAccordion.Header>
                            <RemainsAccordion.Body>
                                <BrandFilter
                                    filters={filters}
                                    setFilters={setFilters}
                                    updatePosition={updatePosition}
                                />
                            </RemainsAccordion.Body>
                        </RemainsAccordion.Item>
                        <RemainsAccordion.Item eventKey='1'>
                            <RemainsAccordion.Header eventKey='1'>
                                Семейство номенклатуры
                            </RemainsAccordion.Header>
                            <RemainsAccordion.Body>
                                <FamiliesFilter
                                    filters={filters}
                                    setFilters={setFilters}
                                    updatePosition={updatePosition}
                                />
                            </RemainsAccordion.Body>
                        </RemainsAccordion.Item>
                        <RemainsAccordion.Item eventKey='2'>
                            <RemainsAccordion.Header eventKey='2'>
                                Валюты:
                            </RemainsAccordion.Header>
                            <RemainsAccordion.Body>
                                <CurrenciesFilters
                                    filters={filters}
                                    setFilters={setFilters}
                                    updatePosition={updatePosition}
                                />
                            </RemainsAccordion.Body>
                        </RemainsAccordion.Item>
                        <RemainsAccordion.Item eventKey='3'>
                            <RemainsAccordion.Header eventKey='3'>
                                Вид покрытия
                            </RemainsAccordion.Header>
                            <RemainsAccordion.Body>
                                <FormCoveragesFilter
                                    filters={filters}
                                    setFilters={setFilters}
                                    updatePosition={updatePosition}
                                />
                            </RemainsAccordion.Body>
                        </RemainsAccordion.Item>
                        <RemainsAccordion.Item eventKey='4'>
                            <RemainsAccordion.Header eventKey='4'>
                                Тип номенклатуры
                            </RemainsAccordion.Header>
                            <RemainsAccordion.Body>
                                <TypeNomenclatureFilter
                                    filters={filters}
                                    setFilters={setFilters}
                                    updatePosition={updatePosition}
                                />
                            </RemainsAccordion.Body>
                        </RemainsAccordion.Item>
                        <RemainsAccordion.Item eventKey='5'>
                            <RemainsAccordion.Header eventKey='5'>
                                Тип покрытия
                            </RemainsAccordion.Header>
                            <RemainsAccordion.Body>
                                <CoatingTypeFilter
                                    filters={filters}
                                    setFilters={setFilters}
                                    updatePosition={updatePosition}
                                />
                            </RemainsAccordion.Body>
                        </RemainsAccordion.Item>
                    </RemainsAccordion>
                    <DryingTimeInputs
                        filters={filters}
                        setFilters={setFilters}
                        updatePosition={updatePosition}
                    />
                    <ThicknessInputs
                        filters={filters}
                        setFilters={setFilters}
                        updatePosition={updatePosition}
                    />
                    <VolumeInputs
                        filters={filters}
                        setFilters={setFilters}
                        updatePosition={updatePosition}
                    />
                    <DryResidueInputs
                        filters={filters}
                        setFilters={setFilters}
                        updatePosition={updatePosition}
                    />
                    <SpecificWeightInputs
                        filters={filters}
                        setFilters={setFilters}
                        updatePosition={updatePosition}
                    />
                    <OverlapInputs
                        filters={filters}
                        setFilters={setFilters}
                        updatePosition={updatePosition}
                    />
                    <WeightInputs
                        filters={filters}
                        setFilters={setFilters}
                        updatePosition={updatePosition}
                    />
                    <PriceForUnitInputs
                        filters={filters}
                        setFilters={setFilters}
                        updatePosition={updatePosition}
                    />
                    <DiscountInputs
                        filters={filters}
                        setFilters={setFilters}
                        updatePosition={updatePosition}
                    />
                    <MarginInputs
                        filters={filters}
                        setFilters={setFilters}
                        updatePosition={updatePosition}
                    />
                </div>
                <div className="remains-filters__controls">
                    <button
                        className={'remains-filters__submit-button'}
                        onClick={e => updateBackendFilters()}
                    >
                        Применить
                    </button>
                    <button
                        className={'remains-filters__clear-button'}
                        onClick={() => {
                            setFilters(initFilters);
                            setBackendFilters(initFilters);
                        }}
                    >
                        Сбросить
                    </button>
                    <SubmitButton position={position} onClick={updateBackendFilters}/>
                </div>
                {/*<Form.Check*/}
                {/*    className='mb-2 remains-filters__btn'*/}
                {/*    type='switch'*/}
                {/*    disabled*/}
                {/*    checked={filters.isAllWarehouses}*/}
                {/*    label={'Все склады: - в разработке'}*/}
                {/*    onChange={() => setFilters({...filters, isAllWarehouses: !filters.isAllWarehouses})}*/}
                {/*    bsPrefix={'remains__sw'}*/}
                {/*/>*/}
                {/*{!filters.isAllWarehouses &&*/}
                {/*    <Form.Select*/}
                {/*        multiple*/}
                {/*        className='w-auto mb-2'*/}
                {/*        defaultValue={filters.warehouses}*/}
                {/*        onChange={e => {*/}
                {/*            setFilters({...filters, warehouses: findOptions(e)});*/}
                {/*        }}*/}
                {/*        bsPrefix={'remains__select'}*/}
                {/*    >*/}
                {/*        {warehouses?.map(warehouse => (*/}
                {/*            <option key={warehouse?.guid} value={warehouse?.id}>{warehouse?.name}</option>*/}
                {/*        ))}*/}
                {/*    </Form.Select>*/}
                {/*}*/}
            </Form>
        </div>
    );
};

export default RemainsFilters;