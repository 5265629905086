import {useEffect, useState} from 'react';

const UseDebounce = ({value, delay, callback}) => {
    const [endpointValue, setEndpointValue] = useState(value)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setEndpointValue(value)
        }, delay || 500)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [value, delay])
    useEffect(() => {
        callback?.(endpointValue)
    }, [endpointValue]);

    return endpointValue;
};

export default UseDebounce;