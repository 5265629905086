export default (files, type) => {
    const RE_TYPE_FILE = new RegExp(type);
    let isValid = true;
    if (files instanceof FileList){
        for (let i = 0; i < files.length; i++) {
            if (!checkType(files[i], RE_TYPE_FILE)) {
                isValid = false;
            }
        }
    }
    else {
        isValid = checkType(files, RE_TYPE_FILE);
    }
    return isValid;
}

const checkType = (file, regexp) => {
    if ( file instanceof File) {
        return !!file.type.match(regexp);
    }
    return false;
}