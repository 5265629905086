import {useEffect, useState} from "react";
import {useInteractionBackend} from "../../../../../hooks/useInteractionBackend";
import {getByAccessor} from "../../../API/remainsAPI";
import RemainsCheckbox from "../../remainscheckbox/RemainsCheckbox";
import newArrayIds from "../../../helpers/newArrayIds";
import ClearBtn from "../../../UI/clearbtn/ClearBtn";
import {useAuth} from "../../../../../features/authprovider/AuthProvider";


const TypeNomenclatureFilter = ({filters, setFilters, updatePosition}) => {
    const [initialFilters, setInitialFilters] = useState(filters.typeNomenclatureIds);
    const [typeNomenclature, setTypeNomenclature] = useState([]);
    const {userInfo} = useAuth();

    const [fetchTypeNomenclature] = useInteractionBackend({
        type: 'axios',
        cb: getByAccessor,
        thenCb: ({data}) => setTypeNomenclature(data)
    });

    useEffect(() => {
        const token = userInfo?.accessToken || '';
        fetchTypeNomenclature({token, accessor: 'TypeNomenclature'});
    }, [])

    return (
        <div>
            {
                typeNomenclature?.map(type => {
                    return (
                        <RemainsCheckbox
                            key={type.guid}
                            checked={filters.typeNomenclatureIds.includes(type.id)}
                            onClick={(e) => {
                                let newArr = newArrayIds(type.id, filters.typeNomenclatureIds);
                                setFilters({...filters, typeNomenclatureIds: newArr});
                                updatePosition(e);
                            }}
                        >
                            {type.value}
                        </RemainsCheckbox>
                    )
                })
            }
            <ClearBtn
                isActive={filters.typeNomenclatureIds.length > 0}
                onClick={(e) => {
                    setFilters({...filters, typeNomenclatureIds: initialFilters});
                    updatePosition(e);
                }}
            />
        </div>
    );
};

export default TypeNomenclatureFilter;