import './SubmitButton.css'
import {useEffect, useRef, useState} from "react";

const SubmitButton = ({onClick, position, lifetime = 5000}) => {
    let [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isVisible, setIsVisible] = useState(true);
    const [height, setHeight] = useState(0);
    const [centeredPosition, setCenteredPosition] = useState({top: '-10000px', left: '0'});
    const refBtn = useRef(null);
    let [timerId, setTimerId] = useState(null);

    useEffect(() => {
        setHeight(refBtn.current.offsetHeight);
        setIsVisible(false)
    }, [])

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
        } else {
            setIsVisible(true);
            if (timerId) {
                clearTimeout(timerId);
            }
            timerId = setTimerId(setTimeout(() => setIsVisible(false), lifetime));
            const el = refBtn.current;
            if (el) {
                const centerY = height / 2;
                setCenteredPosition({top: position.top - centerY + 'px', left: position.left + 5 + 'px'})
            }
        }
    }, [position]);

    return (
        <div ref={refBtn} className={`submit-button${isVisible ? '' : ' submit-button_inactive'}`}
             style={centeredPosition}>
            <button
                onClick={(e) => {
                    setIsVisible(false);
                    onClick(e)
                }}
            >
                Показать
            </button>
        </div>
    );
};

export default SubmitButton;