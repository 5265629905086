import {Text, View} from "@react-pdf/renderer";
import {getCurrencyString} from "../../../../../../features/formatters/forrmaterrub";

const TkpSummaryTableDiluentPdfV2 = ({diluent, defaultStyles, visiblePrice, tkp}) => {
    return (
        <View key={diluent?.guid} style={[defaultStyles.item, defaultStyles.diluent]}>
            <View style={[defaultStyles.column, {flex: 3, paddingLeft: 10, alignItems: "flex-start"}]}>
                <Text style={defaultStyles.names}>{diluent?.equipment?.printName}</Text>
            </View>
            <View style={[defaultStyles.column, {flex: 0.5}]}>
                <Text style={defaultStyles.tableHeaderText}>{diluent?.quantityDiluentIncludingPackaging?.toFixed(1)}</Text>
            </View>
            <View style={[defaultStyles.column, {flex: 0.5}]}>
                <Text style={defaultStyles.tableHeaderText}>{diluent?.totalWeight?.toFixed(0)}</Text>
            </View>
            <View style={[defaultStyles.column, {flex: 0.5}]}>
                <Text style={defaultStyles.tableHeaderText}>{diluent?.percentFromMaterial?.toFixed(0)}%</Text>
            </View>
            {visiblePrice && <View style={[defaultStyles.column, {flex: 0.5}]}>
               <Text style={defaultStyles.tableHeaderText}>{getCurrencyString(diluent?.priceForLiter, tkp?.currencyForHandle?.currencyCode)}</Text>
            </View>}
            {visiblePrice && <View style={[defaultStyles.column, {flex: 0.5}]}>
                <Text style={defaultStyles.tableHeaderText}>{getCurrencyString(diluent?.priceForKg, tkp?.currencyForHandle?.currencyCode)}</Text>
            </View>}
            {visiblePrice && <View style={[defaultStyles.column, {flex: 1}]}>
                <Text style={defaultStyles.tableHeaderText}>{getCurrencyString(diluent?.totalPriceIncludingPackaging, tkp?.currencyForHandle?.currencyCode)}</Text>
            </View>}
        </View>
    );
};

export default TkpSummaryTableDiluentPdfV2;