import React from 'react';
import CustomItem from "../customitem/CustomItem";

const ArrayData = ({data, rules, itemClasses, textClasses}) => {
    return (
        data.map((item) => {
            let result = null;
            let key = null; //key for map Text components
            let itemKey = null;  // key for map Item components
            for (const rule of rules) {
                result = rule.text(item) || null; // Array items, or one item, or null
                key = rule?.key(item); // Array keys or one key
                itemKey = rule.itemKey(item);
                if (result) break;
            }
            return <CustomItem
                data={result}
                key={itemKey}
                itemClasses={itemClasses}
                textClasses={textClasses}
                textKey={key}
            />
        })
    );
};

export default ArrayData;