import {Form} from "react-bootstrap";
import useCaching from "../../../../../store/useCaching";
import {useEffect, useState} from "react";

const EditElementForm = ({KEY_CACHING}) => {
    const getPayload = useCaching.getState().getPayload;
    const setPayload = useCaching.getState().setPayload;
    const [item, setItem] = useState({});

    useEffect(() => {
        setItem(getPayload(KEY_CACHING))
    }, []);

    const updateItem = (newItem) => {
        setItem({...newItem});
        setPayload(KEY_CACHING, {...newItem});
    }

    return (
        <Form>
            <Form.Group>
                <Form.Label>Name:</Form.Label>
                <Form.Control
                    className={'mb-2'}
                    value={item?.name || ''}
                    onChange={e => updateItem({...item, name: e.target.value})}
                    placeholder={'Name'}
                />
                <Form.Label>Title:</Form.Label>
                <Form.Control
                    className={'mb-2'}
                    value={item?.title || ''}
                    onChange={e => updateItem({...item, title: e.target.value})}
                    placeholder={'Title'}
                />
                <Form.Label>Color:</Form.Label>
                <Form.Control
                    className={'mb-2'}
                    value={item?.color || ''}
                    onChange={e => updateItem({...item, color: e.target.value})}
                    placeholder={'Color'}
                />
                <Form.Label>Path:</Form.Label>
                <Form.Control
                    className={'mb-2'}
                    value={item?.path || ''}
                    onChange={e => updateItem({...item, path: e.target.value})}
                    placeholder={'Path'}
                />
                <Form.Check
                    className={'mb-2'}
                    checked={item?.isAdmin || false}
                    onChange={e => updateItem({...item, isAdmin: !item?.isAdmin})}
                    label={'isAdmin'}
                />
            </Form.Group>
        </Form>
    );
};

export default EditElementForm;