import './PartnerUsers.css'
import {Button, Form, InputGroup} from "react-bootstrap";
import UserIcon from "../../../../components/UI/icons/UserIcon";
import RoleIcon from "../../../../components/UI/icons/RoleIcon";
import {useNavigate} from "react-router";
import {useRef, useState} from "react";
import EmailIcon from "../../../../components/UI/icons/EmailIcon";
import ListIcon from "../../../../components/UI/icons/ListIcon";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../hooks/useAxiosHelper";
import useLoader from "../../../../hooks/useLoader";
import {useAuth} from "../../../../features/authprovider/AuthProvider";
import ModalWrapper from "../../../../hooks/usemodal/ModalWrapper";

const PartnerUsers = () => {

    const client = useQueryClient()
    const {userInfo} = useAuth()
    const modalRef = useRef(null)
    const navigate = useNavigate()
    const {show, hide} = useLoader()
    const registerFormRef = useRef(null)
    const {data: users} = useQuery({
        queryKey: ['current-partner-users', userInfo?.partnerId],
        queryFn: ({queryKey: [_, partnerId]}) => AxiosHelper.getMethod({
            urlWithoutBaseUrl: `ancorr/api/partner/get/users/${partnerId}`,
        }),
    })

    const [registerRequest, setRegisterRequest] = useState({
        userName: '',
        email: '',
        password: '',
    })
    const onAddUser = () => {

        const form = registerFormRef.current;
        if(form.checkValidity() !== false){

            addUserForPartner({request: registerRequest, partnerId: userInfo?.partnerId, createdUserId: userInfo?.id})
            clearRegisterForm()
        }
    }
    const clearRegisterForm = () => setRegisterRequest({
        userName: '',
        email: '',
        password: '',
    })

    const {mutate: addUserForPartner} = useMutation({
        mutationKey: ['current-partner-users', userInfo?.partnerId],
        mutationFn: ({request, partnerId, createdUserId}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/partner/add/user',
            queryParams: {
                partnerId: partnerId,
                createdUserId: createdUserId
            },
            data: request,
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-partner-users', userInfo?.partnerId]}),
        onMutate: () => show(),
        onSettled: () => hide()
    })
    const {mutate: removeUserFromPartner} = useMutation({
        mutationKey: ['current-partner-users', userInfo?.partnerId],
        mutationFn: ({userId, partnerId}) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/partner/delete/user',
            queryParams: {
                partnerId: partnerId,
                userId: userId
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-partner-users', userInfo?.partnerId]}),
        onMutate: () => show(),
        onSettled: () => hide()
    })
    return (
        <div className={'partner-users__wrapper'}>
            <div className="partner-users__header">
                <h3 className="partner-users__title">Мои сотрудники</h3>
            </div>
            <div className="partner-users__items">
                {users?.map((user, idx) => (
                    <div key={idx} className={'partner-users__item'}>
                        <div className="partner-users__item_info">
                            <UserIcon classNames={'icon-extension'}/>
                            <span className={'partner-users__item_name'}>{user?.userName}</span>
                        </div>
                        <div className="partner-users__item_info">
                            |
                            <span className={'partner-users__item_name'}>{user?.fio}</span>
                        </div>
                        <div className="partner-users__item_role">
                            <RoleIcon classNames={'icon-extension'}/>
                            <span className={'partner-users__item_name'}>{user?.role}</span>
                        </div>
                        <div className="partner-users__item_buttons">
                            <Button variant={'primary'} onClick={() => navigate(`/profile/users/${user?.id}`)}>Карточка
                                пользователя</Button>
                            <Button variant={'danger'} onClick={() => removeUserFromPartner({userId: user?.id, partnerId: userInfo?.partnerId})}>Удалить</Button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="partner-users__buttons">
                <Button className={'button-add-user-extension'} variant={'primary'}
                        onClick={() => modalRef?.current?.open()}>
                    Добавить сотрудника
                </Button>
            </div>
            <ModalWrapper
                ref={modalRef}
                isBeginOpen={false}
                onHide={() => clearRegisterForm()}
                buttons={[
                    <Button variant={"danger"} onClick={() => {
                        modalRef?.current?.close()
                        clearRegisterForm()

                    }}>Закрыть</Button>,
                    <Button onClick={() => {
                        modalRef?.current?.close()
                        onAddUser()
                    }}>Создать</Button>
                ]}
                caption={'Создание пользователя'}
            >
                <Form ref={registerFormRef}>
                    <InputGroup className={'w-100 mb-3'} hasValidation>
                        <InputGroup.Text><EmailIcon/></InputGroup.Text>
                        <Form.Control
                            type={"email"}
                            defaultValue={registerRequest.email}
                            placeholder={"Email"}
                            onChange={e => setRegisterRequest({...registerRequest, email: e.target.value})}
                            required
                        />
                    </InputGroup>
                    <InputGroup className={'mb-3'} hasValidation>
                        <InputGroup.Text><ListIcon/></InputGroup.Text>
                        <Form.Control
                            type={"text"}
                            defaultValue={registerRequest.userName}
                            placeholder={"Username"}
                            onChange={e => setRegisterRequest({...registerRequest, userName: e.target.value})}
                            required
                        />
                    </InputGroup>
                    <InputGroup className={'mb-3'} hasValidation>
                        <InputGroup.Text><ListIcon/></InputGroup.Text>
                        <Form.Control
                            type={"text"}
                            defaultValue={registerRequest.password}
                            placeholder={"Password"}
                            onChange={e => setRegisterRequest({...registerRequest, password: e.target.value})}
                            required
                        />
                    </InputGroup>
                </Form>
            </ModalWrapper>
        </div>
    );
};

export default PartnerUsers;