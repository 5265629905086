import {create} from "zustand";
import {devtools} from "zustand/middleware";


const useSubmitModalStore = create()(devtools((set, get) => ({
    stack: [],
    addItem: (submit, children) => {
        const stack = get().stack;
        set({stack: [...stack, {submit, children}]})
    },
    removeLast: () => {
        const stack = get().stack;
        if (stack.length === 0 || stack.length === 1) {
            set({stack: []});
        } else {
            const newStack = stack.slice(0, stack.length - 2);
            set({stack: newStack});
        }
    }
}), {name: 'submitModalStore'}));

export default useSubmitModalStore;