import './DetailBody.css'
import {Accordion} from "react-bootstrap";
const DetailBody = ({children, className}) => {
    return (
        <Accordion className={`detail__body ${className}`} defaultActiveKey={['0']} alwaysOpen>
            {children}
        </Accordion>
    );
};

export default DetailBody;