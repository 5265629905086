import './SellVariationList.css'
import {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router";
import useLoader from "../../../../hooks/useLoader";
import {AxiosHelper} from "../../../../hooks/useAxiosHelper";
import {usePaginationController} from "../../../../components/pagination/components";
import ListIcon from "../../../../components/UI/icons/ListIcon";
import EditIcon from "../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../components/UI/icons/BackCrossIcon";
import useCheckAccess from "../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../components/access/AccessDenied";
import GuidsWrapper from "../../../../components/guids/wrapper/GuidsWrapper";
import GuidsHeader from "../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../components/guids/search/GuidsSearchInput";
import {Button, Container, Form} from "react-bootstrap";
import GuidsBody from "../../../../components/guids/body/GuidsBody";
import KanbanRenderer from "../../../../modules/kanban/KanbanRenderer";
import ImageAvatar from "../../../../components/imageavatar/ImageAvatar";
import {getRubString} from "../../../../features/formatters/forrmaterrub";
import TableBootstrap from "../../../../components/tableb/TableBootstrap";
import GuidsPagination from "../../../../components/guids/pagination/GuidsPagination";
import DropdownDefault from "../../../../components/UI/selects/dropdowndefault/DropdownDefault";
import AssociatedEntities from "../../../../components/associatedentities/AssociatedEntities";
import {ReactComponent as RubleIcon} from "../../../../components/UI/icons/badge-russian-ruble.svg";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import useModals from "../../../../hooks/usemodal/useModals";
import {formatDate, oldDate, convertFromStatusInfoToFieldItem} from "../../../../features/entityhelper/EntityHelper";
import useAxiosErrorHandler from "../../../../hooks/axioserrorhandler/useAxiosErrorHandler";

const SellVariationList = ({assignedId, clientId}) => {
    const [searchValue, setSearchValue] = useState('')
    const navigate = useNavigate()
    const [isKanban, setIsKanban] = useState(false)
    const params = useParams()
    const {hideAll, show, hide} = useLoader()
    const [add, modals] = useModals()
    const handleErrorResponse = useAxiosErrorHandler()


    const [filters, setFilters] = useState({
        assignedIds: assignedId ? [assignedId] : [],
        createdIds: [],
        modifyIds: [],
        clientIds: clientId ? [clientId] : [],
        stagesIds: [],
        projectId: Number(params['id'] || 0)
    })
    const [page, setPage] = useState(1)

    const client = useQueryClient()

    const {data: items} = useQuery({
        queryKey: ['projectSell-list', page, filters, isKanban],
        queryFn: ({queryKey: [name, page, filters, isKanban]}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/project/sell/list',
            queryParams: {
                offset: 15,
                page: page,
                isKanban: isKanban
            },
            data: filters,
        }),
        onError: (error) => handleErrorResponse(error),
        onMutate: () => show(),
        onSettled: () => hide()
    })
    const {data: lanes} = useQuery({
        queryKey: ['lanes', 'project-sell'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/lanes',
            queryParams: {
                destinationEntity: 'projectSell'
            },
        }),
        onError: (error) => handleErrorResponse(error)
    })

    const {mutate: changeStageItem} = useMutation({
        mutationKey: ['projectSell-list'],
        mutationFn: (newItem) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/project/sell/update',
            data: newItem,
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['projectSell-list']}),
        onError: (error) => handleErrorResponse(error),
        onMutate: () => show(),
        onSettled: () => hide()
    })

    const {mutate: addItem} = useMutation({
        mutationKey: ['projectSell-list'],
        mutationFn: () => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/project/sell/add',
            data: {
                assignedId,
                clientId,
                projectId: Number(params['id'] || 0)
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['projectSell-list']}),
        onError: (error) => handleErrorResponse(error),
        onMutate: () => show(),
        onSettled: () => hide()
    })
    const {mutate: deleteItem} = useMutation({
        mutationKey: ['projectSell-list'],
        mutationFn: (guid) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/project/sell/delete',
            queryParams: {
                guid
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['projectSell-list']}),
        onError: (error) => handleErrorResponse(error),
        onMutate: () => show(),
        onSettled: () => hide()
    })

    const memoData = useMemo(() => items?.data?.filter(x => x?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())), [items, searchValue])


    useEffect(() => {
        document.title = "Мой клиент: Проекты продажи"
    }, [])

    const {paginationModule} = usePaginationController({
        pagesCount: items?.pages || 1,
        changePageCallback: (data) => {
            setPage(data === 0 ? 1 : data)
        }
    })

    useEffect(() => {
        if(items?.currentPage > items?.pages){
            if(items?.pages === 0)
                setPage(1)
            else
                setPage(items?.pages)
        }
    }, [items]);

    const popoverActions = [
        {
            icon: <ListIcon classNames={'cl-light-purple'}/>,
            title: 'Смена стадии',
            onClick: item => {
                let onRemoveItem = add({
                    children:
                        <Form>
                            <Form.Group>
                                <Form.Label>Выберите стадию:</Form.Label>
                                <DropdownDefault
                                    options={lanes?.map(x => {
                                        return {
                                            id: x?.id,
                                            title: x?.statusName,
                                            accessor: x?.statusValue,
                                            value: x?.statusValue,
                                        }
                                    })}
                                    currentValue={convertFromStatusInfoToFieldItem(lanes, item?.stage?.statusValue)}
                                    placeholder={'Стадия'}
                                    onChange={e => changeStageItem({
                                        ...item,
                                        stage: lanes?.find(x => x?.statusValue === e?.value)
                                    })}
                                />
                            </Form.Group>
                        </Form>,
                    size: 'lg',
                    buttons: [
                        <Button onClick={() => onRemoveItem()} variant={"danger"}>Закрыть</Button>
                    ]
                })
            }
        },
        {
            icon: <ListIcon classNames={'cl-light-purple'}/>,
            title: 'Связанные элементы',
            onClick: item => {
                let onRemoveItem = add({
                    children:
                        <AssociatedEntities
                            projectSellVariationId={item?.id}
                            clientId={clientId}
                            assignedId={assignedId}
                            offerBuy
                            appealBuy
                            dealBuy
                        />,
                    fullscreen: true,
                    buttons: [
                        <Button onClick={() => onRemoveItem()} variant={"danger"}>Закрыть</Button>
                    ]
                })
            }
        },
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item => navigate(`/projects/sell/detail/${item?.id}`)
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => deleteItem(item?.guid)
        },
    ]

    const access = useCheckAccess("sales/deal")

    if(!access){
        hideAll()
        return <AccessDenied />
    }

    const schema = [
        {
            title: 'Дата создания',
            callbackItem: item => new Date(item?.createdTimeDb)?.toLocaleDateString('ru-RU') || "Нет данных",
        },
        {
            title: 'Название',
            callbackItem: item => item?.name || "Не заполнено",
        },
        {
            title: 'Стадия',
            callbackItem: item => lanes?.find(x => x?.statusValue === item?.stage?.statusValue)?.statusName || "",
        },
    ]

    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'СПИСОК ВАРИАЦИЙ ПОКУПКИ'}/>
                <Container fluid className={'d-flex justify-content-end gap-2'}>
                    <GuidsSearchInput onChange={newValue => setSearchValue(newValue)}/>
                    <Button onClick={() => addItem()}>Добавить</Button>
                </Container>
            </GuidsHeader>
            <Form className={'w-100 flex left'}>
                <Form.Check // prettier-ignore
                    checked={isKanban}
                    type="switch"
                    label="Вид канбана"
                    onChange={e => setIsKanban(!isKanban)}
                />
            </Form>
            <GuidsBody>
                {isKanban
                    ?
                    <KanbanRenderer
                        lanes={lanes}
                        data={memoData}
                        stageKey={item => item?.stage?.statusValue}
                        sortableKey={item => new Date(item?.modifyTimeDb)}
                        addItem={addItem}
                        renderDataItemComponent={item => {
                            return (
                                <div className={'kp-card__wrapper'}>
                                    <div className="kp-card__container">
                                        <div className={'kp-card__created-time'}>
                                            {formatDate(item?.createdTimeDb)}
                                        </div>
                                    </div>
                                    <p className="m-0 p-0 kp-card__name">{item?.title}</p>
                                    <div className="m-0 p-0 text-muted kp-card__creds">
                                        <ImageAvatar urlPath={item?.createdBy?.avatar?.urlPath}/>
                                        {item?.createdBy?.lastName && item?.createdBy?.firstName ?
                                            `${item?.createdBy?.lastName} ${item?.createdBy?.firstName}`
                                            :
                                            `${item?.createdBy?.email}`
                                        }
                                        {/*{item?.createdBy?.lastName} {item?.createdBy?.firstName}*/}
                                    </div>
                                    <div className={'kp-card__footer'}>
                                        <div className={'kp-card__footer-price'}>
                                            <RubleIcon/>
                                            {getRubString(item?.totalPriceIncludingPackaging)}
                                        </div>
                                        <div className="kp-card__old-date">
                                            {oldDate(item?.modifyTimeDb)}
                                        </div>
                                    </div>
                                </div>
                            )
                        }}
                        changeLaneItem={e => changeStageItem({...e?.item, stage: e?.to})}
                        onClickItem={item => navigate(`/projects/sell/detail/${item?.id}`)}
                    />
                    :
                    <TableBootstrap
                        popoverCaption={'Действия'}
                        items={memoData}
                        schema={schema}
                        popoverActions={popoverActions}
                    />
                }
            </GuidsBody>
            {!isKanban &&
                <GuidsPagination>
                    {
                        paginationModule
                    }
                </GuidsPagination>
            }
            {modals}
        </GuidsWrapper>
    );
};

export default SellVariationList;