import './Wrapper.css'
import useClassNames from "../../../../hooks/useClassNames";

const Wrapper = ({children, classes = []}) => {

    const wrapperClasses = useClassNames({
        cls: 'stretch-line',
        additional: classes
    })

    return (
        <div className={wrapperClasses}>
            {children}
        </div>
    );
};

export default Wrapper;