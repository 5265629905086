import {useState} from 'react';
import ModalWrapper from "./ModalWrapper";
import {generateGuid} from "../../features/guidgenerator/GuidGenerator";

const UseModals = () => {

    const [modals, setModals] = useState([])

    const onRemoveItem = (guid) => {
        setModals(prev => prev?.filter(x => x?.key !== guid))
    }
    const add = ({children, caption = 'Модальное окно', buttons = [], fullscreen = false, size = 'lg',  onHide}) => {
        let newGuid = generateGuid()

        setModals(prev => [...prev, {
            key: newGuid,
            modal:
                <ModalWrapper
                    key={newGuid}
                    guid={newGuid}
                    fullscreen={fullscreen}
                    size={size}
                    caption={caption}
                    onHide={onHide}
                    buttons={buttons}
                >
                    {children}
                </ModalWrapper>}])

        return () => onRemoveItem(newGuid)
    }
    return [
        add,
        modals?.map(modalItem => modalItem?.modal)
    ]

};

export default UseModals;