
const RequisitesIcon = ({classNames}) => {
    return (
        <svg className={classNames} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33333 2C2.97971 2 2.64057 2.14048 2.39052 2.39052C2.14048 2.64057 2 2.97971 2 3.33333M12.6667 2C13.0203 2 13.3594 2.14048 13.6095 2.39052C13.8595 2.64057 14 2.97971 14 3.33333M14 12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14M3.33333 14C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667M6 2H6.66667M6 14H6.66667M9.33333 2H10M9.33333 14H10M2 6V6.66667M14 6V6.66667M2 9.33333V10M14 9.33333V10M4.66667 5.33333H10M4.66667 8H11.3333M4.66667 10.6667H8.66667" stroke="#6C7275" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default RequisitesIcon;