import {Form, InputGroup, Stack} from "react-bootstrap";
import MultipleDropdownDefault from "../../../components/UI/selects/multipledropdowndefault/MultipleDropdownDefault";
import DropdownDefault from "../../../components/UI/selects/dropdowndefault/DropdownDefault";
import ListIcon from "../../../components/UI/icons/ListIcon";
import {
    converterEntityToOptionOrReverse,
    getOptionsFromArrayEntities
} from "../../../features/entityhelper/EntityHelper";
import {getRubString} from "../../../features/formatters/forrmaterrub";
import useModals from "../../../hooks/usemodal/useModals";
import EntitiesSelector from "../../entitiesselector/EntitiesSelector";
import NumericInput from "../../../components/UI/inputs/numeric/NumericInput";
import PhotoEntityRenderer from "../../../components/files/photo/PhotoEntityRenderer";
import {TextField} from "@mui/material";
import CompanyEditInputModule from "../../clients/editinputs/CompanyEditInputModule";
import AddressEditInputModule from "../../address/editinputs/AddressEditInputModule";
import ContactEditInputModule from "../../clients/editinputs/ContactEditInputModule";
import ClientsEditInputModule from "../../clients/editinputs/ClientsEditInputModule";


const Input = ({
                   field,
                   value,
                   keys,
                   handleChangeValue,
                   dependencyEntity,
                   entities
}) => {


    const [add, modals] = useModals()

    if (field?.type === 'text') {
        return (
            <Form.Group className={'mb-3'}>
                <TextField
                    style={{width: 400}}
                    defaultValue={value}
                    label={field?.label}
                    size={'small'}
                    onChange={e => handleChangeValue(e, field?.accessor, keys)}
                />
            </Form.Group>
        )
    } else if (field?.type === 'textarea') {
        return (
            <Form.Group className={'mb-3'}>
                <TextField
                    style={{width: 400}}
                    defaultValue={value}
                    multiline
                    maxRows={10}
                    size={'small'}
                    label={field?.label}
                    onChange={e => {
                        handleChangeValue(e, field?.accessor, keys)
                    }}
                />
            </Form.Group>
        )
    } else if (field?.type === 'address') {
        return (
            <Form.Group className={'mb-3'}>
                <AddressEditInputModule
                    defaultValue={value}
                    label={field?.label}
                    onChange={newValue => handleChangeValue(newValue, field?.accessor, keys, field?.type)}
                />
            </Form.Group>
        )
    } else if (field?.type === 'crm_client') {
        return (
            <Form.Group className={'mb-3'}>
                <ClientsEditInputModule
                    controlled
                    defaultValue={value}
                    label={field?.label}
                    onChange={newValue => handleChangeValue(newValue, field?.accessor, keys, field?.type)}
                />
            </Form.Group>
        )
    } else if (field?.type === 'crm_company') {
        return (
            <Form.Group className={'mb-3'}>
                <CompanyEditInputModule
                    controlled
                    defaultValue={value}
                    label={field?.label}
                    onChange={newValue => handleChangeValue(newValue, field?.accessor, keys, field?.type)}
                />
            </Form.Group>
        )
    } else if (field?.type === 'crm_contact') {
        return (
            <Form.Group className={'mb-3'}>
                <ContactEditInputModule
                    controlled
                    defaultValue={value}
                    label={field?.label}
                    onChange={newValue => handleChangeValue(newValue, field?.accessor, keys, field?.type)}
                />
            </Form.Group>
        )
    } else if (field?.type === 'number') {
        return (
            <Form.Group className={'mb-3'}>
                <NumericInput
                    defaultValue={value}
                    suffics={field?.suffics}

                    onChange={newValue => handleChangeValue(newValue, field?.accessor, keys, field?.type)}
                    label={field?.label}
                />
            </Form.Group>
        )
    } else if (field?.type === 'enum') {
        return field?.isMultiple
            ?
            <Form.Group className={'mb-3'}>
                <MultipleDropdownDefault
                    placeholder={field?.label}
                    options={field?.items || []}
                    currentValues={value}
                    onChange={newValue => handleChangeValue(newValue, field?.accessor, keys, field?.type)}
                />
            </Form.Group>
            :
            <Form.Group className={'mb-3'}>
                <DropdownDefault
                    options={field?.items || []}
                    currentValue={value}
                    onChange={newValue => handleChangeValue(newValue, field?.accessor, keys, field?.type)}
                    placeholder={field?.label}
                />
            </Form.Group>
    } else if (field?.type === 'entity') {
        return (
            <Form.Group className={'mb-3'}>
                <Form.Label>{field?.label}</Form.Label>
                <DropdownDefault
                    options={getOptionsFromArrayEntities({
                        entities: entities?.find(x => x?.method === field?.methodGettingEntities)?.items || [],
                        accessorName: 'brand',
                        valueCallback: itemC => dependencyEntity?.find(x => x?.method === field?.methodGettingEntities)?.valueSelector?.(itemC),
                        titleCallback: itemC => dependencyEntity?.find(x => x?.method === field?.methodGettingEntities)?.titleSelector?.(itemC) || '',
                        idCallback: itemC => dependencyEntity?.find(x => x?.method === field?.methodGettingEntities)?.keySelector?.(itemC)
                    }) || []}
                    currentValue={converterEntityToOptionOrReverse({
                        endpoint: 'option',
                        accessorName: 'brand',
                        valueCallback: item => dependencyEntity?.find(x => x?.method === field?.methodGettingEntities)?.valueSelector?.(item),
                        titleCallback: item => dependencyEntity?.find(x => x?.method === field?.methodGettingEntities)?.titleSelector?.(item) || '',
                        idCallback: item => dependencyEntity?.find(x => x?.method === field?.methodGettingEntities)?.keySelector?.(item),
                        searchPropertyForArray: value?.id || null,
                        arrayEntities: entities?.find(x => x?.method === field?.methodGettingEntities)?.items || []
                    })}
                    onChange={newValue => handleChangeValue(
                        converterEntityToOptionOrReverse({
                            endpoint: 'entity',
                            arrayEntities: entities?.find(x => x?.method === field?.methodGettingEntities)?.items || [],
                            searchPropertyForArray: newValue?.value
                        }), field?.accessor, keys, field?.type)}
                />
            </Form.Group>
        )
    } else if (field?.type === 'selector') {
        return (
            <div className={'field__info pointer'} >
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'} onClick={() => {
                    let onRemoveItem = add({
                        children:
                            <EntitiesSelector
                                entities={entities?.find(x => x?.method === field?.methodGettingEntities)?.items || []}
                                nameSelector={item => dependencyEntity?.find(x => x?.method === field?.methodGettingEntities)?.titleSelector?.(item) || ''}
                                keySelector={item => dependencyEntity?.find(x => x?.method === field?.methodGettingEntities)?.keySelector?.(item) || ''}
                                onClickItem={item => {
                                    if (item?.isSelect)
                                        handleChangeValue(null, field?.accessor, keys, field?.type)
                                    else
                                        handleChangeValue(item?.item, field?.accessor, keys, field?.type)
                                    onRemoveItem()
                                }}
                                selectedEntities={[value?.id]}
                            />
                    })
                }}>
                    <ListIcon/>
                    <span
                        className={'field__text'}>{dependencyEntity?.find(x => x?.method === field?.methodGettingEntities)?.titleSelector?.(value) || 'Не заполнено'}</span>
                </div>
                {modals}
            </div>

        )
    } else if (field?.type === 'date') {
        return (
            <Form.Group className={'mb-3'}>
                <Form.Label>{field?.label}</Form.Label>
                <InputGroup>
                    <InputGroup.Text><ListIcon/></InputGroup.Text>
                    <Form.Control type={'date'} value={value}
                                  onChange={e => handleChangeValue(e, field?.accessor, keys)}/>
                </InputGroup>
            </Form.Group>
        )
    } else if (field?.type === 'boolean') {
        return (
            <Form.Group className={'mb-3'}>
                <Form.Check
                    type={'checkbox'}
                    checked={value}
                    onChange={e => handleChangeValue(e, field?.accessor, keys)}
                    label={'Активное'}
                />
            </Form.Group>
        )
    } else if (field?.type === 'label') {
        return (
            <div className={'field__info'}>
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'}>
                    <ListIcon/>
                    <span className={'field__text'}>{field?.isMoney ? getRubString(value) : value}</span>
                </div>
            </div>
        )
    } else if (field?.type === 'file' || field?.type === 'photo') {
        return (
            <Stack className={'mb-3'}>
                <label className={'field__label'}>{field?.label}</label>
                <PhotoEntityRenderer
                    isMultiple={field?.isMultiple}
                    isEditable
                    entity={value}
                    onChange={newValue => handleChangeValue(newValue, field?.accessor, keys, field?.type)}
                />
            </Stack>


            /*<Form.Group className={'mb-3'}>
                <Form.Label>{field?.label}</Form.Label>
                <Form.Control
                    type={'file'}
                    onChange={e => handleChangeValue(e, field?.accessor, keys)}
                />
            </Form.Group>*/
        )
    }
};

export default Input;