import {useState} from 'react';
import useDebouncingFunction from "../../../../hooks/debouncingfunction/useDebouncingFunction";
import {TextField} from "@mui/material";
import useControlledValue from "../../../../hooks/controlled/useControlledValue";

const TextInput = ({
                       defaultValue = '',
                       controlled = false,
                       onChange,
                       suffics = '',
                       placeholder = '',
                       label = '',
                       multiline = false,
                       maxRows = 7,
                       minRows = 1,
                       useDebounce = false,
                       variant = 'standard',
                       sx = { width: 300 }
                   }) => {
    const [value, setValue] = useControlledValue({defaultValue, controlled, initialStateValue: ''})
    const [isFocus, setIsFocus] = useState(false)


    const updateValue = (newValue) => {
        onChange?.(newValue)
    }

    const debounceUpdateValue = useDebouncingFunction(updateValue, 1200)

    return (
        <TextField
            variant={variant}
            label={label}
            placeholder={placeholder}
            onFocus={e => setIsFocus(true)}
            onBlur={e => setIsFocus(false)}
            multiline={multiline}
            minRows={minRows}
            maxRows={maxRows}
            onChange={e => {
                setValue(e.target.value)

                if(useDebounce)
                    debounceUpdateValue(e.target.value)
                else
                    updateValue(e.target.value)
            }}
            value={value + (!isFocus ? suffics : '')}
            style={sx}
            size={"small"}
        />
    );
};

export default TextInput;