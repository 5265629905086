import RemainsCheckbox from "../../remainscheckbox/RemainsCheckbox";
import {useState} from "react";
import ClearBtn from "../../../UI/clearbtn/ClearBtn";


const WarehouseFilter = ({filters, setFilters, updatePosition}) => {
    const [initialFilters, setInitialFilters] = useState({
        notInStock: filters.notInStock,
        isSupply: filters.isSupply,
    });

    return (
        <div>
            <RemainsCheckbox
                checked={filters.notInStock}
                onClick={(e) => {
                    setFilters({...filters, notInStock: !filters.notInStock});
                    updatePosition(e);
                }}
            >
                {'Показать отсутствующие'}
            </RemainsCheckbox>
            <RemainsCheckbox
                checked={filters.isSupply}
                onClick={(e) => {
                    setFilters({...filters, isSupply: !filters.isSupply});
                    updatePosition(e);
                }}
            >
                {'Будущие поступления'}
            </RemainsCheckbox>
        </div>
    );
};

export default WarehouseFilter;