import axios from "axios";
import useLoader from "./useLoader";
import {BaseUrl} from "../features/databaseinfo/BaseUrl";

const handleUrlWithQueryParams = (urlWithoutBaseUrl = '', queryParams = null) => {

    let endpointUrl = `${BaseUrl}/${urlWithoutBaseUrl}`

    if(queryParams){
        Object.keys(queryParams)?.forEach((key, idx) => {
            if(idx === 0)
                endpointUrl += `?${key}=${queryParams[key]}&`
            else
                endpointUrl += `${key}=${queryParams[key]}&`
        })
    }

    return endpointUrl
}

export class AxiosHelper{
    static getMethod ({urlWithoutBaseUrl = '', queryParams = null, headers, otherConfig}) {
        return new Promise((resolve, reject) => {
            axios.get(handleUrlWithQueryParams(urlWithoutBaseUrl, queryParams), {
                headers: {
                    ...headers
                },
                ...otherConfig
            })
                .then(({data}) => resolve(data))
                .catch(error => reject(error))
        })
    }
    static postMethod ({urlWithoutBaseUrl = '', queryParams = null, data, headers, otherConfig}) {
        return new Promise((resolve, reject) => {
            axios.post(handleUrlWithQueryParams(urlWithoutBaseUrl, queryParams), JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
                ...otherConfig
            })
                .then(({data}) => resolve(data))
                .catch(error => reject(error))
        })
    }
    static postFiles ({urlWithoutBaseUrl = '', queryParams = null, data, headers, otherConfig}) {
        return new Promise((resolve, reject) => {
            axios.post(handleUrlWithQueryParams(urlWithoutBaseUrl, queryParams), data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...headers
                },
                ...otherConfig
            })
                .then(({data}) => resolve(data))
                .catch(error => reject(error))
        })
    }
    static putMethod ({urlWithoutBaseUrl = '', queryParams = null, data, headers, otherConfig})  {
        return new Promise((resolve, reject) => {
            axios.put(handleUrlWithQueryParams(urlWithoutBaseUrl, queryParams), JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
                ...otherConfig
            })
                .then(({data}) => resolve(data))
                .catch(error => reject(error))
        })
    }
    static patchMethod ({urlWithoutBaseUrl = '', queryParams = null, data, headers, otherConfig})  {
        return new Promise((resolve, reject) => {
            axios.patch(handleUrlWithQueryParams(urlWithoutBaseUrl, queryParams), JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
                ...otherConfig
            })
                .then(({data}) => resolve(data))
                .catch(error => reject(error))
        })
    }
    static getFileAndDownload ({urlWithoutBaseUrl = '', filename, queryParams = null, headers, otherConfig}) {
        return new Promise((resolve, reject) => {
            axios.get(handleUrlWithQueryParams(urlWithoutBaseUrl, queryParams), {
                responseType: 'blob',
                headers: {
                    ...headers
                },
                ...otherConfig
            })
                .then(({data}) => {

                    const href = URL.createObjectURL(data);

                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                    resolve(data)
                })
                .catch(error => reject(error))
        })
    }
    static getFile ({urlWithoutBaseUrl = '', queryParams = null, headers, otherConfig}) {
        return new Promise((resolve, reject) => {
            axios.get(handleUrlWithQueryParams(urlWithoutBaseUrl, queryParams), {
                responseType: 'blob',
                headers: {
                    ...headers
                },
                ...otherConfig
            })
                .then(({data}) => resolve(data))
                .catch(error => reject(error))
        })
    }
    static async deleteMethod ({urlWithoutBaseUrl = '', queryParams = null, headers, otherConfig}) {
        return new Promise((resolve, reject) => {
            axios.delete(handleUrlWithQueryParams(urlWithoutBaseUrl, queryParams), {
                headers: {
                    ...headers
                },
                ...otherConfig
            })
                .then(({data}) => resolve(data))
                .catch(error => reject(error))
        })
    }
}

const UseAxiosHelper = ({}) => {

    const {show, hide} = useLoader()
    const postMethod = ({urlWithoutBaseUrl = '', queryParams = null, data, headers, otherConfig, successCallback, errorCallback, finallyCallback, useLoader = true}) => {
        if(useLoader)
            show();

        axios.post(handleUrlWithQueryParams(urlWithoutBaseUrl, queryParams), JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
            ...otherConfig
        })
            .then(res => successCallback?.(res))
            .catch(res => errorCallback?.(res))
            .finally(res => {
                finallyCallback?.(res)
                if(useLoader)
                    hide()
            })
    }
    const putMethod = ({urlWithoutBaseUrl = '', queryParams = null, data, headers, otherConfig, successCallback, errorCallback, finallyCallback, useLoader = true}) => {
        if(useLoader)
            show();

        axios.post(handleUrlWithQueryParams(urlWithoutBaseUrl, queryParams), JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
            ...otherConfig
        })
            .then(res => successCallback?.(res))
            .catch(res => errorCallback?.(res))
            .finally(res => {
                finallyCallback?.(res)
                if(useLoader)
                    hide()
            })
    }
    const patchMethod = ({urlWithoutBaseUrl = '', queryParams = null, data, headers, otherConfig, successCallback, errorCallback, finallyCallback, useLoader = true}) => {

        if(useLoader)
            show();

        axios.post(handleUrlWithQueryParams(urlWithoutBaseUrl, queryParams), JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
            ...otherConfig
        })
            .then(res => successCallback?.(res))
            .catch(res => errorCallback?.(res))
            .finally(res => {
                finallyCallback?.(res)
                if(useLoader)
                    hide()
            })
    }
    const getMethod = ({urlWithoutBaseUrl = '', queryParams = null, headers, otherConfig, successCallback, errorCallback, finallyCallback, useLoader = true}) => {

        if(useLoader)
            show();

        axios.get(handleUrlWithQueryParams(urlWithoutBaseUrl, queryParams), {
            headers: {
                ...headers
            },
            ...otherConfig
        })
            .then(res => successCallback?.(res))
            .catch(res => errorCallback?.(res))
            .finally(res => {
                finallyCallback?.(res)
                if(useLoader)
                    hide()
            })
    }
    const getFileAndDownload = ({urlWithoutBaseUrl = '', filename, queryParams = null, headers, otherConfig, successCallback, errorCallback, finallyCallback, useLoader = true}) => {

        if(useLoader)
            show();

        axios.get(handleUrlWithQueryParams(urlWithoutBaseUrl, queryParams), {
            responseType: 'blob',
            headers: {
                ...headers
            },
            ...otherConfig
        })
            .then(res => {

                const href = URL.createObjectURL(res.data);

                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);

                successCallback?.(res)
            })
            .catch(res => errorCallback?.(res))
            .finally(res => {
                finallyCallback?.(res)

                if(useLoader)
                    hide()
            })
    }
    const getFile = ({urlWithoutBaseUrl = '', queryParams = null, headers, otherConfig, successCallback, errorCallback, finallyCallback, useLoader = true}) => {

        if(useLoader)
            show();

        axios.get(handleUrlWithQueryParams(urlWithoutBaseUrl, queryParams), {
            responseType: 'blob',
            headers: {
                ...headers
            },
            ...otherConfig
        })
            .then(res => successCallback?.(res))
            .catch(res => errorCallback?.(res))
            .finally(res => {
                finallyCallback?.(res)
                if(useLoader)
                    hide()
            })
    }
    const deleteMethod = ({urlWithoutBaseUrl = '', queryParams = null, headers, otherConfig, successCallback, errorCallback, finallyCallback, useLoader = true}) => {
        if(useLoader)
            show();
        axios.delete(handleUrlWithQueryParams(urlWithoutBaseUrl, queryParams), {
            headers: {
                ...headers
            },
            ...otherConfig
        })
            .then(res => successCallback?.(res))
            .catch(res => errorCallback?.(res))
            .finally(res => {
                finallyCallback?.(res)
                if(useLoader)
                    hide()
            })
    }

    return {
        postMethod,
        getMethod,
        deleteMethod,
        putMethod,
        patchMethod,
        getFileAndDownload,
        getFile
    }
};

export default UseAxiosHelper;