import EntitiesSelector from "../../../../../modules/entitiesselector/EntitiesSelector";
import useAxiosHelper from "../../../../../hooks/useAxiosHelper";
import useCaching from "../../../../../store/useCaching";
import {useEffect, useState} from "react";


const PartnersList = ({KEY_CACHING, updateItems}) => {
    const [partners, setPartners] = useState([]);
    const {getMethod, postMethod, deleteMethod} = useAxiosHelper({});
    const getPayload = useCaching.getState().getPayload;
    const setPayload = useCaching.getState().setPayload;
    const [itemStore, setItemStore] = useState({});

    useEffect(() => {
        setItemStore(getPayload(KEY_CACHING));
        getMethod({
            urlWithoutBaseUrl: 'ancorr/api/menu/get/partners',
            successCallback: ({data}) => setPartners(data)
        })
    }, []);

    const updateItem = (newItem) => {
        setItemStore({...newItem});
        setPayload(KEY_CACHING, {...newItem});
    }

    return (
        <EntitiesSelector
            nameSelector={item => item?.name}
            keySelector={item => item?.guid}
            entities={partners}
            onClickItem={item => {
                if (!item?.isSelect)
                    postMethod({
                        urlWithoutBaseUrl: 'ancorr/api/menu/add/partner',
                        queryParams: {
                            menuId: itemStore?.id,
                            partnerId: item?.item?.id
                        },
                        successCallback: () => updateItem({
                            ...itemStore,
                            partnersAccess: [...itemStore?.partnersAccess, item?.item]
                        }) || updateItems()
                    })
                else
                    deleteMethod({
                        urlWithoutBaseUrl: 'ancorr/api/menu/delete/partner',
                        queryParams: {
                            menuGuid: itemStore?.guid,
                            partnerGuid: item?.item?.guid
                        },
                        successCallback: () => updateItem({
                            ...itemStore,
                            partnersAccess: itemStore?.partnersAccess?.filter(x => x?.guid !== item?.item?.guid)
                        }) || updateItems()
                    })
            }}
            selectedEntities={itemStore?.partnersAccess?.reduce((acc, item) => [...acc, item?.guid], []) || []}
        />
    );
};

export default PartnersList;