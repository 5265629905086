import axios from "axios";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";

export class KPService {
    static getKP({id}) {
        return axios.get(`${BaseUrl}/ancorr/api/kp/get/kp/${id}`);
    }
    static updateKP ({kp, bxInfo}) {
        return axios.post(
            `${BaseUrl}/ancorr/api/kp/edit/kp`,
            JSON.stringify({...kp, ...bxInfo}),
            {headers: {'Content-Type': 'application/json'}}
        )
    }
    static deleteKP ({id}) {
        return axios.delete(`${BaseUrl}/ancorr/api/kp/delete/${id}`);
    }

    static addElement ({kpId, event}) {
        return axios.post(
            `${BaseUrl}/ancorr/api/kp/edit/add/element?kpId=${kpId}&typeElement=${event?.type}&elementId=${event?.element?.id}`
        )
    }
    static editElement ({itemKPForEdit}) {
        return axios.post(
            `${BaseUrl}/ancorr/api/kp/edit/element`,
            JSON.stringify(itemKPForEdit),
            {headers: {'Content-Type': 'application/json'}}
        );
    }
    static deleteElement ({guid, kpId}) {
        return axios.post(`${BaseUrl}/ancorr/api/kp/edit/delete/element?guid=${guid}&kpId=${kpId}`);
    }
}