import {AccordionSummary, styled} from "@mui/material";
import {ArrowDownward} from "@mui/icons-material";

const AccordionSummaryCourse = styled((props) => (
    <AccordionSummary
        expandIcon={<ArrowDownward/>}
        {...props}
    />
))(({theme}) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
        marginLeft: theme.spacing(1),
    }
}));

export default AccordionSummaryCourse;