import {create} from 'zustand'

const useCaching = create((set, get) => ({
    payloads: {},
    setPayload: (key, payload) => set((state) => ({ payloads: {...state.payloads,  [key]: payload}})),
    getPayload: (key) => get()?.payloads?.[key],
    removePayload: (key) => set((state) => {
        let newObj = {...state.payloads}
        delete newObj[key]

        return {payloads: newObj }
    })
}))

export default useCaching;