import React from 'react';
import './TableHead.css'
const TableHead = ({children, classNames = ''}) => {
    return (
        <div  className={`table__head ${classNames && classNames}`}>
            {children}
        </div>
    );
};

export default TableHead;