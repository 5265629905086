import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import ListIcon from "../../../../components/UI/icons/ListIcon";
import InheritInput from "../../../../components/UI/inputs/inheritinput/InheritInput";
import {Button} from "react-bootstrap";
import {useRef} from "react";
import TkpEditSystemModule from "../../../../modules/tkp/system/TkpEditSystemModule";

const TkpDetailSystem = ({children, system, changeSystemTkp, indexSystem}) => {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: system?.id})

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }
    const editSystemModuleRef = useRef(null)

    return (
        <div className={'tkp-system__container'} style={style} ref={setNodeRef}>
            <div className={'tkp-system__header'}>
                <div className={'tkp-system__container_info'}>
                    <div className={'tkp-system__header_draggable'} {...attributes} {...listeners}>
                        <ListIcon/>
                    </div>
                    <div className={'tkp-system__header_square'}>
                        <InheritInput
                            defaultValue={system?.square}
                            useDebounce
                            controlled
                            isNumber
                            suffics={' м²'}
                            onChange={newValue => changeSystemTkp({
                                ...system,
                                square: newValue
                            }, true)}
                        />
                    </div>
                    <div className={'tkp-system__header_preview'}>
                        <b>Система окраски №{indexSystem + 1}</b> -
                        <InheritInput
                            defaultValue={system?.title}
                            useDebounce
                            controlled
                            placeholder={'наименование'}
                            onChange={newValue => changeSystemTkp({
                                ...system,
                                title: newValue
                            }, true)}
                        />
                    </div>
                </div>
                <div className={'tkp-system__container_selects'}>
                    <div
                        className={'tkp-system__selects_item'}>{system?.background?.value || 'Не заполнено'}</div>
                    <div className={'tkp-system__selects_item'}> /</div>
                    <div
                        className={'tkp-system__selects_item'}>{system?.roughness?.value || 'Не заполнено'}</div>
                    <div className={'tkp-system__selects_item'}>
                        <Button
                            className={'w-auto'}
                            variant={"outline-primary"}
                            onClick={() => editSystemModuleRef?.current?.openForm(system)}
                        >
                            Настроить
                        </Button>
                    </div>
                </div>
            </div>
            {children}
            <TkpEditSystemModule ref={editSystemModuleRef}/>
        </div>
    );
};

export default TkpDetailSystem;