import './BillingDetailTS.css'

const BillingDetailTs = () => {
    return (
        <div>

        </div>
    );
};

export default BillingDetailTs;