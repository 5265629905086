export default [
    {
        title: 'ID',
        callbackItem: item => item?.id,
    },
    {
        title: 'Полное наименование',
        callbackItem: item => item?.fullName,
    },
    {
        title: 'Короткое наименование',
        callbackItem: item => item?.shortName,
    },
]