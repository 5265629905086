import CrmLayout from "../shared/CrmLayout";
import Main from "../pages/main/Main";
import Test from "../pages/testpage/Test";
import Logistics from "../pages/logistics/Logistics";
import ClientList from "../pages/clients/modules/list/ClientList";
import ClientDetail from "../pages/clients/modules/detail/ClientDetail";
import StatusGroupList from "../pages/guids/statuses/modules/list/StatusGroupList";
import UserDetailCard from "../pages/guids/users/modules/detail/UserDetailCard";
import UserList from "../pages/guids/users/modules/list/UserList";
import BaseLayout from "../shared/baselayout/BaseLayout";
import Sales from "../pages/sales/Sales";
import Profile from "../pages/profile/Profile";
import Guids from "../pages/guids/main/Guids";
import GuidsBrandsDetail from "../pages/guids/brands/modules/detail/GuidsBrandsDetail";
import GuidsComponentsDetail from "../pages/guids/component/modules/detail/GuidsComponentsDetail";
import GuidsCurrencyDetail from "../pages/guids/currency/modules/detail/GuidsCurrencyDetail";
import GuidsWarehousesDetail from "../pages/guids/warehouses/modules/detail/GuidsWarehousesDetail";
import GuidsNomenclaturesDetail from "../pages/guids/nomeclatures/modules/detail/GuidsNomenclaturesDetail";
import GuidsSuppliersDetail from "../pages/guids/suppliers/modules/detail/GuidsSuppliersDetail";
import GuidsFieldsList from "../pages/guids/fileds/modules/list/GuidsFieldsList";
import GuidsFieldsDetail from "../pages/guids/fileds/modules/detail/GuidsFieldsDetail";
import GuidsEquipmentDetail from "../pages/guids/equipment/modules/detail/GuidsEquipmentDetail";
import GuidsPackagingDetail from "../pages/guids/packaging/modules/detail/GuidsPackagingDetail";
import KpList from "../pages/kp/components/list/KpList";
import TkpDetailN from "../pages/newTkp/detail/TkpDetailN";
import TkpListN from "../pages/newTkp/list/TkpListN";
import RemainsNew from "../pages/remainsnew/RemainsNew";
import KpPreview from "../pages/kp/components/kppreview/KpPreview";
import BillingsDetail from "../pages/sales/billings/detail/BillingsDetail";
import BillingsList from "../pages/sales/billings/list/BillingsList";
import SpecificationList from "../pages/sales/specifications/list/SpecificationList";
import SpecificationDetail from "../pages/sales/specifications/detail/SpecificationDetail";
import DealsList from "../pages/sales/deals/list/DealsList";
import DealsDetail from "../pages/sales/deals/detail/DealsDetail";
import ContractsDetail from "../pages/sales/contracts/detail/ContractsDetail";
import ContractsList from "../pages/sales/contracts/list/ContractsList";
import Materials from "../pages/guids/materials/Materials";
import MenuItemList from "../pages/guids/menu/list/MenuItemList";
import KPDetailNew from "../pages/kp/components/detailnew/KPDetailNew";
import Management from "../pages/management/Management";
import ClientsTs from "../pages/management/clients/list/ClientsTS";
import ClientDetailTS from "../pages/management/clients/detail/ClientDetailTS";
import AppealsList from "../pages/management/appeals/list/AppealsList";
import DealListTs from "../pages/management/deals/list/DealListTS";
import ProjectList from "../pages/projects/main/list/ProjectList";
import ProjectDetail from "../pages/projects/main/detail/ProjectDetail";
import OffersList from "../pages/management/offers/list/OffersList";
import ContractDetailTs from "../pages/management/contract/detail/ContractDetailTS";
import ContractListTs from "../pages/management/contract/list/ContractListTS";
import BillingListTs from "../pages/management/billing/list/BillingListTS";
import BillingDetailTs from "../pages/management/billing/detail/BillingDetailTS";
import KpDetailTs from "../pages/management/kp/detail/KPDetailTS";
import KpListTs from "../pages/management/kp/list/KPListTS";
import RentVariationDetail from "../pages/projects/rentvariation/detail/RentVariationDetail";
import SellVariationDetail from "../pages/projects/sellvariation/detail/SellVariationDetail";
import RentOfferDetail from "../pages/management/offers/detail/rentofferdetail/rentOfferDetail";
import BuyOfferDetail from "../pages/management/offers/detail/buyofferdetail/BuyOfferDetail";
import BuyDealDetail from "../pages/management/deals/detail/buydealdetail/BuyDealDetail";
import BuyAppealDetail from "../pages/management/appeals/detail/buyappealdetail/BuyAppealDetail";
import RentAppealDetail from "../pages/management/appeals/detail/rentappealdetail/RentAppealDetail";
import RentDealDetail from "../pages/management/deals/detail/rentdealdetail/RentDealDetail";
import {ProtectedRoute, useAuth} from "../features/authprovider/AuthProvider";
import Login from "../pages/auth/login/Login";
import TkpClientPreviewV2 from "../pages/newTkp/components/client/web/TkpClientPreviewV2";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

export const Routes = () => {
    const {accessToken} = useAuth()
    const router = createBrowserRouter([
        ...routesForPublic,
        ...(!accessToken ? routesForNotAuthenticatedOnly : []),
        ...routesForAuthenticatedOnly,
    ]);
    return <RouterProvider router={router} />;
}

export default Routes;

const routesForPublic = [
    {
        path: 'crm',
        element: <CrmLayout />,
        children: [
            {
                path: 'preview/v2/:guid',
                element: <TkpClientPreviewV2 />
            },
        ],
    },
]
const routesForAuthenticatedOnly  = [
    {
        path: '',
        element: <ProtectedRoute/>,
        children: [
            {
                path: '',
                element: <BaseLayout/>,
                children: [
                    {
                        path: '',
                        element: <Main />
                    },
                    {
                        path: '/profile',
                        element: <Profile />
                    },
                    {
                        path: '/profile/users/:id',
                        element: <UserDetailCard />
                    },
                    {
                        path: '/profile/warehouses/detail/:id',
                        element: <GuidsWarehousesDetail />
                    },
                    {
                        path: '/profile/brands/detail/:id',
                        element: <GuidsBrandsDetail />
                    },
                    {
                        path: '/profile/components/detail/:id',
                        element: <GuidsComponentsDetail />
                    },
                    {
                        path: '/profile/packaging/detail/:id',
                        element: <GuidsPackagingDetail />
                    },
                    {
                        path: '/profile/materials/',
                        element: <Materials />
                    },
                    {
                        path: '/profile/materials/:type/:id',
                        element: <Materials />
                    },
                    {
                        path: '/profile/nomenclatures/detail/:id',
                        element: <GuidsNomenclaturesDetail />
                    },
                    {
                        path: '/profile/equipments/detail/:id',
                        element: <GuidsEquipmentDetail />
                    },
                    {
                        path: '/profile/currencies/detail/:id',
                        element: <GuidsCurrencyDetail />
                    },
                    {
                        path: '/profile/suppliers/detail/:id',
                        element: <GuidsSuppliersDetail />
                    },
                    {
                        path: '/sales',
                        element: <Sales />
                    },
                    {
                        path: 'sales/tkp',
                        element: <TkpListN />
                    },
                    {
                        path: 'sales/tkp/detail/:id',
                        element: <TkpDetailN />
                    },
                    {
                        path: 'sales/tkp/v2',
                        element: <TkpListN />
                    },
                    {
                        path: 'sales/tkp/v2/detail/:id',
                        element: <TkpDetailN />
                    },
                    {
                        path: 'sales/kp',
                        element: <KpList />
                    },
                    {
                        path: 'sales/kp/detail/:id',
                        element: <KPDetailNew />
                    },
                    {
                        path: 'sales/billings',
                        element: <BillingsList />
                    },
                    {
                        path: 'sales/billings/detail/:id',
                        element: <BillingsDetail />
                    },
                    {
                        path: 'sales/specifications',
                        element: <SpecificationList />
                    },
                    {
                        path: 'sales/specifications/detail/:id',
                        element: <SpecificationDetail />
                    },
                    {
                        path: 'sales/deals',
                        element: <DealsList />
                    },
                    {
                        path: 'sales/deals/detail/:id',
                        element: <DealsDetail />
                    },
                    {
                        path: 'sales/contracts',
                        element: <ContractsList />
                    },
                    {
                        path: 'sales/contracts/detail/:id',
                        element: <ContractsDetail />
                    },
                    {
                        path: 'sales/clients',
                        element: <ClientList />
                    },
                    {
                        path: 'sales/clients/detail/:id',
                        element: <ClientDetail />,
                    },
                    {
                        path: 'sales/documents',
                        element: <Test />
                    },
                    {
                        path: '/logist',
                        element: <Test />
                    },
                    {
                        path: 'logist/guids',
                        element: <Guids />
                    },
                    {
                        path: 'logist/logistics',
                        element: <Logistics />
                    },
                    {
                        path: '/production',
                        element: <Test />
                    },
                    {
                        path: '/service',
                        element: <Test />
                    },
                    {
                        path: '/admins',
                        element: <Test />
                    },
                    {
                        path: 'admins/users',
                        element: <UserList />
                    },
                    {
                        path: 'admins/users/detail/:id',
                        element: <UserDetailCard />
                    },
                    {
                        path: 'admins/fields',
                        element: <GuidsFieldsList />
                    },
                    {
                        path: 'admins/fields/detail/:id',
                        element: <GuidsFieldsDetail />
                    },
                    {
                        path: 'admins/statuses',
                        element: <StatusGroupList />
                    },
                    {
                        path: 'admins/menu',
                        element: <MenuItemList />
                    },
                    {
                        path: 'users/detail/:id',
                        element: <UserDetailCard />
                    },
                    {
                        path: 'projects',
                        element: <ProjectList />
                    },
                    {
                        path: 'projects/detail/:id',
                        element: <ProjectDetail />
                    },
                    {
                        path: 'projects/sell/detail/:id',
                        element: <SellVariationDetail />
                    },
                    {
                        path: 'projects/rent/detail/:id',
                        element: <RentVariationDetail />
                    },
                    {
                        path: 'management',
                        element: <Management />
                    },
                    {
                        path: 'management/clients',
                        element: <ClientsTs />
                    },
                    {
                        path: 'management/clients/detail/:id',
                        element: <ClientDetailTS />
                    },
                    {
                        path: 'management/appeals',
                        element: <AppealsList />
                    },
                    {
                        path: 'management/appeals/rent/detail/:id',
                        element: <RentAppealDetail />
                    },
                    {
                        path: 'management/appeals/buy/detail/:id',
                        element: <BuyAppealDetail />
                    },
                    {
                        path: 'management/deals',
                        element: <DealListTs />
                    },
                    {
                        path: 'management/deals/rent/detail/:id',
                        element: <RentDealDetail />
                    },
                    {
                        path: 'management/deals/buy/detail/:id',
                        element: <BuyDealDetail />
                    },
                    {
                        path: 'management/offers',
                        element: <OffersList />
                    },
                    {
                        path: 'management/offers/rent/detail/:id',
                        element: <RentOfferDetail />
                    },
                    {
                        path: 'management/offers/buy/detail/:id',
                        element: <BuyOfferDetail />
                    },
                    {
                        path: 'management/commercial/contract',
                        element: <ContractListTs />
                    },
                    {
                        path: 'management/commercial/contract/detail/:id',
                        element: <ContractDetailTs />
                    },
                    {
                        path: 'management/commercial/billing',
                        element: <BillingListTs />
                    },
                    {
                        path: 'management/commercial/billing/detail/:id',
                        element: <BillingDetailTs />
                    },
                    {
                        path: 'management/commercial/kp',
                        element: <KpListTs />
                    },
                    {
                        path: 'management/commercial/kp/detail/:id',
                        element: <KpDetailTs />
                    },
                ]
            },
            {
                path: 'crm',
                element: <CrmLayout />,
                children: [
                    {
                        path: '',
                        element: <Main />
                    },
                    {
                        path: 'remains/v2',
                        element: <RemainsNew/>
                    },
                    {
                        path: 'kp/preview/:guid',
                        element: <KpPreview/>
                    },
                    {
                        path: 'test',
                        element: <Test />
                    },
                ],
            },
        ]
    }
]
const routesForNotAuthenticatedOnly   = [
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '*',
        element: <Login />,
    },
]
