import React from 'react';
import './Test.css'


const Test = () => {



    return (
        <div className={'cont'}>
            <h1>В разработке</h1>
        </div>
    );
};

export default Test;
